import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MailIcon from "@mui/icons-material/Mail";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MoreIcon from "@mui/icons-material/MoreVert";
import Notification from "../../images/notifications.png";
import Logout from "../../images/logout.png";
import MenuDropdown from "../dropdown/dropdown";
import DesktopLogo from "../../images/dizz-logo.svg";
import MobileLogo from "../../images/dizbuzz-white-logo.svg";

import MenuIcon from "../../images/menu-icon.png";
import SettingsIcon from "../../images/nav-setting-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import "./header.css";
import { makeStyles} from '@mui/styles'
import { useScrollTrigger} from "@mui/material";
import Sidebar from "../sidebar/sidebar";
export default function PrimarySearchAppBar(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const dispatch = useDispatch();
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const mobileMenuToggle = useSelector((state) => state.mobileMenuToggle);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    if (mobileMenuToggle) {
      dispatch({ type: "TOGGLE_MENU", data: false });
    } else {
      dispatch({ type: "TOGGLE_MENU", data: true });
    }
  };

  function ElevationScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
      disableHysteresis: true,
      threshold: 0,
      target: window ? window() : undefined,
    });

    return React.cloneElement(children, {
      elevation: trigger ? 4 : 0,
    });
  }

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="error">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
        >
          <Badge badgeContent={17} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  return (
    <Box className="p-0" sx={{ flexGrow: 1 }}>
      <AppBar style={{
        '-webkit-box-shadow': '-5px 6px 6px -6px #777',
       ' -moz-box-shadow': '-5px 6px 6px -6px #777',
        'box-shadow': '-5px 6px 6px -6px #777'
      }}  elevation={4}  position="sticky" >
          <Toolbar className="mobileHeaderColor" style={{ backgroundColor:"white" }}>
            <div className="logo-band">
              <img src={DesktopLogo} alt="logo" />
            </div>
            <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{
                  display: { xs: "block", md: "none" },
                  paddingTop: "18px",
                  paddingBottom: "18px",
                }}
            >
              <img src={DesktopLogo} alt="logo" className="dasktop-logo" />
              <img src={MobileLogo} alt="logo" className="mobile-logo" />
            </Typography>

            <Box sx={{ flexGrow: 1 }} />
            <Box   sx={{ display: { xs: "none", md: "flex" } }}>
              <IconButton
                  size="large"
                  aria-label="show 4 new mails"
                  color="inherit"
                  // style={{ marginRight: "93px" }}
              >
                <MenuDropdown />
              </IconButton>
              {/* <IconButton
              size="large"
              aria-label="show 17 new notifications"
              color="inherit"
            >
              <img src={Notification} alt="bell" />
            </IconButton> */}
              {/* <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <div
                style={{
                  background: "#1C4E63",
                  width: "56px",
                  height: "56px",
                  borderRadius: "100%",
                  display: "flex",
                  selfAlign: "center",
                  justifyContent: "center",
                }}
              >
                <img src={SettingsIcon} alt="exit" style={{ width: "35px" }} />
              </div>
            </IconButton> */}
            </Box>
            <Box sx={{ display: { xs: "flex", md: "none" } }}>
              <IconButton
                  size="large"
                  aria-label="show more"
                  aria-controls={mobileMenuId}
                  aria-haspopup="true"
                  onClick={handleMobileMenuOpen}
                  color="inherit"
              >
                <img src={MenuIcon} alt="menu" />
              </IconButton>
            </Box>
          </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    <div />
  </Box>
  )

  // return (
  //   <Box style={{
  //     '-webkit-box-shadow': '-5px 10px 6px -6px #777',
  //     ' -moz-box-shadow': '-5px 10px 6px -6px #777',
  //     'box-shadow': '-5px 10px 6px -6px #777'
  //   }} >
  //     <AppBar  elevation={4} position="static">
  //     </AppBar>
  //     {renderMobileMenu}
  //     {renderMenu}
  // </Box>
  // );
}
