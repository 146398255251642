import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TitleComponentHeader from "../../components/titleComponent/TitleComponent";
import services from "../../services/services";
import axios from "axios";
// import "./uploadBankDetails.css";
import MessageComponent from "../../components/messageComponent/messageComponent";
import { useNavigate } from "react-router";
export default function UploadBorrowFile() {
  const navigate = useNavigate();
  const userDetails = useSelector((state) => state.userDetails);
  const globalErrorMessage = useSelector((state) => state.globalErrorMessage);
  const getBvnRecordDetails = useSelector((state) => state.getBvnRecordDetails);
  const loanProductSlected = useSelector((state) => state.loanProductSlected);
  const loanPaymentSuccess = useSelector((state) => state.loanPaymentSuccess);
  const setEmploymentDetails = useSelector(
    (state) => state.setEmploymentDetails
  );
  const getLoanId = useSelector((state) => state.getLoanId);
  const [imageDetails, setimageDetails] = useState({
    borrow_id: "",
  });
  const [borrowImage, setborrowImage] = useState("");

  const dispatch = useDispatch();

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      let form_data = new FormData();
      form_data.append("borrower_id", imageDetails.borrow_id);

      form_data.append(
        "files",
        JSON.stringify('{"name":"bank statement","fileName":"bank statement"}')
      );
      form_data.append(
        "files",
        JSON.stringify('{"name":"bank statement","fileName":"bank statement"}')
      );
      form_data.append("image", e.target.files[0]);

      form_data.append("image", e.target.files[0]);
      const requestOptions = {
        headers: {
          Authorization: `${userDetails.token}`,
        },
      };

      axios
        .post(
          "http://api.odiopay.com/api/v1/borrower/borrower-file",
          form_data,
          requestOptions
        )
        .then((res) => {
          if (res?.data?.errorMessage) {
            let errorMessage = {
              message: res?.data?.errorMessage,
              status: false,
            };
            dispatch({ type: "ERROR_MESSAGE", data: errorMessage });
          } else {
            setborrowImage(res?.data?.data[0]?.image);
            console.log(res.data);
            let successMessage = {
              message: "file uploaded successful",
              status: true,
            };
            dispatch({ type: "ERROR_MESSAGE", data: successMessage });
          }
          // console.log(data.data);
        })
        .catch((err) => {});
    }
  };

  useEffect(() => {
    const getUserDetails = new services().getService(
      "borrower",
      userDetails.token
    );
    getUserDetails.then((res) => {
      if (!res.errorMessage) {
        dispatch({ type: "GET_CURRENT_LOGGEDIN_USER", data: res.data });
        setimageDetails({
          borrow_id: res?.data?.id ? res?.data?.id : "",
        });
      }
    });
    let successMessage = {
      message: "",
      status: false,
    };
    dispatch({ type: "ERROR_MESSAGE", data: successMessage });
  }, []);
  console.log(borrowImage);
  return (
    <div className="bank-statement-upload-cover">
      <TitleComponentHeader
        title={"Add Photo"}
        subTitle={"Kindly add your recent photo"}
      />
      <div
        style={{
          marginTop: "37px",
          paddingTop: "37px",
          boxShadow: "1.5px 2.6px 10px rgba(119, 119, 119, 0.1)",
          paddingLeft: "12px",

          paddingRight: "12px",
        }}
      >
        <div
          style={{
            paddingTop: "20px",
            paddingLeft: "25px",
            paddingRight: "25px",
            paddingBottom: "52px",
          }}
        >
          <div
            className=" col-md-5 col-12"
            style={{
              marginTop: "10px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div
              style={{ width: "194px", height: "210px", background: "#C4C4C4" }}
            >
              {borrowImage ? (
                <img
                  src={borrowImage}
                  alt="image"
                  style={{ width: "100%", height: "100%" }}
                />
              ) : (
                ""
              )}
            </div>

            <input
              type="file"
              className="form-control"
              onChange={handleImageChange}
              style={{ display: "none" }}
              id="upload-borrow"
            />
          </div>
          {globalErrorMessage.message ? (
            <div
              className=" col-md-5 col-12"
              style={{ marginTop: "20px", textAlign: "center" }}
            >
              <MessageComponent messageDetails={globalErrorMessage} />
            </div>
          ) : (
            ""
          )}
          <div
            className=" col-md-5 col-12"
            style={{ marginTop: "20px", textAlign: "center" }}
          >
            <label htmlFor="upload-borrow">
              <div
                style={{
                  background: "#2BC155",
                  border: "1px solid#2BC155",
                  borderRadius: "26px",
                  color: "white",
                  fontSize: "18px",
                  width: "261px",
                  height: "54px",
                  lineHeight: "54px",
                  cursor: "pointer",
                }}
              >
                Choose from Gallery
              </div>
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}
