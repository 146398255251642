import React from "react";
import LandingPage from "../auth/landingPage/landingPage";
import { Routes, Route } from "react-router-dom";
import RootScreen from "../screen/rootScreen";
import SignupPage from "../auth/signup/signup";
import Signin from "../auth/signin/signin";
import RecoverPassword from "../auth/recoverPassword/recoverPassword";
import Home from "../screen/home/Home";
import VerifyEmail from "../screen/verifications/verify-email";
import RestructureForm from "../screen/verifications/accept-reject-restructure";
export default function RootLandingPage() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/register" element={<SignupPage />}></Route>
        <Route path="/login" element={<Signin />}></Route>
        <Route path="/recover-password" element={<RecoverPassword />}></Route>
        <Route path="/verify-email" element={<VerifyEmail />}></Route>
        <Route path="/restructure" element={<RestructureForm />}></Route>
      </Routes>
    </div>
  );
}
