import React from "react";
import BackButton from "../../images/back-arrow.png";
import { useNavigate } from "react-router-dom";
export default function GoBack() {
  const navigate = useNavigate();
  return (
    <div>
      <img
        src={BackButton}
        alt="back-button"
        onClick={() => navigate(-1)}
        style={{ cursor: "pointer", marginRight: "22px" }}
      />
    </div>
  );
}
