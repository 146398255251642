import React from "react";
import "./authForm.css";
import Valid from "../../images/valid.png";
import Invalid from "../../images/invalid.png";
import Eye from "../../images/eye.png";
export default function AuthForm({
  type,
  isValid,
  placeholder,
  right,
  top,
  handleChange,
  value,
  disable,
  hasLabel,
  noCheck,
  label,
  handleType,
  isPassword,
}) {
  return (
    <div className="auth-input-cover">
      {noCheck ? (
        ""
      ) : (
        <div style={{ position: "absolute", right: right, top }}>
          {isPassword ? (
            <div
              onClick={() => {
                handleType();
              }}
            >
              <img src={Eye} alt="checked" />
            </div>
          ) : (
            <img src={isValid ? Valid : Invalid} alt="checked" />
          )}
        </div>
      )}

      {hasLabel ? (
        <div
          style={{ fontSize: "14px", marginBottom: "17px", color: "#32343E" }}
        >
          {label}
        </div>
      ) : (
        ""
      )}
      <input
        value={value}
        type={type}
        className="form-control auth-main-input"
        placeholder={placeholder}
        onChange={handleChange}
        disabled={disable}
      />
    </div>
  );
}
