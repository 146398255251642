import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import PrimaryButton from "../../components/primaryButton/primaryButton";
import TitleComponentHeader from "../../components/titleComponent/TitleComponent";
import services from "../../services/services";
// import "./changePassword.css";
export default function RestructureForm() {
  const dispatch = useDispatch();
  const [accepted, setAccepted] = useState(0);//accepted =1 , rejected = -1, 0 is pending
  const [loading, setLoading] = useState(false)
  const handleAccept = async () => {
    setLoading(true);
    const acceptRestructureRoute = new services().getService(`loan/restured-loan/approve/${loanId}`,token);
    acceptRestructureRoute
        .then((res) => {
          setLoading(false);
          if (res.errorMessage) {
            let errorMessage = {
              message: res.errorMessage,
              status: false,
            };

            dispatch({ type: "ERROR_MESSAGE", data: errorMessage });
          }else{
            setAccepted(1)
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
};
const handleDeny = async () => {
    setLoading(true);
    const denyRestructureRoute = new services().getService(`loan/restured-loan/dis-approve/${loanId}`,token);
    denyRestructureRoute
        .then((res) => {
          setLoading(false);
          if (res.errorMessage) {
            let errorMessage = {
              message: res.errorMessage,
              status: false,
            };

            dispatch({ type: "ERROR_MESSAGE", data: errorMessage });
          }else{
            setAccepted(-1)
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
};
  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get("token");
  const loanId = searchParams.get("loan_id");
  useEffect(() => {
    let errorMessage = {
      message: "",
      status: false,
    };
    return dispatch({ type: "ERROR_MESSAGE", data: errorMessage });
  }, []);
  return (
    <div className="change-password-cover">
      <div style={{ marginBottom: "50px" }}>
        <TitleComponentHeader hasGoBack title={"Loan Restructure"} />
      </div>
      <div>
        <div className="col-md-5 col-12" style={{ marginBottom: "24px" }}>
          
        <div className="dispay-text">
            {accepted===0? "We have restructured your loan request. Kindly accept or reject this offer":`You have ${accepted===1?"Accepted":"Rejected"} the loan restructure`}
        </div>
        {accepted===0 ?<div className="d-flex gap-5 mt-5">
            <PrimaryButton
                text={"Reject"}
                buttonWith={"261px"}
                handleClickButton={handleDeny}
                loading={loading}
                isPrimary={false}
            />
            <PrimaryButton
                text={"Accept"}
                buttonWith={"261px"}
                handleClickButton={handleAccept}
                loading={loading}
            />
        </div>:<></>}
        
        
        </div>
      </div>
    </div>
  );
}
