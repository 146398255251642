import React, { useEffect } from "react";
import "./my-account.css";
import TitleDiv from "../../components/titleComponent/TitleComponent";
import services from "../../services/services";
import { useDispatch, useSelector } from "react-redux";
import ImageAccountAvatars from "../../components/accountAvater/accountAvater";
import AccountDetailsList from "../../components/account-list-component/account-list-component";
import Phone from "../../images/phone.png";
import user from "../../images/user.png";
import email from "../../images/email.png";
import LightButton from "../../components/lightButton/lightButton";
import { useNavigate } from "react-router";
export default function MyAccount() {
  const userDetails = useSelector((state) => state.userDetails);
  const navigate = useNavigate();
  const getCurrentLoggedInUser = useSelector(
    (state) => state.getCurrentLoggedInUser
  );
  const getCustomers = useSelector((state) => state.getCustomers);
  const dispatch = useDispatch();
  useEffect(() => {
    const getCustomer = new services().getService(
      "borrower",

      userDetails?.token
    );
    getCustomer
      .then((res) => {
        if (res.errorMessage) {
          dispatch({ type: "GET_CURRENT_LOGGEDIN_USER", data: {} });
        } else {
          dispatch({ type: "GET_CURRENT_LOGGEDIN_USER", data: res?.data });
        }
      })
      .catch((error) => {
        dispatch({ type: "GET_CURRENT_LOGGEDIN_USER", data: {} });
      });
    const getCustomerDetails = new services().getServiceWithId(
      "customers",

      userDetails?.customer_id,
      userDetails?.token
    );
    getCustomerDetails
      .then((res) => {
        if (res.errorMessage) {
          dispatch({ type: "GET_CUSTOMER_DETAILS", data: {} });
        } else {
          dispatch({ type: "GET_CUSTOMER_DETAILS", data: res?.data });
        }
      })
      .catch((error) => {
        dispatch({ type: "GET_CUSTOMER_DETAILS", data: {} });
      });
  }, []);

  const handleChangePassword = () => {
    navigate("/change-password");
  };
  return (
    <div className="my-account-cover">
      <div style={{ marginBottom: "60px" }}>
        <TitleDiv title={"My Account"} hasGoBack />
      </div>
      <div className="account-content-cover">
        <div className="row m-0 p-0">
          <div className="col-md-1 col-4">
            <ImageAccountAvatars />
          </div>
          <div
            className="col-md col fullname-cover"
            style={{ paddingLeft: "40px", paddingTop: "10px" }}
          >
            <span style={{ fontSize: "20px", fontWeight: "700" }}>
              {getCustomers?.first_name + " " + getCustomers?.last_name}
            </span>
            <br />
            <span
              style={{ fontSize: "14px", color: "#A0A5BA", marginTop: "2px" }}
            >
              {getCustomers?.username}
            </span>
          </div>
        </div>
        {/* Account body content */}

        <div style={{ marginTop: "55px" }}>
          <AccountDetailsList
            primaryName={"Full Name"}
            secondaryName={
              getCustomers?.first_name + " " + getCustomers?.last_name
            }
            icon={user}
          />
          <AccountDetailsList
            primaryName={"Email"}
            secondaryName={getCustomers?.email}
            icon={email}
          />
          <AccountDetailsList
            primaryName={"Phone Number"}
            secondaryName={getCustomers?.phone}
            icon={Phone}
          />
        </div>
      </div>
      <div
        className="account-button-div"
        style={{ marginTop: "50px", paddingBottom: "50px" }}
      >
        <span
          style={{ marginRight: "51px" }}
          className="desktop-edit-profile-button"
        >
          {" "}
          <LightButton
            customBorderRadius={"26px"}
            customHeight={"54px"}
            customWidth={"261px"}
            title={"Edit Profile"}
            handleClick={() => navigate("/edit-profile")}
          />
        </span>
        <span>
          <LightButton
            customBorderRadius={"26px"}
            customHeight={"54px"}
            customWidth={"261px"}
            title={"Change Password"}
            handleClick={handleChangePassword}
          />
        </span>
      </div>
    </div>
  );
}
