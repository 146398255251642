import React from "react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import "./employmentGoogleLocation.css";

export default function LocationGoogleAutocomplete({
  onChangeValue,
  isValid,
  top,
  right,
  left,
  labelTitle,
  isEmployee,
  value,
}) {
  var options = {
    types: ["(regions)"],
    componentRestrictions: { country: "Ng" },
  };
  return (
    <div
      style={{ position: "relative" }}
      className="employment-google-location-div"
    >
      {labelTitle ? (
        <div className="form-title-div" style={{ color: "#020614" }}>
          {labelTitle}
        </div>
      ) : (
        ""
      )}

      <GooglePlacesAutocomplete
        options={options}
        defaultValue={value}
        apiKey="AIzaSyCGPY_hsHcarYRmtuyvZCTOyoRWGN7-JGA"
        selectProps={{
          onChange: (evt) => onChangeValue(evt),
          placeholder: isEmployee
            ? "Enter employees address"
            : "Enter your company address",
        }}
      />
    </div>
  );
}
