import React from "react";
import "./primaryButtonTitle.css";
export default function PrimaryButton({
  buttonWith,
  text,
  handleClickButton,
  isPrimary=true,
  loading,
}) {
  return (
    <button
      className={`btn btn-default ${isPrimary?"mobile-primary-button":"mobile-light-button"}`}
      style={{
        width: buttonWith,
        background: loading ? "#A6ACBE" : "#2BC155",
        color: "white",
        height: "54px",
        borderRadius: "26px",
        fontSize: "16px",
      }}
      onClick={loading ? "" : handleClickButton}
    >
      {text}
    </button>
  );
}
