import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import MobileLogo from "../../images/dizz-logo.svg";
import "./landingHeader.css";
import { useNavigate } from "react-router-dom";
export default function LandingPageHeader() {
  const navigation = useNavigate();
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="static"
        elevation={3}
        color="transparent"
        style={{
          paddingTop: "10px",
          paddingBottom: "10px",
        }}
      >
        <Toolbar variant="dense">
          <Typography
            variant="h6"
            color="inherit"
            component="div"
            className="mobile-logo-band"
            style={{ color: "black", paddingLeft: "51px" }}
          >
            <img
              src={MobileLogo}
              alt="logo"
              onClick={() => navigation("/")}
              style={{ cursor: "pointer" }}
            />
          </Typography>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
