import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TitleComponentHeader from "../../components/titleComponent/TitleComponent";
import services from "../../services/services";
import "./borrowLoanSchedul.css";
import BorrowLoanTable from "./borrowLoanTable";
import BorrowTableHeader from "./borrowTableHeader";
import { useParams } from "react-router";
import BorrowMobileCards from "./borrowMobileCards";
import LoanMobileTitleComponent from "../../components/loanMobileTitleComponent/loanMobileTitleComponent";
import GoBack from "../../components/goBack/goBack";
export default function BorrowLoanSchedule() {
  const userDetails = useSelector((state) => state.userDetails);
  const loaSchedule = useSelector((state) => state.loaSchedule);
  const id = useParams();
  const dispatch = useDispatch();
  // useEffect(() => {
  //   const getLoanSchedule = new services().getServiceWithId(
  //     "repayments/schedule",
  //     id.id,
  //     userDetails.token
  //   );
  // }, []);

  return (
    <div className="borrow-loan-cover">
      <div className="borrow-loan-for-desktop">
        <div className="borrow-title-cover">
          <TitleComponentHeader title={"Borrower's loan schedule"} hasGoBack />
        </div>
        <div className="borrow-loan-table-cover">
          <BorrowLoanTable id={id} />
        </div>
      </div>
      {/* <BorrowTableHeader /> */}
      <div className="loan-schedule-for-mobile">
        <div style={{ marginBottom: "26px" }}>
          <LoanMobileTitleComponent title={"Loan History"} />
        </div>
        <div>
          <BorrowMobileCards id={id} />
        </div>
      </div>
    </div>
  );
}
