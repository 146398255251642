import React, { useState } from "react";
import "./viewLoanTableComponent.css";
import LoanDropDownIcons from "../loanIconDropDown/loanDropDownIcons";
import LoanHistoryDetails from "../../screen/ViewExistingLoan/loanHistory-details";
import { formatToCurrency, getStatusColor } from "../../utilities/utilities";
import { useDispatch, useSelector } from "react-redux";
export default function ViewLoanMobileTable({ getExistingLoan, index }) {
    const getLoanDetailsOpenState = useSelector(
        (state) => state.getLoanDetailsOpenState
    );
    const [pendingList, setpendingList] = useState([
        { id: "1", name: "View details" },
        { id: "2", name: "View schedule" },

        // { id: "4", name: "Setup Direct Debit" },
        { id: "5", name: "Upload bank Statement" },
        { id: "6", name: "Upload files" },
    ]);
    const [notPendingList, setnotPendingList] = useState([
        // { id: "1", name: "Setup Direct Debit" },
        { id: "2", name: "View Loan Schedule" },
        // { id: "3", name: "View Next Repayment" },
        // { id: "4", name: "Make Repayment" },
        // { id: "5", name: "View Repayments" },
    ]);
    const [addIndex, setaddIndex] = useState("");
    const dispatch = useDispatch();
    const handleOpenDetails = (id) => {
        setaddIndex(id);
        if (getLoanDetailsOpenState) {
            dispatch({ type: "OPEN_LOAN_DETAILS_STATE", data: false });
        } else {
            dispatch({ type: "OPEN_LOAN_DETAILS_STATE", data: true });
        }
    };
    const indexClick = (index) => {
        setaddIndex(index);
    };

    return (
        <div>
            <div className="mobile-card-cover-page-for-loan">
                <div
                    style={{
                        display: "flex",
                        flexDirection: "col",
                        gap: "1ch",
                        // justifyContent: "space-around",
                    }}
                >
                    <div
                        className="col-5 content-and-amount-div"
                        onClick={() => handleOpenDetails(index)}
                    >
                        {getExistingLoan?.type}
                        <br />
                        <span className="card-amount-span">
                            {" "}
                            ₦
                            {getExistingLoan?.initial_principal
                                ? formatToCurrency(
                                      getExistingLoan?.initial_principal
                                  )
                                : formatToCurrency(0)}
                        </span>
                    </div>
                    <div
                        className="col-6"
                        onClick={() => handleOpenDetails(index)}
                    >
                        <span
                            className="status-card-span"
                            style={{
                                color: getStatusColor(getExistingLoan?.status)
                                    .color,
                                background: getStatusColor(
                                    getExistingLoan?.status
                                ).background,
                            }}
                        >
                            {getExistingLoan?.status}
                        </span>
                    </div>
                    <div className="col-1">
                        <LoanDropDownIcons
                            getIndex={indexClick}
                            sendIndex={index}
                            listDetails={
                                getExistingLoan?.status === "pending"
                                    ? pendingList
                                    : notPendingList
                            }
                            forMobile
                            loanId={getExistingLoan?.id}
                        />
                    </div>
                </div>
            </div>
            {getLoanDetailsOpenState && addIndex === index ? (
                <LoanHistoryDetails details={getExistingLoan} />
            ) : (
                ""
            )}
        </div>
    );
}
