import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import ButtonWithChecker from "../../../components/buttonWithChecker/buttonWithChecker";
import MessageComponent from "../../../components/messageComponent/messageComponent";
import BasicModal from "../../../components/modal/modalPop";
import NetworkChecker from "../../../components/networkChecker/networkChecker";
import TitleComponentHeader from "../../../components/titleComponent/TitleComponent";
import services from "../../../services/services";
import "./creditCheck.css";
import CreditFormChecker from "./creditFormChecker";
import Okra from "okra-js";
import { okraLogoToken } from "../../../utilities/utilities";
export default function CreditCheck() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userDetails = useSelector((state) => state.userDetails);
  const globalErrorMessage = useSelector((state) => state.globalErrorMessage);
  const getBvnRecordDetails = useSelector((state) => state.getBvnRecordDetails);
  const loanProductSlected = useSelector((state) => state.loanProductSlected);
  const loanPaymentSuccess = useSelector((state) => state.loanPaymentSuccess);
  const getLoanId = useSelector((state) => state.getLoanId);
  const [ShowOkraPayment, setShowOkraPayment] = useState(false);
  const [bankPaymentOption, setbankPaymentOption] = useState("option 1");

  const setEmploymentDetails = useSelector(
    (state) => state.setEmploymentDetails
  );

  const handleOpenModal = () => {
    const loadBankPay = new services().getService(
      "repayments/add-card",
      userDetails.token
    );

    loadBankPay
      .then((res) => {
        if (res.errorMessage) {
          let errorMessage = {
            message: res.data.errorMessage,
            status: false,
          };

          dispatch({ type: "ERROR_MESSAGE", data: errorMessage });
        } else {
          dispatch({ type: "LOAN_PAYMENT_SUCCESS_CHECKER", data: true });
          window.location.href = `${res.data.authorization_url}`;

          let loanData = {
            bvnRecord: getBvnRecordDetails,
            loanSelectedProduct: loanProductSlected,
            employmentRecord: setEmploymentDetails,
          };
        }
      })
      .catch((error) => {
        let errorMessage = {
          message: error.errorMessage,
          status: false,
        };

        dispatch({ type: "ERROR_MESSAGE", data: errorMessage });
      });
  };
  const handleOpenOkra = () => {
    Okra.buildWithOptions({
      name: "Odiopay ",
      env: "production",
      key: "306f2df1-5ecb-5d4e-ac4d-de5ab7224017",
      token: "5eeb30f1ac63db0684c8c1dc",
      source: "integration",
      color: "#252B63",
      limit: "6",
      corporate: null,
      connectMessage: "Select an account to continue",

      products: ["auth", "identity", "transactions", "income"],
      callback_url: "null",
      redirect_url: "",
      logo: okraLogoToken,
      institutions: [
        "first-bank-of-nigeria",
        "united-bank-for-africa",
        "guaranty-trust-bank",
        "access-bank",
        "zenith-bank",
        "kuda-bank",
        "stanbic-ibtc-bank",
        "first-city-monument-bank",
      ],
      widget_success: "Your account was successfully linked to Odiopay ",
      widget_failed: "An unknown error occurred, please try again.",
      currency: "NGN",
      mode: "dark",
      continue_cta: "Continue",
      multi_account: "true",
      exp: null,
      success_title: "You've successfully linked to Odiopay!",
      success_message:
        "With your bank account linked, Credits will be processed faster on Odiopay.",
      auth: { manual: false, debitLater: true, debitType: "recurring" },

      onSuccess: function (data) {
        let okraData = {
          loanId: getLoanId?.loanId,
          okra: data,
        };

        const postokra = new services()
          .postServiceWithToken(
            "bankstatement/okra",
            okraData,
            userDetails.token
          )
          .then((res) => {
            if (res.errorMessage) {
              let errorMessage = {
                message: res.errorMessage,
                status: true,
              };
              dispatch({ type: "LOAN_PAYMENT_SUCCESS_CHECKER", data: false });

              dispatch({ type: "ERROR_MESSAGE", data: errorMessage });
            } else {
              dispatch({ type: "LOAN_PAYMENT_SUCCESS_CHECKER", data: true });
              let successMessage = {
                message: "Payment successful.",
                status: true,
              };
              dispatch({ type: "GET_LOAN_ID", data: {} });
              dispatch({ type: "ERROR_MESSAGE", data: successMessage });
            }
          });
        // console.log("success", data);
      },
      onClose: function () {
        console.log("closed");
      },
    });
  };
  const handleUploadFile = () => {
    navigate("/loans/upload-bank-statement");
  };
  const handleRouteConfirm = () => {
    dispatch({ type: "LOAN_PAYMENT_SUCCESS_CHECKER", data: false });
    dispatch({ type: "LOAN_PRODUCT_SELECTED", data: {} });
    dispatch({ type: "GET_RECORD_DETAILS", data: {} });
    dispatch({ type: "SET_EMPLOYMENT_DETAILS", data: {} });

    setShowOkraPayment(true);
  };
  const handleRouteConfirmLoan = () => {
    dispatch({ type: "LOAN_PAYMENT_SUCCESS_CHECKER", data: false });
    navigate("/loans/confirm");
    setShowOkraPayment(false);
  };
  return (
    <div>
      {/* <div className="credit-check-title">
        <TitleComponentHeader
          title={"Credit Check"}
          subTitle={
            "We verify your profile by running a credit check using the details you have provided."
          }
        />
      </div> */}
      <div className="bank-statement-cover">
        {/* <div>
          <CreditFormChecker />
        </div> */}
        <div>
          <TitleComponentHeader
            title={"Repayment"}
            subTitle={
              !ShowOkraPayment
                ? "Setup automatic repayment on your debit card.You will debited 100 if successful. This amount will be refunded back to your card within 24 hours."
                : "Kindly connect your bank account to enable us access your account statement."
            }
          />

          <div style={{ paddingTop: "28px" }}>
            {/* <NetworkChecker checkerWith={"261px"} message={"Connect Bank"} /> */}
          </div>
          <div style={{ paddingTop: "11px" }}>
            {globalErrorMessage.message ? (
              <div style={{ marginTop: "20px" }}>
                <MessageComponent messageDetails={globalErrorMessage} />
              </div>
            ) : (
              ""
            )}

            {ShowOkraPayment ? (
              <div>
                <div className="upload-bank-statement-div">
                  {loanPaymentSuccess ? (
                    <button
                      onClick={handleRouteConfirmLoan}
                      className="continue-button"
                    >
                      Continue
                    </button>
                  ) : (
                    <button
                      onClick={handleOpenOkra}
                      className="continue-button"
                    >
                      Continue with Okra
                    </button>
                  )}
                </div>
                <div style={{ paddingTop: "30px", paddingLeft: "20px" }}>
                  <span
                    style={{
                      fontSize: "14px",
                      fontWeight: 400,
                      color: "#5B8DD7",
                      cursor: "pointer",
                    }}
                    onClick={handleUploadFile}
                  >
                    Upolad bank statement
                  </span>
                </div>
              </div>
            ) : (
              <div>
                {loanPaymentSuccess ? (
                  <ButtonWithChecker
                    buttonWith={"261px"}
                    right={"17px"}
                    text={"Continue"}
                    isValid={true}
                    handleClick={handleRouteConfirm}
                  />
                ) : (
                  // <button
                  //   onClick={handleRouteConfirm}
                  //   className="continue-button"
                  // >
                  //   Continue
                  // </button>
                  <button
                    onClick={handleOpenModal}
                    style={{
                      border: "1px solid #9597A6",
                      width: "261px",
                      height: "54px",
                      background: "white",
                      color: "#858585",
                      fontSize: "16px",
                      borderRadius: "26px",
                    }}
                  >
                    Setup direct debit
                  </button>
                )}
              </div>
            )}

            {/* <div>
              {globalErrorMessage.message ? (
                <div style={{ marginTop: "20px" }}>
                  <MessageComponent messageDetails={globalErrorMessage} />
                </div>
              ) : (
                ""
              )}
              
            </div> */}
            {/* <ButtonWithChecker
              buttonWith={"261px"}
              right={"17px"}
              text={"Connected"}
              isValid={false}
              handleClick={handleClickDebitCard}
            /> */}
          </div>
        </div>
      </div>
    </div>
  );
}
