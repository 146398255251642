import React from "react";
import { useDispatch, useSelector } from "react-redux";
import services from "../../services/services";
import { formatToCurrency } from "../../utilities/utilities";
import RepaymentButton from "./repaymentButton";

export default function UpcomingRepaymentMobile({ getIndividualLoan }) {
  const getRepayment = useSelector((state) => state.getRepayment);
  const dispatch = useDispatch();

  const userDetails = useSelector((state) => state.userDetails);
  const makeRepayment = (amount) => {
    dispatch({ type: "SHOW_SPINNER", data: true });
    // console.log(loanId);
    let data = {
      loan_id: getIndividualLoan.id,
      amount: amount,
    };

    const postRepayment = new services().postServiceWithToken(
      "repayments/make-repayment",
      data,
      userDetails.token
    );
    postRepayment
      .then((res) => {
        if (res.errorMessage) {
          let errorMessage = {
            message: res.errorMessage,
            status: false,
          };

          dispatch({ type: "ERROR_MESSAGE", data: errorMessage });
        } else {
          let errorMessage = {
            message: "Repayment successful",
            status: true,
          };
          window.location.href = res?.data?.authorization_url;

          dispatch({ type: "ERROR_MESSAGE", data: errorMessage });
        }
        dispatch({ type: "SHOW_SPINNER", data: false });

        dispatch({ type: "SHOW_TOAST", data: true });
      })
      .catch((error) => {
        dispatch({ type: "SHOW_SPINNER", data: false });
        console.log(error);
      });
  };
  return (
    <div>
      <div
        className="row"
        style={{
          background: "#E7F6FF",
          paddingTop: "18px",
          paddingBottom: "22px",
          marginBottom: "16px",
          borderRadius: "11px",
        }}
      >
        <div
          className="col-6"
          style={{ paddingLeft: "22px", fontSize: "12px", color: "#858585" }}
        >
          Repayment
        </div>
        <div
          className="col-6"
          style={{ paddingRight: "61px", fontSize: "12px", color: "#858585" }}
        >
          Action
        </div>
      </div>
      {getRepayment?.remainingPeriods?.length ? (
        getRepayment?.remainingPeriods?.map((list) => (
          <div
            className="row"
            style={{
              fontSize: "12px",
              paddingTop: "14px",
              marginBottom: "5px",
            }}
          >
            <div className="col-3"> {list?.date ? list?.date : ""}</div>
            <div
              className="col-4"
              style={{ color: "#858585", paddingBottom: "14px" }}
            >
              {getIndividualLoan?.type}
              <br />

              <span style={{ fontWeight: "bold" }}>
                ₦
                {list?.principal_outstanding
                  ? formatToCurrency(list?.principal_outstanding)
                  : 0}
              </span>
            </div>
            <div className="col-5">
              <RepaymentButton
                title={"Make Payment"}
                fontSize={"12px"}
                customWith={"144px"}
                handlePayRepayment={() =>
                  makeRepayment(list?.principal_outstanding)
                }
              />
            </div>
          </div>
        ))
      ) : (
        <div
          style={{
            color: "#858585",
            fontSize: "12px",
            marginTop: "30px",
          }}
        >
          You have no Upcoming repayment this time
        </div>
      )}
    </div>
  );
}
