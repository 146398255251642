import React from "react";
import { useSelector } from "react-redux";
import TitleComponentHeader from "../../../components/titleComponent/TitleComponent";
import "./personal-details.css";
import PersonalRowCover from "./personalRowCover";
export default function PersonalDetails() {
  const userDetails = useSelector((state) => state.userDetails);
  console.log(userDetails);
  return (
    <div className="personal-cover">
      <div className="personal-details-title">
        <TitleComponentHeader
          title={"Personal Details"}
          subTitle={"Kindly provide your employments"}
        />
      </div>
      <div className="personal-form-cover">
        <PersonalRowCover userDetails={userDetails} />
      </div>
    </div>
  );
}
