import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import Avatar from "@mui/material/Avatar";
import "./dropdown.css";
import ArrowDown from "../../images/arrow-down.png";
import { useDispatch, useSelector } from "react-redux";
import services from "../../services/services";
export default function MenuDropdown() {
  const userDetails = useSelector((state) => state.userDetails);
  const dispatch = useDispatch();
  const getCurrentLoggedInUser = useSelector(
    (state) => state.getCurrentLoggedInUser
  );
  React.useEffect(() => {
    const getCustomer = new services().getService(
      "borrower",

      userDetails?.token
    );
    getCustomer
      .then((res) => {
        if (res.errorMessage) {
          dispatch({ type: "GET_CURRENT_LOGGEDIN_USER", data: {} });
        } else {
          dispatch({ type: "GET_CURRENT_LOGGEDIN_USER", data: res?.data });
        }
      })
      .catch((error) => {
        dispatch({ type: "GET_CURRENT_LOGGEDIN_USER", data: {} });
      });
  }, []);

  return (
    <PopupState variant="popover" popupId="demo-popup-menu">
      {(popupState) => (
        <React.Fragment>
          <span className="cover-admin-dropdown">
            {userDetails?.first_name
              ? userDetails?.first_name + " " + userDetails?.last_name
              : ""}
            <br />
            <span className="dropdown-admin">Customer</span>
          </span>
          <Avatar
            alt={userDetails?.first_name}
            src={getCurrentLoggedInUser?.borrower_avatar}
            sx={{ width: 50, height: 50 }}
            // {...bindTrigger(popupState)}
          />
          {/* <span {...bindTrigger(popupState)} style={{ marginLeft: "18px" }}>
            <img src={ArrowDown} alt="arrow-down" />
          </span> */}

          {/* <Button variant="contained" {...bindTrigger(popupState)}>
            Dashboard
          </Button> */}
          <Menu {...bindMenu(popupState)}>
            <MenuItem onClick={popupState.close}>Profile</MenuItem>
            <MenuItem onClick={popupState.close}>My account</MenuItem>
            <MenuItem onClick={popupState.close}>Logout</MenuItem>
          </Menu>
        </React.Fragment>
      )}
    </PopupState>
  );
}
