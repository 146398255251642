import React from "react";
import "./listOfEmploymentDetailsCard.css";
import PrimaryButton from "../../../../components/primaryButton/primaryButton";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
export default function ListOfEmploymentStatusCard({
  customWidth,
  customHeight,
  employmentDetails,
  getCardClickEmploymentDetails,
    userDataCallback
}) {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <div
      className="mobile-employment-card"
      style={{

        height: customHeight,
        width: customWidth,
        filter: "drop-shadow(1.5px 2.598px 10px rgba(119, 119, 119, 0.102))",
        background: "white",
        paddingLeft: "50px",
        paddingTop: "24px",
        cursor: "pointer",
        marginBottom: "10px",
      }}
      // onClick={() => getCardClickEmploymentDetails(employmentDetails)}
    >
        <div style={{display:"flex","justify-content":"space-around"}}>
            <div>
      <div style={{ color: "#464A53", fontSize: "16px", marginBottom: "9px" }}>
        Current Employer
      </div>
      <div style={{ fontWeight: "700", color: "#464A53", marginBottom: "5px" }}>
        {employmentDetails?.business_name
          ? "Business Name: " + employmentDetails?.business_name
          : employmentDetails?.organization_name
          ? "Organization Name: " + employmentDetails?.organization_name
          : "School Name: " + employmentDetails?.school_name}
      </div>
      <div>
        {employmentDetails?.occupation
          ? "Occupation: " + employmentDetails?.occupation
          : employmentDetails?.organization_name
          ? "Organization Name: " + employmentDetails?.organization_name
          : employmentDetails?.level
          ? "Level: " + employmentDetails?.level
          : "Type: " + employmentDetails?.type}
      </div>
            </div>
            <div>
                <PrimaryButton
                    text={
                        "Use This"
                    }
                    handleClickButton={()=>{
                      dispatch({ type: "SET_EMPLOYMENT_DETAILS", data: employmentDetails });
                      let clearMessage = {
                        message: "",
                        status: false,
                      };
                      dispatch({ type: "ERROR_MESSAGE", data: clearMessage });
                      navigate("/create-loan");
                      userDataCallback(employmentDetails)
                    }}
                />
            </div>
        </div>
    </div>
  );
}
