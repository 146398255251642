import React, { useEffect, useState } from "react";
import "./bvnIdentification.css";
import NormalInputForm from "../../../components/normalInputForm/normalInputForm";
import SelectInput from "../../../components/selectInput/selectInput";
import PrimaryButton from "../../../components/primaryButton/primaryButton";
import { useNavigate } from "react-router";
import services from "../../../services/services";
import { useDispatch, useSelector } from "react-redux";
import MessageComponent from "../../../components/messageComponent/messageComponent";
import { listOfBank } from "../../../utilities/utilities";
export default function BvnIdetification() {
  const navigate = useNavigate();
  const userDetails = useSelector((state) => state.userDetails);
  const globalErrorMessage = useSelector((state) => state.globalErrorMessage);
  const loanProductSlected = useSelector((state) => state.loanProductSlected);
  const getBvnRecordDetails = useSelector((state) => state.getBvnRecordDetails);
  const dispatch = useDispatch();

  console.log(loanProductSlected, "loanProductSlected")

  const getCurrentLoggedInUser = useSelector(
    (state) => state.getCurrentLoggedInUser
  );
  const [bvnIdentificationDetails, setbvnIdentificationDetails] = useState({
    bankName: "",
    accountNumber: "",
    accountName: "",
    bvnNumber: "",
    dateOfBirth: "",
    bvn_status: false,
    bankFullName: "",
  });
  const handleAccountNumberChange = (value) => {
    setbvnIdentificationDetails({
      ...bvnIdentificationDetails,
      accountNumber: value,
    });
  };
  const handleOnChangeBvnVerification = (value) => {
    setbvnIdentificationDetails({
      ...bvnIdentificationDetails,
      bvnNumber: value,
    });
  };
  useEffect(() => {
    if (bvnIdentificationDetails.bvnNumber) {
      let errorMessage = {
        message: "",
        status: false,
      };
      dispatch({ type: "ERROR_MESSAGE", data: errorMessage });
    }
    if (Object.entries(getBvnRecordDetails).length) {
      setbvnIdentificationDetails({
        bankName: getBvnRecordDetails?.bankName,
        accountNumber: getBvnRecordDetails?.accountNumber,
        accountName: getBvnRecordDetails?.accountName,
        bvnNumber: getBvnRecordDetails?.bvnNumber,
        dateOfBirth: getBvnRecordDetails?.dateOfBirth,
        bvn_status: getCurrentLoggedInUser?.description
          ? true
          : getBvnRecordDetails?.bvn_status,
        bankFullName: getBvnRecordDetails?.bankFullName,
      });
    }
  }, []);

  useEffect(() => {
    console.log(getCurrentLoggedInUser);
    if (!bvnIdentificationDetails.bvn_status) {
      if (bvnIdentificationDetails.bvnNumber.length === 11) {
        let data = {
          bvn: bvnIdentificationDetails.bvnNumber,
          account_number: bvnIdentificationDetails.accountNumber,

          bank_code: bvnIdentificationDetails.bankName,
        };
        let getBvnMatchResponse = new services().postBvnMatch(data);
        getBvnMatchResponse
          .then((res) => {
            if (res.status) {
              setbvnIdentificationDetails({
                ...bvnIdentificationDetails,
                bvn_status: true,
              });
              let errorMessage = {
                status: true,
                message: "BVN was successfully verified",
              };
              dispatch({ type: "ERROR_MESSAGE", data: errorMessage });
            } else {
              setbvnIdentificationDetails({
                ...bvnIdentificationDetails,
                bvn_status: false,
              });
              let errorMessage = {
                status: false,
                message: res.message,
              };
              dispatch({ type: "ERROR_MESSAGE", data: errorMessage });
            }
          })
          .catch((error) => {
            let errorMessage = {
              message: "an error occur, please check your network",
              status: false,
            };

            dispatch({ type: "ERROR_MESSAGE", data: errorMessage });
          });
      }
    } else {
    }
  }, [bvnIdentificationDetails.bvnNumber]);

  useEffect(() => {
    if (bvnIdentificationDetails.accountNumber.length > 9) {
      const getBanks = new services().getBanksService(
        bvnIdentificationDetails.accountNumber,
        bvnIdentificationDetails.bankName,
        userDetails.token
      );
      getBanks
        .then((res) => {
          if (res.status) {
            setbvnIdentificationDetails({
              ...bvnIdentificationDetails,
              accountName: res.data.account_name,
            });
          } else {
            let errorMessage = {
              status: false,
              message: res.message,
            };
            dispatch({ type: "ERROR_MESSAGE", data: errorMessage });
          }

          // console.log(res);
        })
        .catch((error) => {
          let errorMessage = {
            message: "an error occur, please check your network",
            status: false,
          };

          dispatch({ type: "ERROR_MESSAGE", data: errorMessage });
        });
    }
    // setbvnIdentificationDetails({
    //   ...bvnIdentificationDetails,
    //   bvn_status: false,
    //   bvnNumber: "",
    // });
  }, [bvnIdentificationDetails.accountNumber]);

  useEffect(() => {
    const getUserDetails = new services().getService(
      "borrower",
      userDetails.token
    );
    getUserDetails.then((res) => {
      if (!res.errorMessage) {
        dispatch({ type: "GET_CURRENT_LOGGEDIN_USER", data: res.data });
        setbvnIdentificationDetails({
          ...bvnIdentificationDetails,
          bvnNumber: res?.data?.description ? res?.data?.description : "",
          bvn_status: res?.data?.description ? true : false,
          dateOfBirth: res?.data?.dob ? res?.data?.dob : "",
        });
        console.log(res);
      }
    });
  }, []);

  const handleRouteToGuarantor = () => {
    if (Object.entries(loanProductSlected).length === 0) {
      let errorMessage = {
        status: false,
        message:
          "an error occur while applying for loan please go back to select loan product.",
      };
      dispatch({ type: "ERROR_MESSAGE", data: errorMessage });
    } else if (
      bvnIdentificationDetails.bankName === "" ||
      bvnIdentificationDetails.bvnNumber === "" ||
      bvnIdentificationDetails.dateOfBirth === "" ||
      bvnIdentificationDetails.accountName === "" ||
      bvnIdentificationDetails.accountNumber === ""
    ) {
      let errorMessage = {
        status: false,
        message: "Please fill out empty fields",
      };
      dispatch({ type: "ERROR_MESSAGE", data: errorMessage });
    } else if (bvnIdentificationDetails.bvn_status === false) {
      let errorMessage = {
        status: false,
        message: "bvn not valid",
      };
      dispatch({ type: "ERROR_MESSAGE", data: errorMessage });
    } else {
      let clearErrorMessage = {
        message: "",
        status: false,
      };

      setTimeout(() => {
        dispatch({ type: "ERROR_MESSAGE", data: clearErrorMessage });

        if (
          getCurrentLoggedInUser?.first_name &&
          getCurrentLoggedInUser?.dob &&
          getCurrentLoggedInUser?.description
        ) {
          let dataBorrow = {
            customer_id: userDetails?.customer_id,
            title: getCurrentLoggedInUser?.title,
            first_name: getBvnRecordDetails.accountName,
            middle_name: ".....",
            last_name: userDetails.last_name,
            email: userDetails.email,
            dob: getBvnRecordDetails.dateOfBirth,
            gender: getCurrentLoggedInUser?.gender,
            residential_address: getCurrentLoggedInUser?.residential_address,
            city: getCurrentLoggedInUser?.city,
            state_origin: getCurrentLoggedInUser?.state_origin,
            state_residence: getCurrentLoggedInUser?.state_residence,
            phone: userDetails.phone,

            description: getCurrentLoggedInUser.description,
          };

          dispatch({ type: "SET_BORROWER_DETAILS", data: dataBorrow });
          navigate("/loans/employment-details");
        } else {
          navigate("/loans/personal-details");
        }
      }, 500);
      let successMessage = {
        message: "Bvn details successfully submitted.",
        status: true,
      };
      dispatch({ type: "ERROR_MESSAGE", data: successMessage });
      dispatch({
        type: "GET_RECORD_DETAILS",
        data: bvnIdentificationDetails,
      });
    }
  };

  return (
    <div className="bvn-identification-number-cover">
      <div className="bvn-title">BVN Identification</div>
      <div className="bvn-sub-title">
        Verify your BVN with your bank account information
      </div>
      <div className="bvn-form-cover">
        <div className="bvn-inner-form-cover">
          <SelectInput
            placeholder={"Bank"}
            customWidth={"63%"}
            dropdownValue={listOfBank}
            labelTitle={"Select your bank from the list"}
            hasBank
            handleChange={(evt) =>
              setbvnIdentificationDetails({
                ...bvnIdentificationDetails,
                accountName: "",
                accountNumber: "",
                bankName: evt.target.value,
                bankFullName: listOfBank.find((list) =>
                  list.code === evt.target.value ? list.name : ""
                ),
              })
            }
            optionValue={bvnIdentificationDetails.bankName}
          />
        </div>
        <div className="bvn-inner-form-cover">
          <NormalInputForm
            customWidth={"63%"}
            placeholder={"Account No"}
            labelTitle={"Enter your bank account number"}
            handleChange={(evt) => handleAccountNumberChange(evt.target.value)}
            value={bvnIdentificationDetails.accountNumber}
          />
        </div>
        <div className="bvn-inner-form-cover">
          {" "}
          <NormalInputForm
            customWidth={"63%"}
            placeholder={"Account Name"}
            labelTitle={"Confirm Account Name"}
            handleChange={(evt) =>
              setbvnIdentificationDetails({
                ...bvnIdentificationDetails,
                accountName: evt.target.value,
              })
            }
            disabled={true}
            value={bvnIdentificationDetails.accountName}
            hasSpecialStyle
          />
        </div>
        {getCurrentLoggedInUser?.first_name &&
        getCurrentLoggedInUser?.dob &&
        getCurrentLoggedInUser?.description ? (
          ""
        ) : (
          <div>
            <div className="bvn-inner-form-cover">
              <NormalInputForm
                customWidth={"63%"}
                placeholder={"BVN"}
                labelTitle={"Enter your BVN Number"}
                handleChange={(evt) =>
                  handleOnChangeBvnVerification(evt.target.value)
                }
                disabled={getCurrentLoggedInUser?.description ? true : false}
                value={bvnIdentificationDetails.bvnNumber}
              />
            </div>
            <div>
              {" "}
              <NormalInputForm
                customWidth={"63%"}
                labelTitle={"Date of Birth"}
                placeholder={"date of birth"}
                type={getCurrentLoggedInUser?.dob ? "text" : "date"}
                handleChange={(evt) =>
                  setbvnIdentificationDetails({
                    ...bvnIdentificationDetails,
                    dateOfBirth: evt.target.value,
                  })
                }
                value={bvnIdentificationDetails?.dateOfBirth}
                disabled={getCurrentLoggedInUser?.dob ? true : false}
              />
            </div>
          </div>
        )}

        {globalErrorMessage.message ? (
          <div style={{ marginTop: "20px" }}>
            <MessageComponent messageDetails={globalErrorMessage} />
          </div>
        ) : (
          ""
        )}
        <div
          style={{ marginTop: "58px", paddingBottom: "459px" }}
          className="mobile-primary-button-cover"
        >
          <PrimaryButton
            buttonWith={"21.65%"}
            text={"Next"}
            handleClickButton={handleRouteToGuarantor}
          />
        </div>
      </div>
    </div>
  );
}
