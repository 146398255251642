import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import services from "../../services/services";
import { formatToCurrency } from "../../utilities/utilities";
import "./borrowTable.css";
import moment from "moment";
import RepaymentButton from "../repayment/repaymentButton";
export default function BorrowLoanTable({ id }) {
  const userDetails = useSelector((state) => state.userDetails);
  const loaSchedule = useSelector((state) => state.loaSchedule);
  const getIndividualLoan = useSelector((state) => state.getIndividualLoan);
  const dispatch = useDispatch();

  useEffect(() => {
    const getLoanSchedule = new services().getServiceWithId(
      "repayments/schedule",
      id.id,
      userDetails.token
    );
    getLoanSchedule
      .then((data) => {
        dispatch({ type: "LOAN_SCEDULE_ACTION", data: data?.data });

        // console.log(data.data.result);
      })
      .catch((error) => {
        console.log(error);
      });

    const getIndividualLoan = new services().getServiceWithId(
      "loan",
      id.id,
      userDetails.token
    );
    getIndividualLoan
      .then((res) => {
        if (res.errorMessage) {
          dispatch({ type: "GET_INDIVIDUAL_LOAN_DETAILS", data: {} });
        } else {
          dispatch({ type: "GET_INDIVIDUAL_LOAN_DETAILS", data: res?.data });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  // console.log(loaSchedule.result);
  const handleMakeRepayment = (due) => {
    dispatch({ type: "SHOW_SPINNER", data: true });
    // console.log(loanId);
    let data = {
      loan_id: id.id,
      amount: due,
    };
    const postRepayment = new services().postServiceWithToken(
      "repayments/make-repayment",
      data,
      userDetails.token
    );
    postRepayment
      .then((res) => {
        if (res.errorMessage) {
          let errorMessage = {
            message: res.errorMessage,
            status: false,
          };

          dispatch({ type: "ERROR_MESSAGE", data: errorMessage });
        } else {
          let errorMessage = {
            message: "Repayment successful",
            status: true,
          };

          dispatch({ type: "ERROR_MESSAGE", data: errorMessage });
        }
        dispatch({ type: "SHOW_SPINNER", data: false });

        dispatch({ type: "SHOW_TOAST", data: true });
      })
      .catch((error) => {
        dispatch({ type: "SHOW_SPINNER", data: false });
        console.log(error);
      });
  };
  // console.log(getIndividualLoan?.result?.balance);
  return (
    <div className="borrow-loan-table">
      <table class="table table-striped">
        <tbody>
          <tr>
            <td className="table-header-title start-table-data"> Date</td>
            <td className="table-header-title"></td>
            <td className="table-header-title"> Principal </td>
            <td className="table-header-title"> Interest</td>
            <td className="table-header-title">Due</td>
            <td className="table-header-title"> Paid</td>
            {getIndividualLoan?.status === "disbursable" ? (
              <td className="table-header-title">Action</td>
            ) : (
              ""
            )}

            <td className="table-header-title"> Balance</td>
          </tr>
          <tr>
            <td className="rec-and-amount start-table-data"> </td>
            <td className=""></td>
            <td className=""></td>
            <td className=""></td>
            <td className=""></td>
            <td className=""></td>
            {getIndividualLoan?.status === "disbursable" ? <td></td> : ""}
            <td className="rec-data-amount">
              ₦
              {getIndividualLoan?.result?.balance
                ? formatToCurrency(getIndividualLoan?.result?.balance)
                : 0}{" "}
            </td>
          </tr>
          {loaSchedule?.result?.map((list, i) => {
            return (
              <tr key={i}>
                <td>
                  {" "}
                  <span className="list-data-record start-table-data">
                    {list?.due_date
                      ? moment(list?.due_date).format("MMM Do YY")
                      : "-"}
                  </span>{" "}
                </td>
                <td>
                  {" "}
                  <span className="list-data-record">{"Repayment"}</span>
                </td>
                <td>
                  {" "}
                  <span className="list-data-record">
                    ₦
                    {list?.principal_outstanding
                      ? formatToCurrency(list?.principal_outstanding)
                      : 0}
                  </span>{" "}
                </td>
                <td>
                  {" "}
                  <span className="list-data-record">
                    {" "}
                    ₦{list?.interest ? formatToCurrency(list?.interest) : 0}
                  </span>
                </td>
                <td>
                  {" "}
                  <span className="list-data-record">
                    ₦
                    {list?.due_principal
                      ? formatToCurrency(list?.due_principal)
                      : 0}
                  </span>{" "}
                </td>
                <td>
                  {" "}
                  <span className="list-data-record">{list?.paid}</span>{" "}
                </td>

                {getIndividualLoan?.status === "disbursable" ? (
                  <td>
                    {" "}
                    <span
                      style={{
                        borderRadius: "30px",
                        border: "1px solid #1EAAE7",
                        color: "#1EAAE7",
                        background: "white",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                        paddingLeft: "20px",
                        paddingRight: "20px",
                        fontSize: "16px",
                        cursor: "pointer",
                        height: "50px",
                      }}
                      onClick={() => handleMakeRepayment(list?.due_principal)}
                    >
                      Pay Now
                    </span>{" "}
                  </td>
                ) : (
                  ""
                )}

                <td>
                  {" "}
                  <span className="list-data-record">
                    ₦{list?.balance ? formatToCurrency(list?.balance) : 0}
                  </span>{" "}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
