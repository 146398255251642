import Home from "../images/inactive-dashboard.svg";
import Person from "../images/profile_white.svg";
import Person2 from "../images/profile_grey.svg";
import Loan from "../images/payment-card.png";
import Payment from "../images/credit-card1.png";
import Signout from "../images/logout_icon.svg";
import Ahome from "../images/active-dashboard-icon.svg";
import loan2 from "../images/loan_grey.svg";
import loan from "../images/loan_white.svg";
import Irepayment from "../images/i-payment-card.png";
import InactiveWallet from "../images/inactive-wallet.png";
export const router = [
  {
    id: 1,
    name: "Dashboard",
    icon: Ahome,
    inctiveIcon: Home,
    marginBottom: "40px",
    marginLeft: "31px",
    color: "#FFFFFF",
    hasChildren: false,
    routeName: "/dashboard",
    checkName: "/dashboard",
  },
  // {
  //   id: 2,
  //   name: "My Wallet",
  //   icon: Iwallet,
  //   inctiveIcon: InactiveWallet,
  //   marginBottom: "40px",
  //   marginLeft: "31px",
  //   color: "#FFFFFF",
  //   hasChildren: false,
  //   routeName: "/wallet",
  //   checkName: "/wallet",
  // },

  {
    routeName: "/existing-loan",
    id: 3,
    name: "Loans",
    icon: loan,
    inctiveIcon: loan2,
    marginBottom: "40px",
    marginLeft: "31px",
    color: "#FFFFFF",
    hasChildren: true,

    checkName: "/existing-loan",

    subMenu: [
      {
        id: 1,
        name: "Apply",
        routeName: "/loans/select-loan",
      },
      {
        id: 2,
        name: "View Loans",
        routeName: "/existing-loan",
      },
      {
        id: 3,
        name: "Make Payment",
        routeName: "/repayment",
      },
    ],
  },
  // {
  //   id: 4,
  //   name: "Repayments",
  //   inctiveIcon: Payment,
  //   icon: Payment,
  //   marginBottom: "40px",
  //   marginLeft: "31px",
  //   color: "#FFFFFF",
  //   routeName: "/repayment",
  //   checkName: "/repayment",
  // },
  {
    id: 7,
    name: "My Account",
    icon: Person,
    inctiveIcon: Person2,
    marginBottom: "40px",
    marginLeft: "31px",
    color: "#FFFFFF",
    hasChildren: false,
    routeName: "/my-account",
    checkName: "/my-account",
  },
  {
    id: 5,
    name: "Sign out",
    icon: Signout,
    inctiveIcon: Signout,
    marginBottom: "40px",
    marginLeft: "31px",
    color: "#FFFFFF",
    hasChildren: false,
    routeName: "/sign-out",
  },
];
