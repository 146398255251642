export default class services {
  async postService(pathName, data) {
    try {
      let response = await fetch(
        `${process.env.REACT_APP_BASEURL}${pathName}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({ ...data }),
        }
      );

      return await response.json();
    } catch (err) {
      throw err;
    }
  }
  async postServiceWithToken(pathName, data, token) {
    try {
      let response = await fetch(
        `${process.env.REACT_APP_BASEURL}${pathName}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `${token}`,
          },
          body: JSON.stringify({ ...data }),
        }
      );


      return await response.json();
    } catch (err) {
      throw err;
    }
  }
  async getService(pathName, token) {
    try {
      let response = await fetch(
        `${process.env.REACT_APP_BASEURL}${pathName}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `${token}`,
          },
        }
      );

      return await response.json();
    } catch (err) {
      throw err;
    }
  }
  async getServiceWithId(pathName, id, token) {
    try {
      let response = await fetch(
        `${process.env.REACT_APP_BASEURL}${pathName}/${id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `${token}`,
          },
        }
      );

      return await response.json();
    } catch (err) {
      throw err;
    }
  }

  async getBanksService(accountNumber, bankCode, token) {
    try {
      let response = await fetch(
        `https://api.paystack.co/bank/resolve?account_number=${accountNumber}&bank_code=${bankCode}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${"sk_live_b79ca5077a3bbedc144afb72aa802e3e3693fe46"}`,
          },
        }
      );

      return await response.json();
    } catch (err) {
      throw err;
    }
  }

  async postBvnMatch(data) {
    try {
      let response = await fetch(`https://api.paystack.co/bvn/match`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${"sk_live_b79ca5077a3bbedc144afb72aa802e3e3693fe46"}`,
        },
        body: JSON.stringify({ ...data }),
      });

      return await response.json();
    } catch (err) {
      throw err;
    }
  }
  async postFileUpload(pathName, data, token) {
    try {
      let response = await fetch(
        `${process.env.REACT_APP_BASEURL}${pathName}`,
        {
          method: "POST",
          headers: {
            "content-type": "multipart/form-data",

            Authorization: `Bearer ${token}`,
          },
          body: data,
        }
      );

      return await response.json();
    } catch (err) {
      throw err;
    }
  }

  async patchServices(pathName, data, token) {
    try {
      let response = await fetch(
        `${process.env.REACT_APP_BASEURL}${pathName}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `${token}`,
          },
          body: JSON.stringify({ ...data }),
        }
      );

      return await response.json();
    } catch (err) {
      throw err;
    }
  }
}
