import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import iconList from "../../images/list-icon.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./loanDropDownIcon.css";
import { Dropdown } from "react-bootstrap";
import services from "../../services/services";
export default function BasicMenu({
    listDetails,
    forMobile,
    loanId,
    loanAmount,
    getIndex,
    sendIndex,
}) {
    const getLoanDetailsOpenState = useSelector(
        (state) => state.getLoanDetailsOpenState
    );
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const userDetails = useSelector((state) => state.userDetails);
    const borrowerDetails = useSelector((state) => state.getBorrowerDetails);
    const getRepayment = useSelector((state) => state.getRepayment);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleViewDetails = (data, listName) => {
        console.log(listName);
        if (listName === "View details") {
            getIndex(sendIndex);

            if (getLoanDetailsOpenState) {
                dispatch({ type: "OPEN_LOAN_DETAILS_STATE", data: false });
            } else {
                dispatch({ type: "OPEN_LOAN_DETAILS_STATE", data: true });
            }
        } else if (
            listName === "View Loan Schedule" ||
            listName === "View schedule"
        ) {
            navigate(`/borrower-loan-schedule/${loanId}`);
        } else if (listName === "Make Repayment") {
            dispatch({ type: "SHOW_SPINNER", data: true });
            // console.log(loanId);
            let data = {
                loan_id: loanId,
                amount: loanAmount,
            };
            const postRepayment = new services().postServiceWithToken(
                "repayments/make-repayment",
                data,
                userDetails.token
            );
            postRepayment
                .then((res) => {
                    if (res.errorMessage) {
                        let errorMessage = {
                            message: res.errorMessage,
                            status: false,
                        };

                        dispatch({ type: "ERROR_MESSAGE", data: errorMessage });
                    } else {
                        let errorMessage = {
                            message: "Repayment successful",
                            status: true,
                        };
                        window.location.href = res?.data?.authorization_url;

                        dispatch({ type: "ERROR_MESSAGE", data: errorMessage });
                    }
                    dispatch({ type: "SHOW_SPINNER", data: false });

                    dispatch({ type: "SHOW_TOAST", data: true });
                })
                .catch((error) => {
                    dispatch({ type: "SHOW_SPINNER", data: false });
                    console.log(error);
                });
        } else if (listName === "Create Consent Recova") {
            dispatch({ type: "SHOW_SPINNER", data: true });
            // console.log(loanId);
            let data = {
                borrower_id: borrowerDetails?.id,
                date_of_collection: new Date(),
            };
            const postConsent = new services().postServiceWithToken(
                `/repayments/${loanId}/create-recova-consent`,
                data,
                userDetails.token
            );
            postConsent
                .then((res) => {
                    if (res.errorMessage) {
                        let errorMessage = {
                            message: res.errorMessage,
                            status: false,
                        };

                        dispatch({ type: "ERROR_MESSAGE", data: errorMessage });
                    } else {
                        let errorMessage = {
                            message: "Consent Created",
                            status: true,
                        };
                        dispatch({ type: "ERROR_MESSAGE", data: errorMessage });
                    }
                    dispatch({ type: "SHOW_SPINNER", data: false });

                    dispatch({ type: "SHOW_TOAST", data: true });
                })
                .catch((error) => {
                    dispatch({ type: "SHOW_SPINNER", data: false });
                    console.log(error);
                });
        } else if (listName === "View Repayments") {
            navigate(`/repayment/${loanId}`);
        } else if (listName === "View Next Repayment") {
            navigate(`/next-repayment/${loanId}`);
        } else if (listName === "Setup Direct Debit") {
            navigate("/loans/credit-check");
            dispatch({
                type: "GET_LOAN_ID",
                data: { loanId: loanId },
            });
        } else if (listName === "Upload bank Statement") {
            navigate("/loans/upload-bank-statement");
            dispatch({
                type: "GET_LOAN_ID",
                data: { loanId: loanId },
            });
        } else if (listName === "Upload files") {
            navigate("/loans/borrow-file");
        }

        return dispatch({ type: "SHOW_TOAST", data: false });
    };

    React.useEffect(() => {
        const getFromServerRepayment = new services().getServiceWithId(
            "repayments/due",
            loanId,
            userDetails.token
        );
        getFromServerRepayment
            .then((res) => {
                if (res.errorMessage) {
                    dispatch({ type: "GET_REPAYMENT_DETAILS", data: {} });
                } else {
                    dispatch({
                        type: "GET_REPAYMENT_DETAILS",
                        data: res?.data,
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);
    return (
        <div style={{ zIndex: 2000 }}>
            <Dropdown>
                <Dropdown.Toggle
                    id="dropdown-basic"
                    style={{
                        background: "none",
                        border: "none",
                        position: "relative",
                    }}
                >
                    <img
                        src={iconList}
                        alt="icon"
                        style={{
                            position: "absolute",
                            left: "50%",
                            top: "25%",
                        }}
                    />
                </Dropdown.Toggle>

                <Dropdown.Menu
                    style={{
                        marginLeft: "-80%",
                        marginTop: "30%",
                        border: "none",
                        filter: "drop-shadow(10px 10px 20px rgba(0, 0, 0, 0.102))",
                        background: "white",
                        zIndex: 1000,
                    }}
                >
                    {listDetails?.map((list) => (
                        <Dropdown.Item
                            style={{
                                paddingBottom: "20px",
                                textAlign: forMobile ? "left" : "center",
                                fontSize: "15px",
                                color: "#313942",
                                position: "relative",
                                paddingTop: "16px",
                            }}
                            className="list1"
                            key={list.id}
                            onClick={() =>
                                handleViewDetails(list.id, list.name)
                            }
                        >
                            {list.name}
                            <hr
                                style={{
                                    position: "absolute",
                                    width: "100%",
                                    left: forMobile ? "0%" : "20%",
                                    width: forMobile ? "100%" : "60%",
                                    borderColor: "#9597A6",
                                    opacity: 0.2,
                                }}
                            />
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
}
