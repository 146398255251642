import React from "react";
import "./roundSelectInput.css";
import Valid from "../../images/valid.png";
import Invalid from "../../images/invalid.png";
import SelectDropdown from "../../images/select-dropdown.png";
export default function RoundSelectInput({
  isValid,
  right,
  top,
  arrowRight,
  arrowTop,
  dropDownValue,
  hasTitle,
  handleSelectChange,
  value,
  hasSex,
  hasStateValue,
  hasCity,
  disabled,
}) {
  return (
    <div className="round-select-cover">
      <div style={{ position: "absolute", right: right, top: top }}>
        <img src={isValid ? Valid : Invalid} alt="checked" />
      </div>
      <div
        style={{
          position: "absolute",
          right: arrowRight,
          top: arrowTop,
          cursor: "pointer",
        }}
      >
        <img src={SelectDropdown} alt="dropdown" />
      </div>
      <select
        class="form-control round-select-input"
        onChange={handleSelectChange}
        value={value}
        disabled={disabled ? true : false}
      >
        {hasStateValue && <option value="">State of Origin</option>}
        {hasCity && <option value="">City</option>}

        {hasTitle &&
          dropDownValue?.map((list) => (
            <option key={list.id} value={list.name}>
              {list.name}
            </option>
          ))}
        {hasSex &&
          dropDownValue?.map((list) => (
            <option key={list.id} value={list.value}>
              {list.name}
            </option>
          ))}

        {hasStateValue &&
          dropDownValue?.map((list) => (
            <option key={list.state.id} value={list.state.name}>
              {list.state.name}
            </option>
          ))}
        {hasCity &&
          dropDownValue?.map((list) => (
            <option key={list.id} value={list.name}>
              {list.name}
            </option>
          ))}
      </select>
    </div>
  );
}
