import React, { useState } from "react";
import { useNavigate } from "react-router";
import { formatToCurrency, getStatusColor } from "../../utilities/utilities";
import LoanDropDownIcons from "../loanIconDropDown/loanDropDownIcons";
import ToastNotification from "../toastNotificaion/toastNotification";
import moment from "moment";
import Tooltip from "@mui/material/Tooltip";
import "./viewLoanTableComponent.css";
import { useSelector } from "react-redux";

export default function ViewLoanTableComponent({ getExistingLoan }) {
    const navigate = useNavigate();
    const [showDropdown, setshowDropdown] = useState("");
    const [pendingList, setpendingList] = useState([
        { id: "2", name: "View schedule" },

        // { id: "4", name: "Setup Direct Debit" },
        { id: "5", name: "Upload bank Statement" },
        { id: "6", name: "Upload files" },
        { id: "7", name: "Upload borrower profile" },
        { id: "8", name: "connect bank account" },
    ]);
    const [notPendingList, setnotPendingList] = useState([
        // { id: "1", name: "Setup Direct Debit" },
        { id: "2", name: "View Loan Schedule" },
        { id: "10", name: "Create Consent Recova" },
        // { id: "3", name: "View Next Repayment" },
        // { id: "4", name: "Make Repayment" },
        // { id: "5", name: "View Repayments" },
    ]);
    const [restructuredList, setrestructuredList] = useState([
        { id: "2", name: "View schedule" },
        { id: "8", name: "Accept loan offer" },
        // { id: "4", name: "Setup Direct Debit" },
        { id: "5", name: "Upload bank Statement" },
        { id: "6", name: "Upload files" },
        { id: "7", name: "Upload borrower profile" },
        { id: "8", name: "connect bank account" },
    ]);

    const getIndex = () => {};
    const handleShowDropDown = (loanId) => {
        if (showDropdown === loanId) {
            setshowDropdown("");
        } else {
            setshowDropdown(loanId);
        }
    };
    const userDetails = useSelector((state) => state.userDetails);
    return (
        <div
            style={{
                paddingTop: "30px",
                paddingBottom: "13px",
                borderRadius: "30px",
            }}
            className="loan-main-table-cover"
        >
            {getExistingLoan.length ? (
                <div className="">
                    <div>
                        {/* <div className="loan-list-header row">
            <div className="col loan-table-header-title">Loan ID</div>
            <div className="col loan-table-header-title">Loan Amount</div>
            <div className="col loan-table-header-title">Duration</div>
            <div className="col loan-table-header-title">Repayment</div>
            <div className="col loan-table-header-title">Status</div>
            <div className="col loan-table-header-title">Action</div>
          </div> */}

                        {getExistingLoan.map((list) => (
                            <div>
                                <Tooltip title="Click to collapse">
                                    <div
                                        className={`${
                                            showDropdown === list?.id
                                                ? "loan-table-data-content-active"
                                                : "loan-table-data-content"
                                        } row`}
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                            handleShowDropDown(list?.id)
                                        }
                                    >
                                        {" "}
                                        <div
                                            className={`${
                                                showDropdown === list?.id
                                                    ? "loan-data-details-col-active"
                                                    : "loan-data-details-col"
                                            } col`}
                                        >
                                            <div className="inner-div-data">
                                                {" "}
                                                {userDetails?.first_name +
                                                    " " +
                                                    userDetails?.last_name}
                                            </div>
                                        </div>
                                        <div
                                            className={`${
                                                showDropdown === list?.id
                                                    ? "loan-data-details-col-active"
                                                    : "loan-data-details-col"
                                            } col`}
                                        >
                                            <div className="inner-div-data">
                                                {moment(list?.createdAt).format(
                                                    "MMM Do YY"
                                                )}
                                            </div>
                                        </div>
                                        <div
                                            className={`${
                                                showDropdown === list?.id
                                                    ? "loan-data-details-col-active"
                                                    : "loan-data-details-col"
                                            } col`}
                                        >
                                            <div className="inner-div-data">
                                                ₦
                                                {list?.initial_principal
                                                    ? formatToCurrency(
                                                          list?.initial_principal
                                                      )
                                                    : formatToCurrency(0)}
                                            </div>
                                        </div>
                                        <div className="col loan-data-details-col-process">
                                            <div
                                                className="in-progress-span"
                                                style={{
                                                    color: getStatusColor(
                                                        list?.status
                                                    ).color,
                                                    background: getStatusColor(
                                                        list?.status
                                                    ).background,
                                                }}
                                            >
                                                {list?.status}
                                            </div>
                                        </div>
                                        <div
                                            className={`${
                                                showDropdown === list?.id
                                                    ? "loan-data-details-col-active"
                                                    : "loan-data-details-col"
                                            } col`}
                                        >
                                            <div className="inner-div-data">
                                                <LoanDropDownIcons
                                                    id="1"
                                                    listDetails={
                                                        list?.status ===
                                                        "pending"
                                                            ? pendingList
                                                            : list?.status ===
                                                              "restructured"
                                                            ? restructuredList
                                                            : notPendingList
                                                    }
                                                    loanId={list?.id}
                                                    getIndex={getIndex}
                                                    loanAmount={
                                                        list?.initial_principal
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </Tooltip>
                                {/* more loan dropdown details */}
                                {showDropdown === list?.id && (
                                    <div>
                                        <div className="loan-table-data-content row">
                                            {" "}
                                            <div className="col loan-data-details-col">
                                                <div className="inner-div-data">
                                                    <span
                                                        style={{
                                                            color: "#727272",
                                                        }}
                                                    >
                                                        {" "}
                                                        Loan Id
                                                    </span>
                                                    <br />
                                                    {list?.loan_acct_id}
                                                </div>
                                            </div>
                                            <div className="col loan-data-details-col">
                                                <div className="inner-div-data">
                                                    <span
                                                        style={{
                                                            color: "#727272",
                                                        }}
                                                    >
                                                        {" "}
                                                        Loan Type
                                                    </span>
                                                    <br />
                                                    {list?.type}
                                                </div>
                                            </div>
                                            <div className="col loan-data-details-col">
                                                <div className="inner-div-data">
                                                    <span
                                                        style={{
                                                            color: "#727272",
                                                        }}
                                                    >
                                                        Amount
                                                        <br />₦
                                                    </span>
                                                    {list?.initial_principal
                                                        ? formatToCurrency(
                                                              list?.initial_principal
                                                          )
                                                        : formatToCurrency(0)}
                                                </div>
                                            </div>
                                            <div className="col loan-data-details-col">
                                                <div className="inner-div-data">
                                                    <span
                                                        style={{
                                                            color: "#727272",
                                                        }}
                                                    >
                                                        Duration
                                                        <br />
                                                    </span>
                                                    {list?.loan_duration &&
                                                    list?.loan_duration_period
                                                        ? list?.loan_duration +
                                                          " " +
                                                          list?.loan_duration_period
                                                        : "-"}
                                                </div>
                                            </div>
                                            <div className="col loan-data-details-col">
                                                <div className="inner-div-data">
                                                    <span
                                                        style={{
                                                            color: "#727272",
                                                        }}
                                                    >
                                                        Date
                                                        <br />
                                                    </span>
                                                    {moment(
                                                        list?.createdAt
                                                    ).format("MMM Do YY")}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div
                                                style={{
                                                    paddingTop: "12px",
                                                    paddingBottom: "12px",
                                                    fontSize: "14px",
                                                    background: "#EDEDED",
                                                    color: "rgba(0, 0, 0, 0.68)",
                                                    paddingLeft: "15px",
                                                    paddingRight: "15px",
                                                    borderRadius: "30px",
                                                    textAlign: "center",
                                                }}
                                            >
                                                {list?.comment}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            ) : (
                <div
                    style={{
                        textAlign: "center",
                        color: "#858585",
                        fontSize: "14px",
                        marginTop: "135px",
                    }}
                >
                    You have no existing loan. Apply now for a loan.
                    <div>
                        <button
                            style={{
                                width: "261px",
                                paddingTop: "15px",
                                paddingBottom: "15px",
                                background: "#47B638 ",
                                border: "none",
                                borderRadius: "26px",
                                color: "white",
                                marginTop: "57px",
                            }}
                            onClick={() => navigate("/loans/select-loan")}
                        >
                            Apply now{" "}
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}
