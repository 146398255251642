import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import TitleComponentHeader from "../../components/titleComponent/TitleComponent";
import services from "../../services/services";
// import "./employmentDetails.css";

import GuarantorEmploymentFormCover from "./guarantorEmploymentForm";
import ListOfEmploymentStatusCard from "../applyLoan/employmentDetails/listOfEmploymentStatusCard/listOfEmploymentStatusCard";
import MessageComponent from "../../components/messageComponent/messageComponent";
import PrimaryButton from "../../components/primaryButton/primaryButton";
import SelectInput from "../../components/selectInput/selectInput";

export default function GuarantorEmploymentDetails() {
    const navigate = useNavigate();
    const globalErrorMessage = useSelector((state) => state.globalErrorMessage);
    const getEmploymentDetails = useSelector(
        (state) => state.getEmploymentDetails
    );
    const getBvnRecordDetails = useSelector(
        (state) => state.getBvnRecordDetails
    );
    const loanProductSlected = useSelector((state) => state.loanProductSlected);
    const setBorrowerDetails = useSelector((state) => state.setBorrowerDetails);
    const [employmentStatus, setemploymentStatus] = useState([
        { id: 1, name: "Government Employee" },
        { id: 2, name: "Employee" },
        { id: 3, name: "Business Owner" },
        { id: 4, name: "Pensioner" },
        { id: 5, name: "Student" },
    ]);

    const setEmploymentDetails = useSelector(
        (state) => state.setEmploymentDetails
    );
    const getListOfWorkStatus = useSelector(
        (state) => state.getListOfWorkStatus
    );
    const userDetails = useSelector((state) => state.userDetails);
    const [EmploymentStatusName, setEmploymentStatusName] =
        useState("Business Owner");
    const [getStatusFromServer, setgetStatusFromServer] = useState(false);
    const dispatch = useDispatch();
    const handleEmploymentStatusFromServer = (employmentStatus) => {
        setEmploymentStatusName(
            employmentStatus ? employmentStatus : "Business Owner"
        );
        setgetStatusFromServer(employmentStatus ? true : false);
    };
    const [addNewEmploymentDetails, setaddNewEmploymentDetails] =
        useState(false);

    useEffect(() => {
        if (Object.entries(setEmploymentDetails).length) {
            setEmploymentStatusName(setEmploymentStatusName.employmentStatus);
        }
    }, []);

    useEffect(() => {
        const getUserDetails = new services().getService(
            "borrower",
            userDetails.token
        );
        getUserDetails
            .then((res) => {
                if (!res.errorMessage) {
                    dispatch({
                        type: "LIST_OF_WORK_STATUS",
                        data: res.data.WorkStatus,
                    });
                }
            })
            .catch((error) => {
                let errorMessage = {
                    message: "an error occur,please check your network.",
                    status: false,
                };

                dispatch({ type: "ERROR_MESSAGE", data: errorMessage });
            });
        let errorMessage = {
            message: "",
            status: false,
        };

        dispatch({ type: "ERROR_MESSAGE", data: errorMessage });
    }, []);

    const [sendSelectedCardDataToFunction, setsendSelectedCardDataToFunction] =
        useState({});

    const getCardClickData = (data) => {
        if (data.type === "Student") {
            // console.log(employmentDetails);
            let dataS = {
                type: data.type,
                school_name: data.school_name,
                level: data.level,
                country: data.country,
            };
            let postWorkStatus = new services().postServiceWithToken(
                "borrower/working-status",
                dataS,
                userDetails.token
            );
            postWorkStatus
                .then((res) => {
                    if (res.errorMessage) {
                        let errorMessage = {
                            message: res.errorMessage,
                            status: false,
                        };

                        dispatch({ type: "ERROR_MESSAGE", data: errorMessage });
                    } else {
                        let successMessage = {
                            message: "Student Details successfully created.",
                            status: true,
                        };

                        dispatch({
                            type: "ERROR_MESSAGE",
                            data: successMessage,
                        });
                        setTimeout(() => {
                            let clearMessage = {
                                message: "",
                                status: false,
                            };

                            dispatch({
                                type: "ERROR_MESSAGE",
                                data: clearMessage,
                            });
                            navigate("/loans/guarantor-details");
                        }, 500);

                        dispatch({
                            type: "GET_GUARANTOR_EMPLOYMENT_DETAILS",
                            data: res?.data,
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        } else if (data.type === "Business Owner") {
            let dataB = {
                type: data.type,
                business_name: data.business_name,
                work_phone: data.work_phone,
                country: data.country,
            };
            let postWorkStatus = new services().postServiceWithToken(
                "borrower/working-status",
                dataB,
                userDetails.token
            );
            postWorkStatus
                .then((res) => {
                    if (res.errorMessage) {
                        let errorMessage = {
                            message: res.errorMessage,
                            status: false,
                        };
                        dispatch({ type: "ERROR_MESSAGE", data: errorMessage });
                    } else {
                        let successMessage = {
                            message: "Employment details successfully created.",
                            status: true,
                        };
                        dispatch({
                            type: "ERROR_MESSAGE",
                            data: successMessage,
                        });
                        setTimeout(() => {
                            let clearMessage = {
                                message: "",
                                status: false,
                            };

                            dispatch({
                                type: "ERROR_MESSAGE",
                                data: clearMessage,
                            });
                            navigate("/loans/guarantor-details");
                        }, 500);

                        dispatch({
                            type: "GET_GUARANTOR_EMPLOYMENT_DETAILS",
                            data: res?.data,
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        } else if (data.type === "Employee") {
            let dataE = {
                type: data.type,
                organization_name: data.organization_name,
                work_phone: data.work_phone,
                country: data.country,
                occupation: data.occupation,
            };
            let postWorkStatus = new services().postServiceWithToken(
                "borrower/working-status",
                dataE,
                userDetails.token
            );
            postWorkStatus
                .then((res) => {
                    if (res.errorMessage) {
                        let errorMessage = {
                            message: res.errorMessage,
                            status: false,
                        };

                        dispatch({ type: "ERROR_MESSAGE", data: errorMessage });
                    } else {
                        let successMessage = {
                            message: "Employment details successfully created.",
                            status: true,
                        };

                        dispatch({
                            type: "ERROR_MESSAGE",
                            data: successMessage,
                        });

                        setTimeout(() => {
                            let clearMessage = {
                                message: "",
                                status: false,
                            };

                            dispatch({
                                type: "ERROR_MESSAGE",
                                data: clearMessage,
                            });
                            navigate("/loans/guarantor-details");
                        }, 500);

                        dispatch({
                            type: "GET_GUARANTOR_EMPLOYMENT_DETAILS",
                            data: res?.data,
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        } else if (data.type === "Government Employee") {
            let dataG = {
                type: data.type,
                occupation: data.occupation,
                work_phone: data.work_phone,
                country: data.country,
                level: data.level,
                organization_name: data.organization_name,
            };
            const postWorkStatus = new services().postServiceWithToken(
                "borrower/working-status",
                dataG,
                userDetails.token
            );
            postWorkStatus
                .then((res) => {
                    if (res.errorMessage) {
                        let errorMessage = {
                            message: res.errorMessage,
                            status: false,
                        };

                        dispatch({ type: "ERROR_MESSAGE", data: errorMessage });
                    } else {
                        let successMessage = {
                            message: "Employment details successfully created.",
                            status: true,
                        };

                        dispatch({
                            type: "ERROR_MESSAGE",
                            data: successMessage,
                        });
                        setTimeout(() => {
                            let clearMessage = {
                                message: "",
                                status: false,
                            };

                            dispatch({
                                type: "ERROR_MESSAGE",
                                data: clearMessage,
                            });
                            navigate("/loans/guarantor-details");
                        }, 500);

                        dispatch({
                            type: "GET_GUARANTOR_EMPLOYMENT_DETAILS",
                            data: res?.data,
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        } else if (data.type === "Pensioner") {
            let dataP = {
                type: data.type,
                occupation: data.occupation,
                organization_name: data.organization_name,
                country: data.country,
                retirement_date: data.retirement_date,
            };
            const postWorkStatus = new services().postServiceWithToken(
                "borrower/working-status",
                dataP,
                userDetails.token
            );
            postWorkStatus
                .then((res) => {
                    if (res.errorMessage) {
                        let errorMessage = {
                            message: res.errorMessage,
                            status: false,
                        };

                        dispatch({ type: "ERROR_MESSAGE", data: errorMessage });
                    } else {
                        let successMessage = {
                            message: "Employment details successfully created.",
                            status: true,
                        };

                        dispatch({
                            type: "ERROR_MESSAGE",
                            data: successMessage,
                        });

                        setTimeout(() => {
                            let clearMessage = {
                                message: "",
                                status: false,
                            };

                            dispatch({
                                type: "ERROR_MESSAGE",
                                data: clearMessage,
                            });
                            navigate("/loans/guarantor-details");
                        }, 500);

                        dispatch({
                            type: "GET_GUARANTOR_EMPLOYMENT_DETAILS",
                            data: res?.data,
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        } else {
            let errorMessage = {
                message: "Select or fill out all required fields",
                status: false,
            };

            dispatch({ type: "ERROR_MESSAGE", data: errorMessage });
        }
    };

    const handleSwitchEmployment = () => {
        if (addNewEmploymentDetails) {
            setaddNewEmploymentDetails(false);
        } else {
            setaddNewEmploymentDetails(true);
        }
    };
    return (
        <div className="employment-cover">
            <div className="employment-title">
                <TitleComponentHeader
                    title={"Guarantor Employment Details"}
                    subTitle={"Kindly provide employments"}
                />
            </div>
            <div className="employment-form-cover">
                {addNewEmploymentDetails ? (
                    ""
                ) : globalErrorMessage.message ? (
                    <div style={{ marginTop: "20px" }}>
                        <MessageComponent messageDetails={globalErrorMessage} />
                    </div>
                ) : (
                    ""
                )}

                {getListOfWorkStatus.length && !addNewEmploymentDetails ? (
                    getListOfWorkStatus.map((list) => (
                        <ListOfEmploymentStatusCard
                            customWidth={"64%"}
                            customHeight={"125px"}
                            employmentDetails={list}
                            getCardClickEmploymentDetails={getCardClickData}
                        />
                    ))
                ) : (
                    <div>
                        <div style={{ marginBottom: "29px" }}>
                            <SelectInput
                                labelTitle={"Select employment status"}
                                placeholder={"Employment status"}
                                dropdownValue={employmentStatus}
                                customWidth={"64%"}
                                hasEmploymentStatus
                                optionValue={EmploymentStatusName}
                                handleChange={(e) =>
                                    setEmploymentStatusName(e.target.value)
                                }
                            />
                        </div>

                        <div>
                            <GuarantorEmploymentFormCover
                                hasEmploymentStatusFromServer={
                                    getStatusFromServer
                                }
                                getSelectedEmploymentDetails={
                                    EmploymentStatusName
                                }
                                employmentStatusFirstForm={employmentStatus}
                                getEmploymentStatusFromEndpoint={
                                    handleEmploymentStatusFromServer
                                }
                            />
                            {/* <FirstFormCover
                hasEmploymentStatusFromServer={getStatusFromServer}
                getSelectedEmploymentDetails={EmploymentStatusName}
                employmentStatusFirstForm={employmentStatus}
                getEmploymentStatusFromEndpoint={
                  handleEmploymentStatusFromServer
                }
              /> */}
                        </div>
                    </div>
                )}
            </div>
            {getListOfWorkStatus.length ? (
                <div style={{ paddingLeft: "50px", paddingTop: "50px" }}>
                    <PrimaryButton
                        text={
                            addNewEmploymentDetails
                                ? "Click to add existing work status"
                                : "Add New Employment Details"
                        }
                        buttonWith={"21.65%"}
                        handleClickButton={handleSwitchEmployment}
                    />
                </div>
            ) : (
                ""
            )}
        </div>
    );
}
