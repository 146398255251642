import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import services from "../../services/services";
import { formatToCurrency, getStatusColor } from "../../utilities/utilities";
import LoanHistoryDetails from "../ViewExistingLoan/loanHistory-details";
import LoanDropDownIcons from "../../components/loanIconDropDown/loanDropDownIcons";
import moment from "moment";
export default function BorrowMobileCards({ id }) {
    const getId = useParams();
    const userDetails = useSelector((state) => state.userDetails);
    const loaSchedule = useSelector((state) => state.loaSchedule);
    const getLoanDetailsOpenState = useSelector(
        (state) => state.getLoanDetailsOpenState
    );
    const dispatch = useDispatch();
    const getIndividualLoan = useSelector((state) => state.getIndividualLoan);
    const [addIndex, setaddIndex] = useState("");
    // const [mobileList, setmobileList] = useState([
    //   { id: "1", name: "View details" },
    //   { id: "2", name: "View schedule" },
    //   { id: "3", name: "Make repayment" },
    //   { id: "4", name: "Setup Direct Debit" },
    //   { id: "5", name: "Upload bank Statement" },
    //   { id: "6", name: "Upload files" },
    // ]);

    const [mobileList, setmobileList] = useState([
        { id: "1", name: "Setup Direct Debit" },
        { id: "2", name: "View Loan Schedule" },
        { id: "3", name: "View Next Repayment" },
        { id: "4", name: "Make Repayment" },
        { id: "5", name: "View Repayments" },
    ]);
    useEffect(() => {
        const getIndividualLoan = new services().getServiceWithId(
            "loan",
            getId?.id,
            userDetails.token
        );
        getIndividualLoan
            .then((res) => {
                if (res.errorMessage) {
                    dispatch({ type: "GET_INDIVIDUAL_LOAN_DETAILS", data: {} });
                } else {
                    dispatch({
                        type: "GET_INDIVIDUAL_LOAN_DETAILS",
                        data: res?.data,
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        const getLoanSchedule = new services().getServiceWithId(
            "repayments/schedule",
            getId?.id,
            userDetails.token
        );
        getLoanSchedule
            .then((data) => {
                dispatch({ type: "LOAN_SCEDULE_ACTION", data: data?.data });

                // console.log(data.data.result);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);
    console.log(getIndividualLoan);
    const handleOpenDetails = (id) => {
        if (getLoanDetailsOpenState) {
            dispatch({ type: "OPEN_LOAN_DETAILS_STATE", data: false });
        } else {
            dispatch({ type: "OPEN_LOAN_DETAILS_STATE", data: true });
        }
    };

    const handleMakeRepayment = (due) => {
        dispatch({ type: "SHOW_SPINNER", data: true });
        // console.log(loanId);
        let data = {
            loan_id: id.id,
            amount: due,
        };
        const postRepayment = new services().postServiceWithToken(
            "repayments/make-repayment",
            data,
            userDetails.token
        );
        postRepayment
            .then((res) => {
                if (res.errorMessage) {
                    let errorMessage = {
                        message: res.errorMessage,
                        status: false,
                    };

                    dispatch({ type: "ERROR_MESSAGE", data: errorMessage });
                } else {
                    let errorMessage = {
                        message: "Repayment successful",
                        status: true,
                    };

                    dispatch({ type: "ERROR_MESSAGE", data: errorMessage });
                }
                dispatch({ type: "SHOW_SPINNER", data: false });

                dispatch({ type: "SHOW_TOAST", data: true });
            })
            .catch((error) => {
                dispatch({ type: "SHOW_SPINNER", data: false });
                console.log(error);
            });
    };
    //   console.log(getLoanDetailsOpenState);
    return (
        <div>
            <div className="mobile-card-cover-page-for-loan">
                <div className="row m-0 p-0">
                    <div
                        className="col-5 content-and-amount-div"
                        onClick={() => handleOpenDetails(getIndividualLoan?.id)}
                    >
                        {getIndividualLoan?.type ? getIndividualLoan?.type : ""}
                        <br />
                        <span className="card-amount-span">
                            {" "}
                            ₦
                            {getIndividualLoan?.result?.balance
                                ? formatToCurrency(
                                      getIndividualLoan?.result?.balance
                                  )
                                : formatToCurrency(0)}
                        </span>
                    </div>
                    <div
                        className="col-6 status-card-for-mobile"
                        onClick={() => handleOpenDetails(getIndividualLoan?.id)}
                    >
                        <span
                            className="status-card-span"
                            style={{
                                color: getStatusColor(getIndividualLoan?.status)
                                    .color,
                                background: getStatusColor(
                                    getIndividualLoan?.status
                                ).background,
                            }}
                        >
                            {getIndividualLoan?.status}
                        </span>
                    </div>
                    <div className="col-1">
                        <LoanDropDownIcons
                            listDetails={mobileList}
                            forMobile
                            loanId={getIndividualLoan?.id}
                        />
                    </div>
                </div>
            </div>

            {getLoanDetailsOpenState ? (
                <LoanHistoryDetails details={getIndividualLoan} />
            ) : (
                ""
            )}
            <div>
                {loaSchedule?.result?.map((list) => (
                    <div className="mobile-card-cover-page-for-loan">
                        <div
                            className="flex  m-0 p-0 "
                            style={{
                                display: "flex",
                                justifyContent: "space-around",
                            }}
                        >
                            <div className="">
                                <span
                                    style={{
                                        fontSize: "10px",
                                        color: "#858585",
                                    }}
                                >
                                    {new Date(list?.due_date).toDateString()}
                                    {/* {moment().format("M/d/Y")} */}
                                </span>
                            </div>
                            <div
                                className="content-and-amount-div mx-5"
                                // onClick={() => handleOpenDetails(index)}
                            >
                                {" "}
                                {getIndividualLoan?.type
                                    ? getIndividualLoan?.type
                                    : ""}
                                <br />
                                <span className="card-amount-span">
                                    {" "}
                                    ₦
                                    {list?.total_payment
                                        ? formatToCurrency(list?.total_payment)
                                        : formatToCurrency(0)}
                                </span>
                            </div>
                            {/* <div>
                                {getIndividualLoan?.status === "disbursable" ? (
                                    <td>
                                        {" "}
                                        <span
                                            style={{
                                                borderRadius: "30px",
                                                border: "1px solid #1EAAE7",
                                                color: "#1EAAE7",
                                                background: "white",
                                                paddingTop: "5px",
                                                paddingBottom: "5 px",
                                                paddingLeft: "20px",
                                                paddingRight: "20px",
                                                fontSize: "16px",
                                                cursor: "pointer",
                                                height: "45px",
                                            }}
                                            onClick={() =>
                                                handleMakeRepayment(
                                                    list?.due_principal
                                                )
                                            }
                                        >
                                            Pay Now
                                        </span>{" "}
                                    </td>
                                ) : (
                                    ""
                                )}
                            </div> */}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}
