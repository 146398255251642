import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export default function ToastNotification() {
  const getShowSpinner = useSelector((state) => state.getShowSpinner);
  const showToastState = useSelector((state) => state.showToastState);
  const globalErrorMessage = useSelector((state) => state.globalErrorMessage);
  const notify = () => toast(`${globalErrorMessage.message}`);
  useEffect(() => {
    if (showToastState) {
      if (globalErrorMessage.message && globalErrorMessage.status) {
        toast.success(globalErrorMessage.message);
      } else {
        toast.error(globalErrorMessage.message);
      }
    }
  }, [showToastState]);
  return (
    <div>
      {/* <button onClick={notify}>Notify!</button> */}
      <ToastContainer theme={"dark"} />
    </div>
  );
}
