import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Routes, Route, Redirect, Switch } from "react-router-dom";


import "./App.css";
import LandingPage from "./auth/landingPage/landingPage";
import RootLandingPage from "./rootLandingPage/rootLandingPage";
import RootScreen from "./screen/rootScreen";
import {
  getDataFromLocalStorage,
  setDateToLocalStorage,
} from "./utilities/utilities";
import {CssBaseline} from "@mui/material";

function App() {
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.userDetails);
  useEffect(() => {
    dispatch({ type: "USER_DETAILS", data: getDataFromLocalStorage() });
    if (!getDataFromLocalStorage()) {
      setDateToLocalStorage({});
      dispatch({ type: "USER_DETAILS", data: {} });
    } else {
      dispatch({ type: "USER_DETAILS", data: getDataFromLocalStorage() });
    }
  }, []);
  console.log(userDetails);
  console.log(getDataFromLocalStorage());
  return (
    <BrowserRouter>
      <CssBaseline />
      <div className="App">
        {Object.entries(userDetails)?.length === 0 ? (
          <RootLandingPage />
        ) : (
          <RootScreen />
        )}
      </div>
    </BrowserRouter>
  );
}

export default App;
