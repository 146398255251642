import React, { useEffect, useState } from "react";
import "./sidebar.css";
import DesktopLogo from "../../images/dizz-logo.svg";
import { router } from "../../routes/router";
import { useNavigate, useLocation } from "react-router-dom";
import { setDateToLocalStorage } from "../../utilities/utilities";
import { useDispatch } from "react-redux";
import {
  Divider,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import List from "@mui/material/List";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";

function InboxIcon() {
  return null;
}

function MailIcon() {
  return null;
}

export default function Sidebar() {
  const getPathName = useLocation();
  const currentRoute = getPathName.pathname;
  const navigator = useNavigate();
  const [routeNameState, setrouteNameState] = useState("");
  const [resetDropDown, setresetDropDown] = useState(false);
  const [subRoute, setsubRoute] = useState("");
  const dispatch = useDispatch();
  const handleRoutePage = (routeName, hasChildren) => {
    if (!hasChildren) {
      if (routeName === "/sign-out") {
        setDateToLocalStorage({});
        dispatch({ type: "TOGGLE_MENU", data: false });

        dispatch({ type: "LOAN_PRODUCT_SELECTED", data: {} });
        dispatch({ type: "GET_RECORD_DETAILS", data: {} });
        dispatch({ type: "SET_EMPLOYMENT_DETAILS", data: {} });
        dispatch({
          type: "GET_LOAN_ID",
          data: {},
        });
        window.location.href = "/login";
      } else {
        navigator(routeName);
        setresetDropDown(false);
        dispatch({ type: "TOGGLE_MENU", data: false });
      }
    } else {
      navigator(routeName);
      setsubRoute("");
      setrouteNameState(routeName);

      if (resetDropDown) {
        setresetDropDown(false);
      } else {
        setresetDropDown(true);
      }
    }
  };

  const handleSubmenuClick = (routeName) => {
    setresetDropDown(true);
    navigator(routeName);
    setsubRoute(routeName);
    dispatch({ type: "TOGGLE_MENU", data: false });
    // setopenChildrenDropdown(true);
  };

  // routeNameState === list.routeName && resetDropDown &&

  let drawerWidth = 240;
  return (
    <div className="sidebar-cover">
      <div className="list-cover">
        <ul style={{ listStyle: "none", padding: "0px" }}>
          {router.map((list) => (
            <div>
              <li
                className="list-cover"
                style={{
                  marginBottom: list.hasChildren ? "16px" : list.marginBottom,
                  marginLeft: list.marginLeft,
                  marginRight: "12px",

                  cursor: "pointer",

                  transition: ".3s",

                  fontSize: "15px",
                  color: currentRoute === list.checkName ? "black" : "#BFBFBF",
                }}
                key={list.id}
                onClick={() =>
                  handleRoutePage(list.routeName, list.hasChildren)
                }
              >
                <span
                  style={{
                    marginRight: "11px",
                    padding: "15px",
                    background:
                      currentRoute === list.checkName ? "#2BC155" : "",
                    borderRadius: "100%",
                  }}
                >
                  <img
                    src={
                      currentRoute === list.checkName
                        ? list.icon
                        : list.inctiveIcon
                    }
                    alt="logo"
                    style={{ width: "25px", height: "25px" }}
                  />
                </span>
                {list.name}
              </li>
              {
                <div style={{ marginTop: "18px", paddingLeft: "99px" }}>
                  <ul style={{ listStyle: "none", padding: "0px" }}>
                    {list?.subMenu?.map((subList) => (
                      <div>
                        {(currentRoute === list.checkName ||
                          currentRoute === list?.subMenu[0]?.routeName ||
                          currentRoute === list?.subMenu[1]?.routeName) && (
                          <li
                            key={subList.id}
                            style={{
                              marginBottom: "16px",
                              fontSize: "14px",
                              color:
                                currentRoute === subList.routeName
                                  ? "black"
                                  : "#BFBFBF",

                              cursor: "pointer",
                            }}
                            onClick={() => {
                              handleSubmenuClick(subList.routeName);
                            }}
                          >
                            {subList.name}
                          </li>
                        )}
                      </div>
                    ))}
                  </ul>
                </div>
              }
            </div>
          ))}
        </ul>
      </div>
    </div>
  );
}
