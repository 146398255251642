import React from "react";
import TitleComponentHeader from "../../components/titleComponent/TitleComponent";
import GuarantorForm from "./guarantorForm";

export default function Guarantors() {
  return (
    <div className="personal-cover">
      <div className="personal-details-title">
        <TitleComponentHeader title={"Guarantor Information"} hasGoBack />
      </div>
      <div className="personal-form-cover">
        <GuarantorForm />
        {/* <PersonalRowCover userDetails={userDetails} /> */}
      </div>
    </div>
  );
}
