export const globalErrorMessageAction = (
  state = { status: false, message: "" },
  action
) => {
  switch (action.type) {
    case "ERROR_MESSAGE":
      return action.data;

    default:
      return state;
  }
};
