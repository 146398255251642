import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import AuthForm from "../../auth/authForm/authForm";
import MessageComponent from "../../components/messageComponent/messageComponent";
import PrimaryButton from "../../components/primaryButton/primaryButton";
import RoundSelectInput from "../../components/roundSelectInput/roundSelectInput";
import services from "../../services/services";
import { StateAndLocalGovernemt } from "../../utilities/statesAndLocal";
import GoogleAutocomplete from "../autoCompletePlace/googleAutocomplete";

export default function GuarantorForm() {
  const [TitleValue, setTitleValue] = useState("Mr");
  const dispatch = useDispatch();
  const [sexValue, setsexValue] = useState("Male");
  const globalErrorMessage = useSelector((state) => state.globalErrorMessage);
  const userDetails = useSelector((state) => state.userDetails);
  const guarantorEmploymentDetails = useSelector(
    (state) => state.guarantorEmploymentDetails
  );
  const [titleState, settitleState] = useState([
    { id: 1, name: "Mr" },
    { id: 2, name: "Mrs" },
  ]);
  const [cityDropDownValue, setcityDropDownValue] = useState([]);
  const [sexDropDownValue, setsexDropDownValue] = useState([
    { id: 1, name: "Male", value: "Male" },
    { id: 2, name: "Female", value: "Female" },
  ]);
  const [stateValue, setstateValue] = useState("");
  const [stateResidence, setstateResidence] = useState("");
  const [addressResidence, setaddressResidence] = useState("");
  const [address, setaddress] = useState("");
  const [cityValue, setcityValue] = useState("");
  const [firstName, setfirstName] = useState("");
  const [lastName, setlastName] = useState();
  const [dateOfBath, setdateOfBath] = useState("");
  const [email, setemail] = useState("");
  const [phone, setphone] = useState("");
  useEffect(() => {
    const getCity = StateAndLocalGovernemt.find(
      (list) => list.state.name === stateValue
    );
    setcityDropDownValue(getCity?.state?.locals);
  }, [stateValue]);
  const onChangeValue = (value) => {
    setaddressResidence(value.label);

    setstateResidence(value?.value?.structured_formatting?.secondary_text);
  };
  console.log(userDetails.token);
  const handleClickButton = () => {
    let guarantorData = {
      title: TitleValue,
      first_name: firstName,
      last_name: lastName,
      email: email,
      dob: dateOfBath,
      gender: sexValue,
      residential_address: addressResidence,
      city: cityValue,
      country: stateValue,
      state_residence: stateResidence,
      phone: phone,
      other_phone: "",
      work_status: guarantorEmploymentDetails.type,
      work_status_id: guarantorEmploymentDetails.id,
    };

    const getGuarantorResponse = new services().postService(
      "guarantor/borrower_access",
      guarantorData,
      userDetails.token
    );

    getGuarantorResponse.then((res) => {
      if (res.errorMessage) {
        let errorMessage = {
          message: res.errorMessage,
          status: false,
        };

        dispatch({ type: "ERROR_MESSAGE", data: errorMessage });
      } else {
        let successMessage = {
          message: "Guarantor details successful",
          status: true,
        };

        dispatch({
          type: "ERROR_MESSAGE",
          data: successMessage,
        });
      }
    });

    // console.log(guarantorData);
  };
  return (
    <div>
      <div className="row m-0 p-0">
        <div className="col-12 p-0 m-0">
          <div className="row m-0 p-0">
            <div
              className="col-lg-6 col-12 first-col"
              style={{ marginBottom: "29px" }}
            >
              <div className="row m-0">
                <div className="col-4 inner-first-col">
                  <RoundSelectInput
                    top={"14px"}
                    right={"21px"}
                    arrowRight={"50px"}
                    arrowTop={"16px"}
                    dropDownValue={titleState}
                    isValid
                    hasTitle
                    value={TitleValue}
                    handleSelectChange={(evt) =>
                      setTitleValue(evt.target.value)
                    }
                  />
                </div>
                <div className="col inner-second-col">
                  <AuthForm
                    isValid
                    type={"text"}
                    placeholder={"First Name"}
                    right={"19px"}
                    top={"14px"}
                    value={firstName}
                    isValid={firstName.length ? true : false}
                    handleChange={(evt) => setfirstName(evt.target.value)}
                  />
                </div>
              </div>
            </div>
            <div
              className="col-lg-6 col-12 second-col"
              style={{ marginBottom: "29px" }}
            >
              <AuthForm
                type={"text"}
                placeholder={"Last Name"}
                right={"19px"}
                top={"14px"}
                handleChange={(evt) => setlastName(evt.target.value)}
                isValid={lastName ? true : false}
              />{" "}
            </div>
            <div
              className="col-lg-6 col-6 mobile-first-col"
              style={{ marginBottom: "29px" }}
            >
              <RoundSelectInput
                top={"14px"}
                right={"21px"}
                arrowRight={"50px"}
                arrowTop={"16px"}
                dropDownValue={sexDropDownValue}
                isValid
                value={sexValue}
                handleSelectChange={(evt) => setsexValue(evt.target.value)}
                hasSex
              />
            </div>
            <div
              className="col-lg-6 col-6 mobile-second-col"
              style={{ marginBottom: "29px" }}
            >
              <AuthForm
                type={"date"}
                placeholder={"Date of Birth"}
                right={"19px"}
                top={"14px"}
                isValid={dateOfBath ? true : false}
                handleChange={(evt) => setdateOfBath(evt.target.value)}
              />{" "}
            </div>
            <div
              className="col-lg-6 col-12 mobile-first-col"
              style={{ marginBottom: "29px" }}
            >
              <GoogleAutocomplete
                onChangeValue={onChangeValue}
                isValid={addressResidence.length ? true : false}
                left={"91%"}
                top={"30%"}
              />
            </div>

            <div
              className="col-lg-6 col-12 mobile-second-col"
              style={{ marginBottom: "29px" }}
            >
              <AuthForm
                type={"text"}
                placeholder={"State of residence"}
                right={"19px"}
                top={"14px"}
                handleChange={(evt) => setstateResidence(evt.target.value)}
                value={stateResidence}
                disable={stateResidence ? true : false}
                isValid={stateResidence ? true : false}
                handleChange={(evt) => setdateOfBath(evt.target.value)}
              />{" "}
            </div>

            <div
              className="col-lg-6 col-12 mobile-first-col"
              style={{ marginBottom: "29px" }}
            >
              <RoundSelectInput
                top={"14px"}
                right={"21px"}
                arrowRight={"50px"}
                arrowTop={"16px"}
                dropDownValue={StateAndLocalGovernemt}
                isValid={stateValue ? true : false}
                value={stateValue}
                handleSelectChange={(evt) => setstateValue(evt.target.value)}
                hasStateValue
              />
            </div>
            <div
              className="col-lg-6 col-12 mobile-second-col"
              style={{ marginBottom: "29px" }}
            >
              <RoundSelectInput
                top={"14px"}
                right={"21px"}
                arrowRight={"50px"}
                arrowTop={"16px"}
                dropDownValue={cityDropDownValue}
                isValid={cityValue ? true : false}
                value={cityValue}
                handleSelectChange={(evt) => setcityValue(evt.target.value)}
                hasCity
              />
            </div>

            <div
              className="col-lg-6 col-6  mobile-first-col"
              style={{ marginBottom: "29px" }}
            >
              <AuthForm
                type={"email"}
                placeholder={"Email Address"}
                right={"19px"}
                top={"14px"}
                isValid={email ? true : false}
                handleChange={(evt) => setemail(evt.target.value)}
              />{" "}
            </div>

            <div className="col-lg-6 col-6  mobile-second-col">
              <AuthForm
                type={"text"}
                placeholder={"Phone number"}
                right={"19px"}
                top={"14px"}
                isValid={phone ? true : false}
                handleChange={(evt) => setphone(evt.target.value)}
              />{" "}
            </div>

            {globalErrorMessage.message ? (
              <div style={{ marginTop: "20px" }}>
                <MessageComponent messageDetails={globalErrorMessage} />
              </div>
            ) : (
              ""
            )}

            <div
              className="col-lg-12 col-12 desktop-button-div"
              style={{
                marginTop: "78px",
                textAlign: "center",
              }}
            >
              <span style={{ paddingRight: "50px" }}>
                {" "}
                <PrimaryButton
                  text={"Continue"}
                  buttonWith={"21.65%"}
                  handleClickButton={handleClickButton}
                />
              </span>
            </div>
            <div
              className="col-lg-12 col-12 mobile-button-div"
              style={{
                marginTop: "78px",
                textAlign: "center",
              }}
            >
              <span>
                {" "}
                <PrimaryButton
                  text={"Continue"}
                  buttonWith={"80%"}
                  handleClickButton={handleClickButton}
                />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
