import React from "react";

export default function RepaymentButton({
  customWith,
  title,
  customFontSize,
  handlePayRepayment,
}) {
  return (
    <button
      style={{
        borderRadius: "30px",
        border: "1px solid #1EAAE7",
        color: "#1EAAE7",
        background: "white",
        paddingTop: "8px",
        paddingBottom: "8px",
        fontSize: customFontSize,
        width: customWith,
      }}
      onClick={handlePayRepayment}
    >
      {title ? title : " Pay Now"}
    </button>
  );
}
