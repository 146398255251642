import React from "react";
import LandingImage from "../../images/landing-image.png";
import RecoverPassword from "../../images/recoverPassword-banner.png";
export default function EnjoyCoverComponent({ recoverPasswordImage }) {
  return (
    <div className="col-md col-12 enjoy-cover">
      {/* <div className="landing-image-content">
        <div className="title">Enjoy Now </div>
        <div className="sub-title">Never wait for the next payday.</div>
      </div> */}
      <img
        src={recoverPasswordImage ? RecoverPassword : LandingImage}
        alt="landing-image"
        className="landing-image col-12"
        // style={{ height: "100%" }}
      />
    </div>
  );
}
