import React from "react";
import LandingPageHeader from "../../components/landingHeader/landingPageHeader";
// import "./landingPage.css";
import LandingImage from "../../images/landing-image.png";
import MobileLogo from "../../images/mobile-logo.png";
import Pattern2 from "../../images/Pattern2.png";
import AuthForm from "../authForm/authForm";
import EnjoyCoverComponent from "../landingPage/enjoyCoverComponent";
import MobileBannerAuth from "../mobileBannerAuth";
import "./signup.css";
import SignUpFormComponent from "./signUpFormComponent";
export default function SignupPage() {
  return (
    <div>
      <div>
        <LandingPageHeader color={"white"} />
      </div>
      <div className="signup-container">
        <div className="container col-xl-8 col-lg-11 ">
          <div className="sign-up-desktop">
            <div className="row m-0 landing-row-cover">
              {/* <EnjoyCoverComponent /> */}

              <div className="col-md-12 col-12 register-form-cover">
                <div className="register-title"> Sign Up</div>
                <div className="register-sub-title">
                  Sign up for a Dizbuzz account
                </div>
                <div>
                  <SignUpFormComponent />
                </div>
              </div>
            </div>
          </div>
          <div className="sign-up-mobile">
            <div className="row m-0">
              {/* <MobileBannerAuth /> */}

              <div className="col-md col-12 register-form-cover">
                <div className="register-title">Sign Up</div>
                <div className="register-sub-title">
                  Sign up for a Dizbuzz account
                </div>
                <div>
                  <SignUpFormComponent />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
