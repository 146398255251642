import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import DashboardCard from "../../components/dashboardCard/dashboardCard";
import MobileDashboardCover from "../../components/mobileDashboardCard/mobileDashboardCover";
import services from "../../services/services";
import "./dashboard.css";
export default function Dashboard() {
  const userDetails = useSelector((state) => state.userDetails);
  const [hasPayment, sethasPayment] = useState(false);
  const [wallet, setwallet] = useState({});
  
 

  useEffect(() => {
    const getWalletBallance = new services().getService(
      "wallet/amount",
      userDetails.token
    );
    getWalletBallance
      .then((res) => {
        if (res?.errorMessage) {
          setwallet({});
        } else {
          setwallet(res?.data);

          if (res?.data?.Wallet?.amount === 0) {
            sethasPayment(false);
          } else {
            sethasPayment(true);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  return (
    <div className="dashboard-cover">
      <div className="dashboard-card-cover">
        <DashboardCard hasPayment={hasPayment} wallet={wallet} />
        <MobileDashboardCover hasPayment={hasPayment} wallet={wallet} />
      </div>
      <div className="dashboard-content-cover">
        {/* <div className="dashboard-content-title">Upcoming Repayment</div> */}
      </div>
    </div>
  );
}
