import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import ImageIcon from "@mui/icons-material/Image";
import WorkIcon from "@mui/icons-material/Work";
import BeachAccessIcon from "@mui/icons-material/BeachAccess";

export default function AccountDetailsList({
  primaryName,
  secondaryName,
  icon,
}) {
  return (
    <List sx={{ width: "100%", maxWidth: 360 }}>
      <ListItem>
        <ListItemAvatar>
          <img src={icon} alt={"icon"} />
        </ListItemAvatar>
        <ListItemText primary={primaryName} secondary={secondaryName} />
      </ListItem>
    </List>
  );
}
