import React, { useEffect, useState } from "react";
import "./select-loan-card-component.css";
import ScrollMenu from "react-horizontal-scroll-menu";
import { useSelector } from "react-redux";
export default function SelectLoanCardComponent({
  data,
  handleGetId,
  storeProductId,
}) {
  const loanProductSlected = useSelector((state) => state.loanProductSlected);
  const [activeId, setactiveId] = useState(1);

  const handleId = (id) => {
    setactiveId(id);
    handleGetId(id);
  };
  useEffect(() => {
    setactiveId(
      loanProductSlected?.getAllClickedObjectProduct?.id
        ? loanProductSlected?.getAllClickedObjectProduct?.id
        : 1
    );
  }, []);

  return data?.map((list) => (
    <div
      key={list.id}
      className={`col-xl-2 col-lg-3 col-md-4 col-6  p-0 desktop-select-card-list`}
    >
      <div
        className={
          storeProductId
            ? storeProductId === list.id
              ? "loan-card-container-active"
              : "loan-card-container-inactive"
            : list.id === activeId
            ? "loan-card-container-active"
            : "loan-card-container-inactive"
        }
        onClick={() => handleId(list.id)}
      >
        <div className="loan-card-title">{list.product_name}</div>
        <div className="loan-card-sub-title"> {list.description}</div>
      </div>
    </div>
  ));
}
