import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AuthForm from "../../auth/authForm/authForm";
import ImageAccountAvatars from "../../components/accountAvater/accountAvater";
import MessageComponent from "../../components/messageComponent/messageComponent";
import PrimaryButton from "../../components/primaryButton/primaryButton";
import TitleComponentHeader from "../../components/titleComponent/TitleComponent";
import services from "../../services/services";
import "./editUserDetailsProfile.css";
export default function EditUserProfile() {
  const userDetails = useSelector((state) => state.userDetails);
  const globalErrorMessage = useSelector((state) => state.globalErrorMessage);
  const [customerDetails, setcustomerDetails] = useState({
    first_name: "",
    last_name: "",
    user_name: "",
    phone: "",
  });
  const [loading, setloading] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    const getCustomerDetails = new services().getServiceWithId(
      "customers",

      userDetails?.customer_id,
      userDetails?.token
    );
    getCustomerDetails
      .then((res) => {
        if (res.errorMessage) {
        } else {
          setcustomerDetails({
            first_name: res?.data?.first_name,
            last_name: res?.data?.last_name,
            user_name: res?.data?.username,
            phone: res?.data?.phone,
          });

          // dispatch({ type: "GET_CUSTOMER_DETAILS", data: res?.data });
        }
      })
      .catch((error) => {
        //   dispatch({ type: "GET_CUSTOMER_DETAILS", data: {} });
      });
  }, []);

  const handleUpdateProfile = () => {
    setloading(true);
    let data = {
      first_name: customerDetails.first_name,
      last_name: customerDetails.last_name,
      username: customerDetails.user_name,
      phone: customerDetails.phone,
    };

    const postUpdateCustomer = new services().patchServices(
      "customers",
      data,
      userDetails?.token
    );
    postUpdateCustomer
      .then((res) => {
        setloading(false);
        if (res.errorMessage) {
          let errorMessage = {
            message: res.errorMessage,
            status: false,
          };

          dispatch({ type: "ERROR_MESSAGE", data: errorMessage });
        } else {
          setcustomerDetails({
            first_name: res?.data.first_name,
            last_name: res?.data?.last_name,
            username: res?.data?.username,
            phone: res?.data?.phone,
          });
          let successMessage = {
            message: "profile update successful.",
            status: true,
          };

          dispatch({ type: "ERROR_MESSAGE", data: successMessage });
        }
      })
      .catch((error) => {
        setloading(false);
        console.log(error);
      });
  };

  useEffect(() => {
    let errorMessage = {
      message: "",
      status: false,
    };
    return dispatch({ type: "ERROR_MESSAGE", data: errorMessage });
  }, []);
  return (
    <div className="profile-edit-cover">
      <div style={{ paddingLeft: "43px", marginBottom: "86px" }}>
        <TitleComponentHeader hasGoBack title={"Edit Profile information"} />
      </div>
      <div className="edit-profile-cover row m-0">
        <div className="col-md-5 col-12">
          <div style={{ paddingLeft: "153px" }} className="avater-cover-div">
            <ImageAccountAvatars hasEditIcon />
          </div>
          <div className="profile-form-cover" style={{ paddingTop: "30px" }}>
            <div className="col-12" style={{ marginBottom: "24px" }}>
              <AuthForm
                noCheck
                hasLabel
                placeholder={"Enter first name"}
                label={"First Name"}
                value={customerDetails.first_name}
                handleChange={(evt) =>
                  setcustomerDetails({
                    ...customerDetails,
                    first_name: evt.target.value,
                  })
                }
              />
            </div>
            <div className="col-12" style={{ marginBottom: "24px" }}>
              <AuthForm
                noCheck
                hasLabel
                placeholder={"Enter last name"}
                label={"Last Name"}
                value={customerDetails.last_name}
                handleChange={(evt) =>
                  setcustomerDetails({
                    ...customerDetails,
                    last_name: evt.target.value,
                  })
                }
              />
            </div>
            <div className="col-12" style={{ marginBottom: "24px" }}>
              <AuthForm
                noCheck
                hasLabel
                placeholder={"Enter username"}
                label={"Username"}
                value={customerDetails.user_name}
                handleChange={(evt) =>
                  setcustomerDetails({
                    ...customerDetails,
                    user_name: evt.target.value,
                  })
                }
              />
            </div>
            <div className="col-12" style={{ marginBottom: "24px" }}>
              <AuthForm
                noCheck
                hasLabel
                placeholder={"Enter phone number"}
                label={"Phone Number"}
                value={customerDetails.phone}
                handleChange={(evt) =>
                  setcustomerDetails({
                    ...customerDetails,
                    phone: evt.target.value,
                  })
                }
              />
            </div>
            {globalErrorMessage.message ? (
              <div style={{ marginTop: "20px", marginBottom: "20px" }}>
                <MessageComponent messageDetails={globalErrorMessage} />
              </div>
            ) : (
              ""
            )}
            <div style={{ paddingBottom: "50px" }}>
              <PrimaryButton
                text={"Save"}
                buttonWith={"261px"}
                loading={loading}
                handleClickButton={handleUpdateProfile}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
