import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import { useSelector } from "react-redux";

export default function SimpleBackdrop() {
  const [open, setOpen] = React.useState(false);
  const getShowSpinner = useSelector((state) => state.getShowSpinner);
  const handleClose = () => {
    setOpen(false);
  };
  React.useEffect(() => {
    // console.log(getShowSpinner);
    if (getShowSpinner) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [getShowSpinner]);
  return (
    <div>
      {/* <Button onClick={handleToggle}>Show backdrop</Button> */}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}
