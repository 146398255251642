import * as React from "react";
import Box from "@mui/material/Box";
import FilledInput from "@mui/material/FilledInput";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import "./normalInputForm.css";
import { TextField } from "@mui/material";
export default function ComposedTextField({
  customWidth,
  placeholder,
  labelTitle,
  bottomLabelTitle,
  value,
  disabled,
  selectLoanChecker,
  handleChange,
  type,
  hasSpecialStyle,
}) {
  const [name, setName] = React.useState("");

  // const style = {
  //   resize: {
  //     fontSize: 50,
  //   },
  // };

  return (
    <div>
      {labelTitle ? (
        <div
          className="form-title-div"
          style={{
            color: selectLoanChecker ? "red" : "#425a70",
            paddingTop: "12px",
          }}
        >
          {labelTitle}
        </div>
      ) : (
        ""
      )}

      <FormControl
        variant="filled"
        style={{
          width: customWidth,
          background: hasSpecialStyle ? "#E9E9E9" : "",
        }}
        className="mobile-form-normal-input"
      >
        <InputLabel
          htmlFor="component-filled"
          style={{
            paddingLeft: "52px",
            paddingTop: disabled ? "" : type == "date" ? "4px" : "17px",
          }}
        >
          {placeholder}
        </InputLabel>
        <FilledInput
          id="component-filled"
          value={value}
          type={type ? type : "text"}
          onChange={handleChange}
          disableUnderline
          disabled={disabled}
          inputProps={{
            style: {
              paddingTop: hasSpecialStyle ? "10px" : "",
              fontSize: hasSpecialStyle ? "20px" : "",
              fontWeight: hasSpecialStyle ? "900" : "",
            },
          }}
        />
      </FormControl>
      {bottomLabelTitle ? (
        <div className="form-bottom-title-div">{bottomLabelTitle}</div>
      ) : (
        ""
      )}
    </div>
  );
}
