import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { StateAndLocalGovernemt } from "../../utilities/statesAndLocal";

import { useDispatch, useSelector } from "react-redux";
import NormalInput from "../../components/normalInputForm/normalInputForm";
import services from "../../services/services";
// import MessageComponent from "../../../components/messageComponent/messageComponent";

import LocationGoogleAutocomplete from "../../components/employmentGooglePlaceHolder/employmentGooglelocation";
import MessageComponent from "../../components/messageComponent/messageComponent";
import PrimaryButton from "../../components/primaryButton/primaryButton";
import SelectInput from "../../components/selectInput/selectInput";
import { ListOfSchools } from "../../utilities/schoolsInNigerial";
// import LocationGoogleAutocomplete from "../../components/employmentGooglePlaceHolder/employmentGooglelocation";
export default function GuarantorEmploymentFormCover({
  getSelectedEmploymentDetails,
  getEmploymentStatusFromEndpoint,
  hasEmploymentStatusFromServer,
  sendCardDetailsToFormPage,
}) {
  const navigate = useNavigate();

  const userDetails = useSelector((state) => state.userDetails);

  const globalErrorMessage = useSelector((state) => state.globalErrorMessage);
  const getEmploymentDetails = useSelector(
    (state) => state.getEmploymentDetails
  );
  const getBvnRecordDetails = useSelector((state) => state.getBvnRecordDetails);
  const loanProductSlected = useSelector((state) => state.loanProductSlected);
  const setBorrowerDetails = useSelector((state) => state.setBorrowerDetails);
  const dispatch = useDispatch();

  const getCurrentLoggedInUser = useSelector(
    (state) => state.getCurrentLoggedInUser
  );

  console.log(sendCardDetailsToFormPage);
  const [employmentDetails, setemploymentDetails] = useState({
    employmentStatus: "",
    companyName: "",
    phone: "",
    location: "",
    role: "",
    ippsNo: "",
    school: "",
    level: "",
    retirement_date: "",
  });
  const [getListOfSchoolSelect, setgetListOfSchoolSelect] = useState([]);
  useEffect(() => {
    setemploymentDetails({
      companyName: "",
      phone: "",
      location: "",
      role: "",
      ippsNo: "",
      school: "",
      level: "",
      retirement_date: "",
      employmentStatus: getSelectedEmploymentDetails,
    });
  }, [getSelectedEmploymentDetails]);
  console.log(
    "---------------------------------------------",
    getSelectedEmploymentDetails
  );
  useEffect(() => {
    const getSchoolPerState = ListOfSchools.find(
      (list) => list.name === employmentDetails.location
    );
    setgetListOfSchoolSelect(getSchoolPerState?.school);
  }, [employmentDetails?.location]);

  useEffect(() => {
    const getUserDetails = new services().getService(
      "borrower",
      userDetails.token
    );
    getUserDetails
      .then((res) => {
        if (!res.errorMessage) {
          dispatch({ type: "GET_CURRENT_LOGGEDIN_USER", data: res?.data });
        }
      })
      .catch((error) => {
        let errorMessage = {
          message: "an error occur,please check your network.",
          status: false,
        };

        dispatch({ type: "ERROR_MESSAGE", data: errorMessage });
      });

    let errorMessage = {
      message: "",
      status: false,
    };

    dispatch({ type: "ERROR_MESSAGE", data: errorMessage });
  }, []);
  // console.log(getCurrentLoggedInUser?.WorkStatus[0]?.business_name);
  const routeToCreditCheckPage = () => {
    if (
      employmentDetails.employmentStatus === "Student" &&
      employmentDetails.location &&
      employmentDetails.school &&
      employmentDetails.level
    ) {
      // console.log(employmentDetails);
      let data = {
        type: employmentDetails.employmentStatus,
        school_name: employmentDetails.school,
        level: employmentDetails.level,
        country: employmentDetails.location,
      };
      let postWorkStatus = new services().postServiceWithToken(
        "borrower/working-status",
        data,
        userDetails.token
      );
      postWorkStatus
        .then((res) => {
          if (res.errorMessage) {
            let errorMessage = {
              message: res.errorMessage,
              status: false,
            };

            dispatch({ type: "ERROR_MESSAGE", data: errorMessage });
          } else {
            let successMessage = {
              message: "Student Details successfully created.",
              status: true,
            };

            dispatch({ type: "ERROR_MESSAGE", data: successMessage });
            setTimeout(() => {
              let clearMessage = {
                message: "",
                status: false,
              };

              dispatch({ type: "ERROR_MESSAGE", data: clearMessage });
              navigate("/loans/guarantor-details");
            }, 500);

            dispatch({
              type: "GET_GUARANTOR_EMPLOYMENT_DETAILS",
              data: res?.data,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (
      getSelectedEmploymentDetails === "Business Owner" &&
      employmentDetails.companyName &&
      employmentDetails.phone &&
      employmentDetails.location
    ) {
      let data = {
        type: employmentDetails.employmentStatus,
        business_name: employmentDetails.companyName,
        work_phone: employmentDetails.phone,
        country: employmentDetails.location,
      };
      let postWorkStatus = new services().postServiceWithToken(
        "borrower/working-status",
        data,
        userDetails.token
      );
      postWorkStatus
        .then((res) => {
          if (res.errorMessage) {
            let errorMessage = {
              message: res.errorMessage,
              status: false,
            };
            dispatch({ type: "ERROR_MESSAGE", data: errorMessage });
          } else {
            let successMessage = {
              message: "Employment details successfully created.",
              status: true,
            };
            dispatch({ type: "ERROR_MESSAGE", data: successMessage });
            setTimeout(() => {
              let clearMessage = {
                message: "",
                status: false,
              };

              dispatch({ type: "ERROR_MESSAGE", data: clearMessage });
              navigate("/loans/guarantor-details");
            }, 500);

            dispatch({
              type: "GET_GUARANTOR_EMPLOYMENT_DETAILS",
              data: res?.data,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (
      getSelectedEmploymentDetails === "Employee" &&
      employmentDetails.role &&
      employmentDetails.companyName &&
      employmentDetails.phone &&
      employmentDetails.location
    ) {
      console.log(employmentDetails);
      let data = {
        type: employmentDetails.employmentStatus,
        organization_name: employmentDetails.companyName,
        work_phone: employmentDetails.phone,
        country: employmentDetails.location,
        occupation: employmentDetails.role,
      };
      let postWorkStatus = new services().postServiceWithToken(
        "borrower/working-status",
        data,
        userDetails.token
      );
      postWorkStatus
        .then((res) => {
          if (res.errorMessage) {
            let errorMessage = {
              message: res.errorMessage,
              status: false,
            };

            dispatch({ type: "ERROR_MESSAGE", data: errorMessage });
          } else {
            let successMessage = {
              message: "Employment details successfully created.",
              status: true,
            };

            dispatch({ type: "ERROR_MESSAGE", data: successMessage });

            setTimeout(() => {
              let clearMessage = {
                message: "",
                status: false,
              };

              dispatch({ type: "ERROR_MESSAGE", data: clearMessage });
              navigate("/loans/guarantor-details");
            }, 500);

            dispatch({
              type: "GET_GUARANTOR_EMPLOYMENT_DETAILS",
              data: res?.data,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (
      getSelectedEmploymentDetails === "Government Employee" &&
      employmentDetails.role &&
      employmentDetails.location &&
      employmentDetails.level &&
      employmentDetails.phone &&
      employmentDetails.companyName
    ) {
      console.log(employmentDetails);
      let data = {
        type: employmentDetails.employmentStatus,
        occupation: employmentDetails.role,
        work_phone: employmentDetails.phone,
        country: employmentDetails.location,
        level: employmentDetails.level,
        organization_name: employmentDetails.companyName,
      };
      const postWorkStatus = new services().postServiceWithToken(
        "borrower/working-status",
        data,
        userDetails.token
      );
      postWorkStatus
        .then((res) => {
          if (res.errorMessage) {
            let errorMessage = {
              message: res.errorMessage,
              status: false,
            };

            dispatch({ type: "ERROR_MESSAGE", data: errorMessage });
          } else {
            let successMessage = {
              message: "Employment details successfully created.",
              status: true,
            };

            dispatch({ type: "ERROR_MESSAGE", data: successMessage });
            setTimeout(() => {
              let clearMessage = {
                message: "",
                status: false,
              };

              dispatch({ type: "ERROR_MESSAGE", data: clearMessage });
              navigate("/loans/guarantor-details");
            }, 500);

            dispatch({
              type: "GET_GUARANTOR_EMPLOYMENT_DETAILS",
              data: res?.data,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (
      getSelectedEmploymentDetails === "Pensioner" &&
      employmentDetails.companyName &&
      employmentDetails.role &&
      employmentDetails.retirement_date &&
      employmentDetails.location
    ) {
      console.log(employmentDetails);
      let data = {
        type: employmentDetails.employmentStatus,
        occupation: employmentDetails.role,
        organization_name: employmentDetails.companyName,
        country: employmentDetails.location,
        retirement_date: employmentDetails.retirement_date,
      };
      const postWorkStatus = new services().postServiceWithToken(
        "borrower/working-status",
        data,
        userDetails.token
      );
      postWorkStatus
        .then((res) => {
          if (res.errorMessage) {
            let errorMessage = {
              message: res.errorMessage,
              status: false,
            };

            dispatch({ type: "ERROR_MESSAGE", data: errorMessage });
          } else {
            let successMessage = {
              message: "Employment details successfully created.",
              status: true,
            };

            dispatch({ type: "ERROR_MESSAGE", data: successMessage });
            setTimeout(() => {
              let clearMessage = {
                message: "",
                status: false,
              };

              dispatch({ type: "ERROR_MESSAGE", data: clearMessage });
              navigate("/loans/guarantor-details");
            }, 500);

            dispatch({
              type: "GET_GUARANTOR_EMPLOYMENT_DETAILS",
              data: res?.data,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      let errorMessage = {
        message: "Select or fill out all required fields",
        status: false,
      };

      dispatch({ type: "ERROR_MESSAGE", data: errorMessage });
    }
  };

  const onChangeValue = (value) => {
    setemploymentDetails({
      ...employmentDetails,
      location: value?.label,
    });
  };

  // useEffect(() => {
  //   setemploymentDetails({
  //     ...employmentDetails,
  //     location: "",
  //   });
  // }, [employmentDetails.employmentStatus]);
  return (
    <div>
      {employmentDetails.employmentStatus === "Business Owner" ? (
        <div>
          <div style={{ marginBottom: "29px" }}>
            <NormalInput
              labelTitle={"What is your company name"}
              placeholder={"Company name"}
              customWidth={"64%"}
              value={employmentDetails.companyName}
              disabled={hasEmploymentStatusFromServer}
              handleChange={(e) =>
                setemploymentDetails({
                  ...employmentDetails,
                  companyName: e.target.value,
                })
              }
            />
          </div>
          <div style={{ marginBottom: "29px" }}>
            <NormalInput
              labelTitle={"Contact Number"}
              placeholder={"Phone"}
              customWidth={"64%"}
              disabled={hasEmploymentStatusFromServer}
              value={employmentDetails.phone}
              handleChange={(e) =>
                setemploymentDetails({
                  ...employmentDetails,
                  phone: e.target.value,
                })
              }
            />
          </div>
          <div
            style={{ marginBottom: "29px" }}
            className="col-md-8 col-12 employment-location-cover"
          >
            {/* <NormalInput
              disabled={hasEmploymentStatusFromServer}
              labelTitle={"Location"}
              placeholder={"Location"}
              value={employmentDetails.location}
              handleChange={(e) =>
                setemploymentDetails({
                  ...employmentDetails,
                  location: e.target.value,
                })
              }
            /> */}

            <LocationGoogleAutocomplete
              labelTitle={"Location"}
              onChangeValue={onChangeValue}
              value={employmentDetails.location}
            />
          </div>
        </div>
      ) : employmentDetails.employmentStatus === "Employee" ? (
        <div>
          <div style={{ marginBottom: "29px" }}>
            <NormalInput
              disabled={hasEmploymentStatusFromServer}
              labelTitle={"What is your company name"}
              placeholder={"Company name"}
              customWidth={"64%"}
              value={employmentDetails.companyName}
              handleChange={(e) =>
                setemploymentDetails({
                  ...employmentDetails,
                  companyName: e.target.value,
                })
              }
            />
          </div>
          <div style={{ marginBottom: "29px" }}>
            <NormalInput
              labelTitle={"What is your Occupation"}
              placeholder={"Occupation"}
              customWidth={"64%"}
              value={employmentDetails.role}
              handleChange={(e) =>
                setemploymentDetails({
                  ...employmentDetails,
                  role: e.target.value,
                })
              }
              disabled={hasEmploymentStatusFromServer}
            />
          </div>
          <div style={{ marginBottom: "29px" }}>
            <NormalInput
              labelTitle={"Contact Number"}
              placeholder={"Employee's Phone No"}
              customWidth={"64%"}
              value={employmentDetails.phone}
              handleChange={(e) =>
                setemploymentDetails({
                  ...employmentDetails,
                  phone: e.target.value,
                })
              }
              disabled={hasEmploymentStatusFromServer}
            />
          </div>
          <div
            style={{ marginBottom: "29px" }}
            className="col-md-8 col-12 employment-location-cover"
          >
            {/* <NormalInput
              disabled={hasEmploymentStatusFromServer}
              labelTitle={"Location"}
              placeholder={"Location"}
              value={employmentDetails.location}
              handleChange={(e) =>
                setemploymentDetails({
                  ...employmentDetails,
                  location: e.target.value,
                })
              }
            /> */}

            <LocationGoogleAutocomplete
              labelTitle={"Location"}
              isEmployee
              onChangeValue={onChangeValue}
            />
          </div>
        </div>
      ) : employmentDetails.employmentStatus === "Government Employee" ? (
        <div>
          <div style={{ marginBottom: "29px" }}>
            <NormalInput
              labelTitle={"Level"}
              placeholder={"Level"}
              customWidth={"64%"}
              value={employmentDetails.level}
              handleChange={(e) =>
                setemploymentDetails({
                  ...employmentDetails,
                  level: e.target.value,
                })
              }
              disabled={hasEmploymentStatusFromServer}
            />
          </div>
          <div style={{ marginBottom: "29px" }}>
            <NormalInput
              disabled={hasEmploymentStatusFromServer}
              labelTitle={"What is your company name"}
              placeholder={"Company name"}
              customWidth={"64%"}
              value={employmentDetails.companyName}
              handleChange={(e) =>
                setemploymentDetails({
                  ...employmentDetails,
                  companyName: e.target.value,
                })
              }
            />
          </div>
          <div style={{ marginBottom: "29px" }}>
            <NormalInput
              labelTitle={"What is your Occupation"}
              placeholder={"Occupation"}
              customWidth={"64%"}
              value={employmentDetails.role}
              handleChange={(e) =>
                setemploymentDetails({
                  ...employmentDetails,
                  role: e.target.value,
                })
              }
              disabled={hasEmploymentStatusFromServer}
            />
          </div>
          <div style={{ marginBottom: "29px" }}>
            <NormalInput
              labelTitle={"Contact Number"}
              placeholder={"Phone"}
              customWidth={"64%"}
              value={employmentDetails.phone}
              handleChange={(e) =>
                setemploymentDetails({
                  ...employmentDetails,
                  phone: e.target.value,
                })
              }
              disabled={hasEmploymentStatusFromServer}
            />
          </div>
          <div
            style={{ marginBottom: "29px" }}
            className="col-md-8 col-12 employment-location-cover"
          >
            {/* <NormalInput
              disabled={hasEmploymentStatusFromServer}
              labelTitle={"Location"}
              placeholder={"Location"}
              value={employmentDetails.location}
              handleChange={(e) =>
                setemploymentDetails({
                  ...employmentDetails,
                  location: e.target.value,
                })
              }
            /> */}

            <LocationGoogleAutocomplete
              labelTitle={"Location"}
              onChangeValue={onChangeValue}
            />
          </div>
        </div>
      ) : employmentDetails.employmentStatus === "Student" ? (
        <div>
          <div style={{ marginBottom: "29px" }}>
            <NormalInput
              labelTitle={"Level"}
              placeholder={"Level"}
              customWidth={"64%"}
              value={employmentDetails.level}
              handleChange={(e) =>
                setemploymentDetails({
                  ...employmentDetails,
                  level: e.target.value,
                })
              }
              disabled={hasEmploymentStatusFromServer}
            />
          </div>
          <div style={{ marginBottom: "29px" }}>
            <SelectInput
              labelTitle={"Location"}
              placeholder={"Location"}
              dropdownValue={StateAndLocalGovernemt}
              customWidth={"64%"}
              optionValue={employmentDetails.location}
              hasLocation
              handleChange={(e) =>
                setemploymentDetails({
                  ...employmentDetails,
                  location: e.target.value,
                })
              }
              disabled={hasEmploymentStatusFromServer}
            />
          </div>
          {employmentDetails.location ? (
            <div style={{ marginBottom: "29px" }}>
              <SelectInput
                labelTitle={"School Name"}
                placeholder={"School Name"}
                dropdownValue={getListOfSchoolSelect}
                customWidth={"64%"}
                hasSchools
                optionValue={employmentDetails.school}
                handleChange={(e) =>
                  setemploymentDetails({
                    ...employmentDetails,
                    school: e.target.value,
                  })
                }
                disabled={hasEmploymentStatusFromServer}
              />
            </div>
          ) : (
            ""
          )}
        </div>
      ) : employmentDetails.employmentStatus === "Pensioner" ? (
        <div>
          <NormalInput
            labelTitle={"What is your organization name"}
            placeholder={"Organization Name"}
            customWidth={"64%"}
            value={employmentDetails.companyName}
            handleChange={(e) =>
              setemploymentDetails({
                ...employmentDetails,
                companyName: e.target.value,
              })
            }
            disabled={hasEmploymentStatusFromServer}
          />
          <div style={{ marginBottom: "29px" }}>
            <NormalInput
              labelTitle={"What is your Occupation"}
              placeholder={"Occupation"}
              customWidth={"64%"}
              value={employmentDetails.role}
              handleChange={(e) =>
                setemploymentDetails({
                  ...employmentDetails,
                  role: e.target.value,
                })
              }
              disabled={hasEmploymentStatusFromServer}
            />
          </div>
          <div
            style={{ marginBottom: "29px" }}
            className="col-md-8 col-12 employment-location-cover"
          >
            {/* <NormalInput
              disabled={hasEmploymentStatusFromServer}
              labelTitle={"Location"}
              placeholder={"Location"}
              value={employmentDetails.location}
              handleChange={(e) =>
                setemploymentDetails({
                  ...employmentDetails,
                  location: e.target.value,
                })
              }
            /> */}

            <LocationGoogleAutocomplete
              labelTitle={"Location"}
              onChangeValue={onChangeValue}
            />
          </div>
          <div style={{ marginBottom: "29px" }}>
            <NormalInput
              type={"date"}
              labelTitle={"Retirement Date"}
              customWidth={"64%"}
              value={employmentDetails.retirement_date}
              handleChange={(e) =>
                setemploymentDetails({
                  ...employmentDetails,
                  retirement_date: e.target.value,
                })
              }
              disabled={hasEmploymentStatusFromServer}
            />
          </div>
        </div>
      ) : (
        ""
      )}
      {globalErrorMessage.message ? (
        <div style={{ marginTop: "20px" }}>
          <MessageComponent messageDetails={globalErrorMessage} />
        </div>
      ) : (
        ""
      )}
      <div
        style={{ marginTop: "58px", paddingBottom: "59px" }}
        className="mobile-first-cover-button"
      >
        <PrimaryButton
          text={"Continue"}
          buttonWith={"21.65%"}
          handleClickButton={routeToCreditCheckPage}
        />
      </div>
    </div>
  );
}
