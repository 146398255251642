import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TitleComponentHeader from "../../components/titleComponent/TitleComponent";
import services from "../../services/services";
import axios from "axios";
import "./uploadBankDetails.css";
import MessageComponent from "../../components/messageComponent/messageComponent";
import { useNavigate } from "react-router";
export default function UploadBankDetails() {
  const navigate = useNavigate();
  const userDetails = useSelector((state) => state.userDetails);
  const globalErrorMessage = useSelector((state) => state.globalErrorMessage);
  const getBvnRecordDetails = useSelector((state) => state.getBvnRecordDetails);
  const loanProductSlected = useSelector((state) => state.loanProductSlected);
  const loanPaymentSuccess = useSelector((state) => state.loanPaymentSuccess);
  const setEmploymentDetails = useSelector(
    (state) => state.setEmploymentDetails
  );
  const getLoanId = useSelector((state) => state.getLoanId);
  const [imageDetails, setimageDetails] = useState({
    image: null,
    loan_id: "",
    files: null,
  });
  const dispatch = useDispatch();

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      setimageDetails({
        ...imageDetails,
        image: e.target.files[0],
        files: e.target.files,
      });
    }
  };
  useEffect(() => {
    setimageDetails({
      ...imageDetails,
      loan_id: getLoanId?.loanId,
    });

    let successMessage = {
      message: "",
      status: false,
    };
    dispatch({ type: "ERROR_MESSAGE", data: successMessage });
  }, []);
  const handleSubmit = (e) => {
    if (imageDetails.image === null || imageDetails.files === null) {
      let errorMessage = {
        message: "Choose a file to upload",
        status: false,
      };
      dispatch({ type: "ERROR_MESSAGE", data: errorMessage });
    } else {
      let form_data = new FormData();
      form_data.append("loan_id", imageDetails.loan_id);

      form_data.append(
        "files",
        JSON.stringify('{"name":"bank statement","fileName":"bank statement"}')
      );
      form_data.append(
        "files",
        JSON.stringify('{"name":"bank statement","fileName":"bank statement"}')
      );
      form_data.append("image", imageDetails.image);

      form_data.append("image", imageDetails.image);
      const requestOptions = {
        headers: {
          Authorization: `${userDetails.token}`,
        },
      };

      axios
        .post(
          "http://api.odiopay.com/api/v1/bankstatement/image",
          form_data,
          requestOptions
        )
        .then((res) => {
          console.log(res);

          if (res?.data?.errorMessage) {
            let errorMessage = {
              message: res?.data?.errorMessage,
              status: false,
            };
            dispatch({ type: "ERROR_MESSAGE", data: errorMessage });
          } else {
            let successMessage = {
              message: "file uploaded successful",
              status: true,
            };
            dispatch({ type: "ERROR_MESSAGE", data: successMessage });
          }
          // console.log(data.data);
        })
        .catch((err) => {});

      // fetch("https://odio.com.ng/api/v1/bankstatement/image", requestOptions, {
      //   body: form_data,
      // })
      //   .then((res) => {
      //     console.log(res);
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });
    }
  };

  return (
    <div className="bank-statement-upload-cover">
      <TitleComponentHeader
        title={"Upload bank statement"}
        subTitle={"Kindly upload your 6-months bank account statement."}
      />
      <div
        style={{
          marginTop: "37px",
          paddingTop: "37px",
          boxShadow: "1.5px 2.6px 10px rgba(119, 119, 119, 0.1)",
          paddingLeft: "12px",

          paddingRight: "12px",
        }}
      >
        <div
          style={{
            paddingTop: "20px",
            paddingLeft: "25px",
            paddingRight: "25px",
            paddingBottom: "52px",
          }}
        >
          <div style={{ fontSize: "16px", color: "#ABAFB3" }}>
            You can upload .JPG. .PNG or PDF files
          </div>
          <div className=" col-md-5 col-12" style={{ marginTop: "10px" }}>
            <input
              type="file"
              className="form-control"
              onChange={handleImageChange}
            />
          </div>
          {globalErrorMessage.message ? (
            <div style={{ marginTop: "20px" }}>
              <MessageComponent messageDetails={globalErrorMessage} />
            </div>
          ) : (
            ""
          )}
          <div className=" col-md-5 col-12" style={{ marginTop: "20px" }}>
            <div className="row m-0 p-0">
              <div className="col-6 m-0 p-0">
                <button
                  style={{
                    background: "#DDDFE1",
                    border: "1px solid #DDDFE1",
                    borderRadius: "6px",
                    color: "#464A53",
                    fontSize: "18px",
                    width: "90%",
                    height: "42px",
                  }}
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </button>
              </div>
              <div className="col-6 m-0 p-0" style={{ textAlign: "right" }}>
                <button
                  style={{
                    background: "#2BC155",
                    border: "1px solid#2BC155",
                    borderRadius: "6px",
                    color: "white",
                    fontSize: "18px",
                    width: "90%",
                    height: "42px",
                  }}
                  onClick={handleSubmit}
                >
                  Attach File
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
