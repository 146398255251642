import React from "react";
import TitleComponentHeader from "../../components/titleComponent/TitleComponent";
import { formatToCurrency } from "../../utilities/utilities";
import RepaymentButton from "./repaymentButton";
import UpcomingRepaymentMobile from "./upcomingRepaymentMobile";
import { useDispatch, useSelector } from "react-redux";
import services from "../../services/services";
export default function MobileViewForRepayment({
  dueRepayment,
  getIndividualLoan,
}) {
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.userDetails);
  const makeRepayment = (amount) => {
    dispatch({ type: "SHOW_SPINNER", data: true });
    // console.log(loanId);
    let data = {
      loan_id: getIndividualLoan.id,
      amount: amount,
    };

    const postRepayment = new services().postServiceWithToken(
      "repayments/make-repayment",
      data,
      userDetails.token
    );
    postRepayment
      .then((res) => {
        if (res.errorMessage) {
          let errorMessage = {
            message: res.errorMessage,
            status: false,
          };

          dispatch({ type: "ERROR_MESSAGE", data: errorMessage });
        } else {
          let errorMessage = {
            message: "Repayment successful",
            status: true,
          };
          window.location.href = res?.data?.authorization_url;

          dispatch({ type: "ERROR_MESSAGE", data: errorMessage });
        }
        dispatch({ type: "SHOW_SPINNER", data: false });

        dispatch({ type: "SHOW_TOAST", data: true });
      })
      .catch((error) => {
        dispatch({ type: "SHOW_SPINNER", data: false });
        console.log(error);
      });
  };
  return (
    <div>
      {/* <div className="mobile-title-cover">
        <TitleComponentHeader title={""} />
      </div> */}
      {Object.entries(dueRepayment).length ? (
        <div className="repayment-for-mobile-first-details">
          {Object.entries(dueRepayment?.currentPeriodStatus)?.length === 0 ? (
            <div style={{ color: "#858585", fontSize: "12px" }}>
              You have no due repayment this time
            </div>
          ) : (
            <div className="row mobile-for-repayment-first-card-row">
              <div className="col-4 mobile-for-repayment-first-card-1">
                {getIndividualLoan?.type ? getIndividualLoan?.type : ""}
                <br />
                <div className="mobile-for-repayment-first-card-span">
                  ₦{" "}
                  {dueRepayment?.currentPeriodStatus?.current_liability
                    ? formatToCurrency(
                        dueRepayment?.currentPeriodStatus?.current_liability
                      )
                    : 0}
                </div>
              </div>
              <div className="col-3">
                <span className="mobile-for-repayment-first-card-status">
                  Due
                </span>
              </div>
              <div className="col-5">
                <RepaymentButton
                  customWith={"114px"}
                  title={"Make Payment"}
                  customFontSize={"12px"}
                  handlePayRepayment={() =>
                    makeRepayment(
                      dueRepayment?.currentPeriodStatus?.current_liability
                    )
                  }
                />
              </div>
            </div>
          )}
        </div>
      ) : (
        ""
      )}

      <div className="mobile-second-repayment-title">Upcoming Repayment</div>
      <div className="second-mobile-repayment-content-cover">
        <UpcomingRepaymentMobile getIndividualLoan={getIndividualLoan} />
      </div>
    </div>
  );
}
