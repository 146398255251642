import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SelectInput from "../../components/selectInput/selectInput";
import TitleComponentHeader from "../../components/titleComponent/TitleComponent";
import { formatToCurrency, listOfBank } from "../../utilities/utilities";
import NormalInputForm from "../../components/normalInputForm/normalInputForm";
import services from "../../services/services";
import { useDispatch } from "react-redux";
import PrimaryButton from "../../components/primaryButton/primaryButton";
import { useNavigate } from "react-router";
import MessageComponent from "../../components/messageComponent/messageComponent";
import "./createLoan.css";
export default function CreateLoan() {
  const loanProductSlected = useSelector((state) => state.loanProductSlected);
  const globalErrorMessage = useSelector((state) => state.globalErrorMessage);
  const userDetails = useSelector((state) => state.userDetails);
  const getBvnRecordDetails = useSelector((state) => state.getBvnRecordDetails);
  const [repaymentAmount, setRepaymentAmount] = useState(0);
  const setEmploymentDetails = useSelector(
    (state) => state.setEmploymentDetails
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [bankDetails, setbankDetails] = useState({
    bankName: "",
    accountName: "",
    accountNumber: "",
    bankFullName: "",
  });
  //   useEffect(() => {
  //     console.log(bankDetails);
  //   }, [bankDetails]);

  console.log(loanProductSlected?.minimum_principal)
  const loanAmount = Number(loanProductSlected?.minimum_principal) ||  Number(loanProductSlected?.minimum_principal.replace(/,/g,""));
  useEffect(() => {
    let data = {
      principal: parseInt(
        loanProductSlected.minimum_principal.toString().replace(/,/g, "")
      ),
      interest: loanProductSlected.getAllClickedObjectProduct.interest,
      loan_duration: loanProductSlected?.loan_duration,
      method: loanProductSlected.getAllClickedObjectProduct.interest_method,
      interest_period:
        loanProductSlected.getAllClickedObjectProduct.interest_period,
      repay_cycle: loanProductSlected.repaymentCycle,
      loan_period: loanProductSlected.loan_duration_period,
    };
    if (data) {
      const getLoanCalculatorResponse = new services().postServiceWithToken(
        "repayments/calculator",
        data,
        userDetails.token
      );
      getLoanCalculatorResponse
        .then((res) => {
          if (res.errorMessage) {
            setRepaymentAmount(0);
          } else {
            setRepaymentAmount(res?.data[0].total_payment);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  useEffect(() => {
    if (bankDetails?.accountNumber?.length > 9) {
      const getBanks = new services().getBanksService(
        bankDetails.accountNumber,
        bankDetails.bankName,
        userDetails.token
      );
      getBanks
        .then((res) => {
          if (res.status) {
            setbankDetails({
              ...bankDetails,
              accountName: res.data.account_name,
            });
          } else {
            let errorMessage = {
              status: false,
              message: res.message,
            };

            dispatch({ type: "ERROR_MESSAGE", data: errorMessage });
          }

          // console.log(res);
        })
        .catch((error) => {
          let errorMessage = {
            message: "an error occur, please check your network",
            status: false,
          };

          dispatch({ type: "ERROR_MESSAGE", data: errorMessage });
        });
    }
    // setbvnIdentificationDetails({
    //   ...bvnIdentificationDetails,
    //   bvn_status: false,
    //   bvnNumber: "",
    // });
  }, [bankDetails.accountNumber]);
  useEffect(() => {
    setbankDetails({
      bankFullName: getBvnRecordDetails?.bankFullName,
      bankName: getBvnRecordDetails?.bankName,
      accountName: getBvnRecordDetails?.accountName,
      accountNumber: getBvnRecordDetails?.accountNumber,
    });
  }, []);
  const handleRouteToPayment = () => {
    if (bankDetails.accountName === "" || bankDetails.accountNumber === "") {
      let errorMessage = {
        message: "Please fill out all empty fields",
        status: false,
      };

      dispatch({ type: "ERROR_MESSAGE", data: errorMessage });
    } else {
      dispatch({
        type: "GET_RECORD_DETAILS",
        data: {
          ...getBvnRecordDetails,
          accountName: bankDetails.accountName,
          accountNumber: bankDetails.accountNumber,
          bankFullName: bankDetails.bankFullName,
          bankName: bankDetails.bankName,
        },
      });
      let successMessage = {
        message: "Your application was successful.",
        status: true,
      };

      dispatch({ type: "ERROR_MESSAGE", data: successMessage });
      if (loanProductSlected?.loanCategory === "CORPORATE") {
        let loanData = {
          work_status_id: setEmploymentDetails?.id,
          loan_product_id: loanProductSlected?.getAllClickedObjectProduct?.id,
          initial_principal:loanAmount,
          repay_cycle: loanProductSlected?.repaymentCycle,
          account_number: getBvnRecordDetails?.accountNumber,
          bank_code: getBvnRecordDetails?.bankName,
          bank_name: getBvnRecordDetails?.bankFullName.name,
          category: loanProductSlected?.loanCategory,
          loan_duration: loanProductSlected?.loan_duration,
          corporate_loan_details: {
            phone_number: setEmploymentDetails?.work_phone,
            company_name: setEmploymentDetails?.business_name,
            address: setEmploymentDetails?.country,
            cac_number: setEmploymentDetails?.cac_number,
          },
        };
        const postApplyLoan = new services().postServiceWithToken(
          "loan",
          loanData,
          userDetails.token
        );
        postApplyLoan
          .then((res) => {
            if (res.errorMessage) {
              let errorMessage = {
                message: res.errorMessage,
                status: false,
              };

              dispatch({ type: "ERROR_MESSAGE", data: errorMessage });
            } else {
              let successMessage = {
                message: "Loan Application successful.",
                status: true,
              };
              dispatch({
                type: "GET_LOAN_ID",
                data: { loanId: res?.data?.id },
              });
              dispatch({ type: "ERROR_MESSAGE", data: successMessage });
              setTimeout(() => {
                let clearMessage = {
                  message: "",
                  status: false,
                };
                dispatch({ type: "LOAN_PAYMENT_SUCCESS_CHECKER", data: false });
                dispatch({ type: "LOAN_PRODUCT_SELECTED", data: {} });
                dispatch({ type: "GET_RECORD_DETAILS", data: {} });
                dispatch({ type: "SET_EMPLOYMENT_DETAILS", data: {} });

                dispatch({ type: "ERROR_MESSAGE", data: clearMessage });
                navigate("/existing-loan");
              }, 700);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        let loanData = {
          work_status_id: setEmploymentDetails?.id,
          loan_product_id: loanProductSlected?.getAllClickedObjectProduct?.id,
          initial_principal:loanAmount,
          repay_cycle: loanProductSlected.repaymentCycle,
          account_number: getBvnRecordDetails.accountNumber,
          bank_code: getBvnRecordDetails.bankName,
          bank_name: getBvnRecordDetails.bankFullName.name,
          category: loanProductSlected?.loanCategory,
          loan_duration: loanProductSlected?.loan_duration,
        };
        const postApplyLoan = new services().postServiceWithToken(
          "loan",
          loanData,
          userDetails.token
        );
        postApplyLoan
          .then((res) => {
            if (res.errorMessage) {
              let errorMessage = {
                message: res.errorMessage,
                status: false,
              };

              dispatch({ type: "ERROR_MESSAGE", data: errorMessage });
            } else {
              let successMessage = {
                message: "Loan Application successful.",
                status: true,
              };
              dispatch({
                type: "GET_LOAN_ID",
                data: { loanId: res?.data?.id },
              });
              dispatch({ type: "ERROR_MESSAGE", data: successMessage });
              setTimeout(() => {
                let clearMessage = {
                  message: "",
                  status: false,
                };
                dispatch({ type: "LOAN_PAYMENT_SUCCESS_CHECKER", data: false });
                // dispatch({ type: "LOAN_PRODUCT_SELECTED", data: {} });
                dispatch({ type: "GET_RECORD_DETAILS", data: {} });
                dispatch({ type: "SET_EMPLOYMENT_DETAILS", data: {} });

                dispatch({ type: "ERROR_MESSAGE", data: clearMessage });
                navigate("/existing-loan");
              }, 700);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };
  console.log({loanProductSlected},loanProductSlected?.minimum_principal)
  return (
    <div className="create-loan-cover">
      <TitleComponentHeader
        title={"Confirm loan details"}
        subTitle={"Below are your loan application details"}
      />
      <div style={{ marginTop: "26px" }}>
        <div
          className="col-sm-5 col-md-10"
          style={{
            border: "1px solid #C9CED6",
            borderRadius: "12px",
            paddingTop: "21px",
            paddingBottom: "21px",
            paddingLeft: "21px",
            textAlign: "left",
          }}
        >
          <div style={{ fontSize: "18px" }}>Loan Amount</div>
          <div
            style={{ fontSize: "40px", fontWeight: "800", marginTop: "8px" }}
          >
            {formatToCurrency(loanAmount)}
          </div>
          <div style={{ fontSize: "18px" }}>Expected repayment</div>
          <div
            style={{
              fontSize: "30px",
              fontWeight: "500",
              marginTop: "15px",
              marginBottom: "9px",
            }}
          >
            {repaymentAmount ? formatToCurrency(repaymentAmount) : 0}/
            {loanProductSlected?.loan_duration_period.charAt(0) + loanProductSlected?.loan_duration_period?.slice(1).toLowerCase() }
          </div>
          <div style={{ fontSize: "18px" }}>Loan Duration</div>
          <div
            style={{ fontSize: "30px", fontWeight: "500", marginTop: "21px" }}
          >
            {loanProductSlected?.loan_duration
              ? parseInt(formatToCurrency(loanProductSlected?.loan_duration))
              : 0}
            {" "} {loanProductSlected?.loan_duration_period.charAt(0) + loanProductSlected?.loan_duration_period?.slice(1).toLowerCase() }
          </div>
          {/* <div style={{ fontSize: "10px", marginTop: "15px" }}>
            <span style={{ color: "red" }}>*</span>Loan is financed by{" "}
            <span style={{ fontWeight: "900" }}>
              Consumer Micro-finance bank
            </span>
            <span style={{ color: "red" }}> *</span>
          </div> */}
        </div>
      </div>

      {/* bankDetails */}

      <div className="col-md-5 col-12">
        <div
          style={{
            marginTop: "48px",
            fontSize: "14px",
            fontWeight: "800",
            marginBottom: "12px",
            textAlign: "left",
          }}
        >
          Please confirm your bank details below
        </div>
        <div>
          <div className="bvn-inner-form-cover">
            <SelectInput
              placeholder={"Bank"}
              customWidth={"100%"}
              dropdownValue={listOfBank}
              labelTitle={"Select your bank from the list"}
              hasBank
              handleChange={(evt) =>
                setbankDetails({
                  accountNumber: "",
                  accountName: "",
                  bankName: evt.target.value,
                  bankFullName: listOfBank.find((list) =>
                    list.code === evt.target.value ? list.name : ""
                  ),
                })
              }
              optionValue={bankDetails.bankName}
            />
          </div>
          <div className="bvn-inner-form-cover">
            <NormalInputForm
              customWidth={"100%"}
              placeholder={"Account No"}
              labelTitle={"Enter your bank account number"}
              handleChange={(evt) =>
                setbankDetails({
                  ...bankDetails,
                  accountNumber: evt.target.value,
                })
              }
              value={bankDetails.accountNumber}
            />
          </div>
          <div className="bvn-inner-form-cover">
            <NormalInputForm
              customWidth={"100%"}
              placeholder={"Account Name"}
              labelTitle={"Confirm Account Name"}
              handleChange={(evt) =>
                setbankDetails({
                  ...bankDetails,
                  accountName: evt.target.value,
                })
              }
              disabled={true}
              value={bankDetails.accountName}
            />
          </div>
          {globalErrorMessage.message ? (
            <div style={{ marginTop: "20px" }}>
              <MessageComponent messageDetails={globalErrorMessage} />
            </div>
          ) : (
            ""
          )}
          <div style={{ marginTop: "20px" }}>
            <PrimaryButton
              buttonWith={"60%"}
              text={"Apply now"}
              handleClickButton={handleRouteToPayment}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
