import React from "react";
import GoBack from "../goBack/goBack";
import "./titleComponent.css";
export default function TitleComponentHeader({
  title,
  subTitle,
  customFontSize,
  customFontWeight,
  hasGoBack,
}) {
  return (
    <div style={{ paddingTop: "48px" }} className="mobile-title-component">
      <div
        style={{
          fontSize: customFontSize ? customFontSize : "20px",
          fontWeight: customFontWeight ? customFontWeight : "bold",
        }}
      >
        <span style={{ display: "flex", marginRight: "22px" }}>
          {hasGoBack ? <GoBack /> : ""}

          {title}
        </span>{" "}
      </div>
      {subTitle ? (
        <div
          style={{
            marginTop: "7px",
            fontSize: "12px",
            color: "#020614",
            opacity: 0.5,
          }}
        >
          {subTitle}
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
