export const ListOfSchools = [
  {
    name: "Abia State",
    school: [
      {
        name: "Michael Okpara University of Agriculture at Umudike (Federally owned)",
      },
      { name: "Abia State University in Uturu (State owned)" },
      { name: "College of Health Sciences Aba" },
      { name: "Abia State polytechnic" },
      { name: "Covenant Polytechnic, Aba" },
      { name: "Temple Gate Polytechnic, Abayi" },
      { name: "Valley View Polytechnic, Aba" },
      { name: "Gregory University Uturu" },
      { name: "Rhema University" },
    ],
  },
  {
    name: "Adamawa State",
    school: [
      { name: "Adamawa State polytechnic, Yola" },
      { name: "Adamawa State University, Mubi" },
    ],
  },
  {
    name: "Anambra State",
    school: [
      { name: "Anambra State University, Anambra" },
      { name: "Nnamdi Azikiwe University" },
      { name: "the Federal Polytechnic, Oko" },
      { name: "Nwafor Orizu University of Education" },
      { name: "The Tansian University, Umunya" },
      { name: "Madonna University, Okija" },
      { name: "St Paul's University, Awka" },
    ],
  },
  {
    name: "Akwa Ibom State",
    school: [
      { name: "University of Uyo" },
      { name: "Maritime Academy of Nigeria, Oron" },
      { name: "Akwa Ibom State University [Oruk Anam LGA And Mkpat Enin LGA]" },
      { name: "Obong University, Obong Ntak Inyang" },
      { name: "Akwa Ibom State Polytechnic" },
      { name: "Uyo City Polytechnic" },
      { name: "Apex Polytechnic" },
      { name: "Heritage Polytechnic, Eket" },
      { name: "School of Nursing Uyo, Eket, Oron, Ikot Ekpene, Etinan" },
      { name: "Akwa Ibom State College of Education, Afaha Nsit" },
      { name: "School Of Basic Studies" },
      { name: "College of Arts & Sciences, Nnung Ukim" },
      { name: "Ritman University" },
      { name: "Sure Polytechnic, Ukanafun" },
    ],
  },
  {
    name: "Bauchi State",
    school: [
      { name: "Abubakar Tafawa Balewa University" },
      { name: "Federal Polytechnic, Bauchi" },
      { name: "FGGC Bauchi" },
      { name: "Lys Children School" },
      { name: "College of Education - Azare" },
      { name: "National Open University Bauchi" },
    ],
  },
  {
    name: "Bayelsa State",
    school: [
      { name: "Bayelsa State College of Health Technology" },
      { name: "Federal Polytechnic, Ekowe" },
      { name: "Federal University, Otueke" },
      { name: "Institute of Science and Technology Yenagoa" },
      { name: "Niger Delta University" },
      { name: "Federal Science and Technical College" },
    ],
  },
  {
    name: "Benue State",
    school: [
      { name: "Federal University of Agriculture, Makurdi" },
      { name: "Benue State University, Makurdi" },
      { name: "University of Mkar, Mkar, Gboko." },
      { name: "Benue State Polytechnic, Ugbokolo" },
      { name: "Fidei polytechnic, Gboko" },
      { name: "Akperan Orshi college of Agriculture Yandev" },
      { name: "Federal College of Education Agasha" },
      { name: "College of Education Oju" },
      { name: "College of Education Kastina Ala." },
    ],
  },
  {
    name: "Borno State",
    school: [
      { name: "College of Education Waka-Biu" },
      { name: "Federal Training Centre, Maiduguri" },
      { name: "Federal University of Maiduguri" },
      { name: "MAI University Of Maiduguri, Maiduguri" },
      { name: "National Open University of Nigeria" },
      { name: "Ramat Polytechnic Maiduguri" },
      { name: "School of Health and Technology, Maiduguri" },
      { name: "School Of Nursing and Midwifery" },
    ],
  },
  {
    name: "Cross River State",
    school: [
      { name: "University of Calabar" },
      { name: "Cross River State University of Technology, Calabar" },
      { name: "Ibrahim Babangida College of Agriculture, Obubra" },
      { name: "Cross River State College of Education, akamkpa" },
      { name: "College of Health Technology, Iboko, Okpoma, Yala" },
      { name: "Technical college Ugep" },
    ],
  },
  {
    name: "Delta State",
    school: [
      { name: "Federal University of Petroleum Resources, Effurun" },
      {
        name: "Delta State University (Abraka Campus, Oleh Campus, Asaba Campus)",
      },
      { name: "Delta State Polytechnic, Ogwashi-Uku" },
      { name: "Delta State Polytechnic, Otefe-Oghara" },
      { name: "Delta State Polytechnic, Ozoro" },
      { name: "College of Education, Agbor" },
      { name: "College of Education, Warri" },
      { name: "Federal College of Education Technical, Asaba" },
      { name: "College of Physical Education, Mosogar" },
      { name: "School of Health Technology, Ughelli" },
      { name: "Petroleum Training Institute, Effurun" },
      { name: "Western Delta University, Oghara" },
      { name: "Novena University, Ogume-Amai" },
      {
        name: "National Open University of Nigeria, Asaba Study Center, Asaba",
      },
      { name: "Delta State School of Marine Technology, Burutu" },
      { name: "Nigerian Maritime University, Okerenkoko, Warri" },
      {
        name: "Conarina School of Maritime & Transport Technology, Oria-Abraka",
      },
      { name: "University of Information and Communication Technology, Agbor" },
      { name: "State School of Midwifery, Asaba" },
      { name: "School of Nursing, Agbor" },
      { name: "School of Nursing, Warri" },
      { name: "Baptist School of Nursing" },
      { name: "Eku Edwin Clark University" },
      { name: "Kiagbodo Eagle Heights University, Omadino" },
      { name: "Warri Nigerian Naval School" },
      { name: "Sapele Nigerian Navy Maritime University, Ibusa" },
    ],
  },
  {
    name: "Ebonyi State",
    school: [
      { name: "Ebonyi State University, Abakaliki (EBSU)" },
      { name: "Federal University Ndufu Alike Ikwo (FUNAI)" },
      { name: "Ebonyi State College of Education Ikwo (EBSCOEI)" },
      { name: "Akanu Ibiam Federal Polytechnic, Unwana" },
      { name: "College of Health Sciences" },
      { name: "Ezzamgbo and Federal College of Agriculture, Ishiagu." },
    ],
  },
  {
    name: "Enugu State",
    school: [
      { name: "University of Nigeria, Nsukka" },
      { name: "Enugu State University of Science & Technology (ESUT)" },
      { name: "Institute of Management and Technology (IMT)" },
      { name: "Federal Cooperative College, Oji River (FCCO)" },
      { name: "Enugu State College of Education Technical, Enugu" },
      { name: "Caritas University, Amorji-Nike" },
      { name: "Renaissance University, Ugbawka" },
      { name: "Federal School of Dental Technology & Therapy" },
      { name: "College of Immaculate Conception" },
      { name: "Our Saviour Institute of Science and Technology, Enugu" },
      { name: "the Federal College of Education" },
      { name: "Eha-AmufuCaritas University" },
    ],
  },
  {
    name: "Edo State",
    school: [
      { name: "(ICE) Institute of Continuing Education, Auchi" },
      { name: "(MILI) Miracle Invasion Leadership Institute" },
      {
        name: "(NICPS) Nigerian Institute Of Computer Science And Professional Studies",
      },
      { name: "(SAU) Samuel Adegboyega University, Ogwa" },
      { name: "AAU Ambrose Ali University, Ekpoma" },
      { name: "BEN University Of Benin, Benin" },
      { name: "Bendel State University, Ekpoma" },
      { name: "Benin Technical College, Benin City" },
      { name: "BIU Benson Idahosa University, Benin City" },
      { name: "College of Education Benin" },
      { name: "Edo State Institute of Technology and Management Usen" },
      { name: "Federal Polytechnic Auchi" },
      { name: "IGB Igbinedion University, Okada-Benin" },
      { name: "Lighthouse Polytechnic, Evbuobanosa" },
      { name: "National Open University Of Nigeria" },
      { name: "Seminary of All Saints, Uhiele, Ekpoma " },
      { name: "Shaka Polytechnic, Benin City" },
      { name: "State School of Nursing, Sapele Road, Benin City" },
      { name: "Usen Polytechnic" },
      { name: "Wellspring University, Benin City" },
      { name: "World Outreach Bible College" },
    ],
  },
  {
    name: "Ekiti State",
    school: [
      { name: "Afe Babalola University" },
      { name: "College of Education,Ikere-Ekiti" },
      { name: "Crown Polytechnic Ado Ekiti" },
      { name: "Divisional Teacher's Training College, Oye-Ekiti" },
      { name: "Federal Polytechnic, Ado-Ekiti" },
      { name: "Federal University Oye Ekiti" },
      { name: "Government Technical College, Ado-Ekiti" },
      { name: "His Mercy Computer Institute " },
      { name: "School of Nursing" },
      { name: "UAD University Of Ado-Ekiti, Ado-Ekiti" },
      { name: "University Of Science And Technology, Ifaki" },
    ],
  },
  {
    name: "Gombe State",
    school: [
      { name: "(CABS) College of Administrative and Business Studies, Kumo" },
      { name: "(FUK) Federal University Kashere" },
      { name: "Federal College of Education (Tech) Gombe" },
      { name: "GSU Gombe State University Tudun Wada, Tudun Wada" },
      { name: "National Open University Of Nigeria" },
    ],
  },
  {
    name: "Imo State",
    school: [
      { name: "(CIP) Claretian Institute of Philosophy, Maryland Nekede" },
      { name: "African Institute Of Science And Technology" },
      { name: "ALV Alvan Ikoku College Of Education, Owerri" },
      { name: "College of Business and Legal Studies" },
      { name: "College of Health Science and Technology, Amaigbo, Nwangele" },
      { name: "Federal Polytechnic Nekede Owerri" },
      { name: "FUTO Fed University Of Technology, Owerri" },
      { name: "Imo State Polytechnic, Umuagwo" },
      { name: "Imo State Technological Skills Acquisition Institution, Orlu" },
      { name: "IMSU Imo State University, Owerri" },
      { name: "National Horticultural Research Institute, Ibadan" },
      { name: "National Open University of Nigeria" },
      { name: "School of Nursing" },
      { name: "Word of Faith Bible Institute" },
    ],
  },
  {
    name: "Jigawa State",
    school: [
      { name: "Federal University, Dutse" },
      { name: "Hussaini Adamu Federal Polytechnic Kazaure" },
      { name: "informatics Institute of Information Technology, Kazaure" },
      { name: "Jigawa State College Of Agriculture, Hadejia" },
      { name: "Jigawa State College of Education, Gumel, Dutse" },
      { name: "Jigawa State Polytechnic" },
    ],
  },
  {
    name: "Kaduna State",
    school: [
      { name: "(NTI) National Teacher's Institute" },
      { name: "ABU Ahmadu Bello University, Zaria" },
      { name: "Air Force Institute Of Technology" },
      { name: "Ameer Shehu Idris College of Advanced Studies, Zaria" },
      { name: "Bouyeat Computer Training School" },
      { name: "Dialogue Computer Institute" },
      { name: "ECWA Theological Seminary Kagoro" },
      { name: "Fantsuam Foundation, Kafanchan" },
      { name: "Federal College of Chemical and Leather Technology" },
      { name: "Federal College Of Forestry Mechanization" },
      { name: "Federal Cooperative College" },
      { name: "Federal School of Statistics" },
      { name: "Federal Training Centre" },
      { name: "Gralom Computer Institute" },
      { name: "Innovation Institute of Technology" },
      { name: "KAD Kaduna Polytechnic, Kaduna" },
      { name: "Kaduna State College of Education" },
      { name: "KAS Kaduna State University, Kaduna" },
      { name: "National Open University of Nigeria, Kaduna" },
      { name: "Nigerian College Of Aviation Technology, Zaria" },
      { name: "Nigerian Defence Academy, Kaduna" },
      { name: "Nigerian Institute of Leather and Science Technology, Samuru" },
      { name: "Nuhu Bamalli Polytechnic Zaria" },
      { name: "School Of Health Technology, Makarfi" },
      { name: "St. Gerard's School of Nursing, Kakuri" },
      { name: "St. Luke Anglican School of Nursing, Wusasa, Zaria" },
      { name: "The Federal College of Chemical and Leather Technology, Zaria" },
      { name: "ZAR Federal College Of Education, Zaria" },
    ],
  },
  {
    name: "Kano State",
    school: [
      { name: "(SRCOE) Sa'adatu Rimi College Of Education, Kumbotso" },
      { name: "Aminu Kano College of Islamic And Legal Studies" },
      { name: "BAY Bayero University, Kano" },
      { name: "British Career Training College" },
      { name: "College of Art, Science and Remedial Studies" },
      { name: "Computer Training Institute" },
      { name: "Federal College of Education (Technical), Bichi" },
      { name: "KAN Federal College Of Education, Kano" },
      { name: "Kano State Polytechnic" },
      { name: "king's Glory University" },
      { name: "KUT Kano University Of Technology, Kano" },
      { name: "Nigerian Police Academy, Wudil" },
      { name: "School of Health Technology" },
    ],
  },
  {
    name: "Katsina State",
    school: [
      { name: "Federal College of Education Katsina" },
      { name: "Federal University Dutsin-Ma" },
      { name: "Hassan Usman Katsina Polytechnic" },
      { name: "Isah Kaita College of Education Dutsinma" },
      { name: "KTS Katsina State University, Katsina" },
      { name: "National Open University Of Nigeria" },
      { name: "School of Basic and Remedial Studies Funtua" },
      { name: "School of Health Technology Daura" },
      { name: "School of Health Technology Kankia" },
      { name: "School of Midwifery Malumfashi" },
      { name: "School of Nursing Katsina" },
      { name: "Umaru Musa Yaradua University" },
      { name: "YBU College of Legal and General Studies Daura" },
    ],
  },
  {
    name: "Kebbi State",
    school: [
      { name: "Adamu Augie College of Education, Argungu" },
      { name: "KUS Kebbi State Univ. Of Science & Tech. Aliero, Aliero" },
      { name: "Waziri Umaru Federal Polytechnic" },
    ],
  },
  {
    name: "Kogi State",
    school: [
      { name: "ECWA School of Nursing (ECWASON)" },
      { name: "Federal College of Education, Okene" },
      { name: "Federal University Of Lokoja" },
      { name: "Kogi State College of Education" },
      { name: "Kogi State College of Nursing" },
      { name: "Kogi State Polytechnic, Lokoja" },
      { name: "KSU Kogi State University, Anyigba" },
      { name: "National Open University of Nigeria" },
      { name: "SUL Salem University, Lokoja" },
      { name: "The Federal Polytechnic Idah" },
    ],
  },
  {
    name: "Kwara State",
    school: [
      { name: "College of Nursing, Ilorin" },
      { name: "Federal Polytechnic Offa" },
      { name: "HIK Al-Hikmah University, Ilorin" },
      { name: "ILO University Of Ilorin, Ilorin" },
      { name: "Kwara State College of Arabic and Islamic Legal Studies" },
      { name: "Kwara State College of Education" },
      { name: "Kwara State College of Health and Technology" },
      { name: "Kwara State Polytechnic, Ilorin" },
      { name: "Kwara State University (KWASU)" },
      { name: "Landmark university omu-aran" },
      { name: "National Open University of Nigeria" },
    ],
  },
  {
    name: "Lagos State",
    school: [
      { name: "(CIBN) The Chartered Institute of Bankers of Nigeria" },
      { name: "(CISN) Certified Institute of Shipping of Nigeria" },
      { name: "(ICAN) The Institute of Chartered Accountants of Nigeria" },
      { name: "(ISMN) Institute of Strategic Management, Nigeria" },
      { name: "(LASOTAT) Lagos School of Travels and Tourism" },
      { name: "(LBS) Lagos Business School" },
      { name: "(NIPR) The Nigerian Institute Of Public Relations" },
      { name: "(NITT) Nigerian Institute of Transport Technology" },
      { name: "(NOIC) Nigerian Opportunity Industrialization Centre, Gbagada" },
      { name: "Adeniran Ogunsanya College Of Education" },
      { name: "Administrative Staff College of Nigeria (ASCON) Badagry" },
      { name: "Aeroland Aviation School" },
      { name: "African Maritime Academy" },
      { name: "AMEC college" },
      { name: "Aptech" },
      { name: "Association of National Accountants of Nigeria (ANAN)" },
      { name: "Bells Univ. Of Tech. Badagry, Badagry" },
      { name: "Chartered Institute of Administration" },
      { name: "Chartered Institute of Marketing of Nigeria" },
      { name: "Chartered Institute of Purchasing and Supply Management" },
      { name: "College Of Aviation And Management Studies" },
      { name: "Command Day Secondary School, Ojo" },
      { name: "Caleb University, Lagos" },
      { name: "Dalewares Institute of Technology" },
      { name: "Excel College" },
      { name: "Ezekiel Private College" },
      { name: "Federal College of Education" },
      { name: "Federal College of Education (Technical), Akoka" },
      { name: "Federal College of Fisheries and Marine Technology" },
      { name: "Federal College Of Orthopedic Technology, Igbobi" },
      { name: "Federal School of Arts and Sciences, Victoria Island" },
      { name: "Federal School of Occupational Therapy" },
      { name: "Federal School of Science" },
      { name: "Federal Science and Technical College" },
      { name: "Federal Training Centre, Ikoyi" },
      { name: "First Immaculate Computer Institute" },
      { name: "Goethe-Institut" },
      { name: "Government Technical College, Ado-Soba" },
      { name: "Government Technical College, Ikorodu" },
      { name: "Grano Institute of Catering and Hotel Management" },
      {
        name: "Helpers International College  Institute for Industrial Technology",
      },
      { name: "Institute of Chartered Economists of Nigeria (ICEN)" },
      { name: "Institute of Data Processing Management (IDPM)" },
      { name: "Ipakodo Grammar School, Ikorodu" },
      { name: "karROX ICT Institute" },
      { name: "Keme Balogun College, Ibeshe" },
      { name: "LAG University Of Lagos, Lagos" },
      { name: "Lagos City Computer College (LCC)" },
      { name: "Lagos State College of Health Technology, Yaba" },
      { name: "Lagos state polytechnic" },
      { name: "Lagos University Teaching Hospital" },
      { name: "LIFE Theological Seminary, Ikorodu," },
      { name: "Logos Institute, Maryland" },
      { name: "LSU Lagos State University, Ojo Lagos" },
      { name: "McAdolph Institute Of Information Technology" },
      { name: "Michael Otedola College of Primary Education" },
      { name: "National Open University of Nigeria" },
      { name: "New Horizons Computer Learning Centres" },
      { name: "Nigerian Army School Of Finance And Administration (NASFA)" },
      { name: "Nigerian Army School Of Medical Sciences" },
      { name: "Nigerian Army School of Nursing and Midwifery" },
      {
        name: "Nigerian Institute of Journalism  Nigerian Institute Of Management (Chartered)",
      },
      { name: "Nigerian Institute of Safety Professionals, Ikotun" },
      { name: "Nigerian Law School" },
      { name: "NIIT National Institute of Information Technology" },
      {
        name: "PEFTI Film Institute  Portfolio And Debt Management Institute Of Nigeria",
      },
      { name: "Redeemers University  Ronik Polytechnic" },
      { name: "Saint Finbarr's College, Akoka" },
      { name: "Yaba  Speedway Information Technology Institute" },
      { name: "Times Journalism Institute, Surulere" },
      { name: "Wolex Polytechnic" },
      { name: "YAB Yaba College Of Technology, Yaba, Lagos" },
      { name: "Yetkem Institute of Catering and Hotel Management" },
    ],
  },
  {
    name: "Nasarawa State",
    school: [
      { name: "Bingham University, Karu" },
      { name: "BIN Gham University, New Karu" },
      { name: "Federal University of Technology, Lafia" },
      { name: "National Open University Of Nigeria" },
      { name: "NSU Nasarawa State University, Keffi" },
      { name: "College of Education, Akwanga" },
      { name: "College of Agriculture, Lafia" },
      { name: "FEDPOLYNAS Federal Polytechnic Nasarawa" },
      { name: "Nacabs Polytechnic, Akwanga" },
      { name: "Nasarawa State Polytechnic, Lafia" },
    ],
  },
  {
    name: "Niger State",
    school: [
      { name: "Federal University of Technology" },
      { name: "MIN Fed University Of Technology, Minna" },
      { name: "National Open University Of Nigeria" },
      { name: "Federal Polytechnic Bida" },
      { name: "Niger State Polytechnic, Zungeru" },
      { name: "(CABS) College of Administrative and Business Studies, Bida" },
      { name: "CEM College Of Education, Minna" },
      { name: "College for Legal and General Studies, Minna" },
      { name: "College of Arts and Islamic Legal Studies" },
      { name: "Federal College of Education, Kontagora" },
      { name: "Federal College of Wildlife Management, New Bussa" },
      { name: "Niger State College of Nursing, Bida" },
      { name: "Suleiman Barau Technical College, Suleja" },
    ],
  },
  {
    name: "Ogun State",
    school: [
      { name: "BAB Babcock University, Ilisan-Remo" },
      { name: "Babcock University" },
      { name: "Bells University of Technology" },
      { name: "CRA Crawford University, Igbesa" },
      { name: "Crescent University, Abeokuta" },
      { name: "National Open University Of Nigeria" },
      { name: "OOU Olabisi Onabanjo University, Ago-Iwoye" },
      { name: "UAA University Of Agriculture, Abeokuta" },
      { name: "Abraham Adesanya Polytechnic" },
      { name: "Allover Central Polytechnic" },
      { name: "D S Adegbenro Ict Polytechnic, Itoro, Ewekoro" },
      { name: "Federal Polytechnic Ilaro" },
      { name: "Grace Polytechnic" },
      { name: "Moshood Abiola Polytechnic, Ojere, Abeokuta" },
      { name: "The Gateway Polytechnic" },
      { name: "Federal College of Education Abeokuta" },
      { name: "Ifo College of Management and Technology" },
      { name: "Ogun State College Of Health Technology" },
      { name: "Savannah College of Art and Design" },
      { name: "TAI Tai Solarin College Of Education, Ijebu-Ode" },
      { name: "Yewa Central College of Education" },
      { name: "Zulikha Abiola College of Arabic And Islamic Studies" },
    ],
  },
  {
    name: "Ondo State",
    school: [
      { name: "Adekunle Ajasin University" },
      { name: "Federal University of Technology, Akure" },
      { name: "Rufus Giwa Polytechnic" },
      { name: "Achievers University" },
      { name: "University of Medical Sciences, Ondo" },
      { name: "Ondo State University of Science and Technology" },
      { name: "Wesley University of Science and Technology" },
      { name: "Elizade University" },
      { name: "Adeyemi College of Education, Ondo." },
    ],
  },
  {
    name: "Osun State",
    school: [
      { name: "Adeleke University, Ede" },
      { name: "Bowen University, Iwo" },
      { name: "Christwealth University ifewara" },
      { name: "FUO Fountain University, Osogbo" },
      { name: "Ladoke Akintola University Of Technology Ogbomoso" },
      { name: "National Open University of Nigeria" },
      { name: "OAU Obafemi Awolowo University, Ile-Ife" },
      { name: "Obafemi Awolowo University Staff School, Ife" },
      { name: "Oduduwa University" },
      { name: "Osun State University, Osogbo" },
      { name: "University of Abu" },
      { name: "Federal Polytechnic Ede" },
      { name: "Interlink Polytechnic" },
      { name: "Osun State Polytechnic, Iree" },
      { name: "The Polytechnic, Ile-Ife" },
      { name: "Osun State College of Technolgy, Esa oke" },
      { name: "Universal College of Technology Ile-ife" },
      { name: "College of Arts and Science, Ile Ife" },
      { name: "OSC Osun State College Of Education Ilesa, Ilesa" },
      { name: "OSS college of EDucation, Ila-Orangun" },
    ],
  },
  {
    name: "Oyo State",
    school: [
      { name: "ACU Ajayi Crowther University, Oyo" },
      { name: "University Of Ibadan, Ibadan" },
      { name: "Lead City University, Ibadan" },
      { name: "National Open University of Nigeria" },
      { name: "The Polytechnic, Ibadan" },
      { name: "St. Winifred Innovative Polytechnic, Ibadan" },
      { name: "The Polytechnic, Ibadan" },
      { name: "Tower Polytechnic, Ibadan" },
      { name: "Best Legacy College of Education, Ogbomoso" },
      { name: "Federal College of Agriculture, Moor Plantation, Ibadan" },
      { name: "Federal College Of Animal Production and Health" },
      { name: "Federal College of Education" },
      { name: "Federal College of Forestry" },
      { name: "Mufutau Lanihun College of Education, Ibadan" },
      { name: "Oyo Stae College of Education" },
      { name: "St. Andrews College Of Education, Oyo" },
    ],
  },
  {
    name: "Plateau State",
    school: [
      { name: "JOS University Of Jos, Jos" },
      { name: "PSU Plateau State University, Bokkos" },
      { name: "Plateau State Polytechnic" },
      { name: "(NCA) Nigerian College of Accountancy, Jos" },
      { name: "College of Arabic and Islamic Studies(cais)Rikkos" },
      { name: "College of Arts, Science and Technology, Kurgwi" },
      { name: "College of Education, Jos" },
      { name: "College of Health Technology, Zawan" },
      { name: "Federal College of Education, Pankshin" },
    ],
  },
  {
    name: "Rivers State",
    school: [
      { name: "Madonna University" },
      { name: "National Open University Of Nigeria" },
      { name: "University Of Port-Harcourt, Port-Harcourt" },
      { name: "Rivers State University Of Technology, Port-Harcourt" },
      { name: "University of Science and Technology, Diobu, Port Harcourt" },
      { name: "Rivers State polytechnic" },
      { name: "King's College of Commerce, Buguma" },
      { name: "Federal College of Education (Technical), Omoku" },
      { name: "College Of Education, Port-Harcourt" },
      { name: "Rivers State College Of Arts" },
      { name: "Rivers State College of Health Science and Technology" },
    ],
  },
  {
    name: "Sokoto State",
    school: [
      { name: "Sokoto State University" },
      { name: "National Open University of Nigeria" },
      { name: "UDU Usmanu Danfodio University, Sokoto" },
      {
        name: "Usmanu Danfodiyo University Teaching Hospital School Of Nursing (UDUTHSON)",
      },
      { name: "Sokoto State Polytechnic" },
      { name: "Haliru Binji College Of Art and Science" },
      { name: "Sokoto Teachers' College" },
    ],
  },
  {
    name: "Taraba State",
    school: [
      { name: "TAR Taraba State University, Jalingo" },

      { name: "Taraba State Polytechnic, Jalingo" },
      { name: "Federal Science and Technical College" },
      { name: "Taraba State College of Agriculture, Jalingo" },
    ],
  },
  {
    name: "Yobe State",
    school: [
      { name: "Buka Abba Ibrahim University, Damaturu" },
      { name: "Federal Polytechnic Damaturu" },
      { name: "College of Administrative and Business Studies, Potiskum" },
      { name: "College of Education, Gashua" },
    ],
  },
  {
    name: "Zamfara State",
    school: [
      { name: "Abdul Gusau Polytechnic" },
      { name: "Federal Polytechnic Kaura Namoda" },
      { name: "Kaura Knamoda Polytechnic" },
      { name: "College of Islamic Science, Gusau" },
      { name: "Federal University Gusau, Zamfara State" },
    ],
  },
  {
    name: "Abuja State",
    school: [
      { name: "(NTNU) Nigerian Turkish Nile University" },
      { name: "African University of Science and Technology" },
      { name: "National Open University of Nigeria" },
    ],
  },
];
