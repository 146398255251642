import React from "react";
import "./loanMobileTitleComponent.css";
export default function LoanMobileTitleComponent({ title }) {
  return (
    <div
      className="loan-mobile-title"
      style={{
        paddingTop: "55px",
        paddingLeft: "32px",
        fontSize: "20px",
        fontWeight: "700",
      }}
    >
      {title}
    </div>
  );
}
