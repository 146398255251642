import React from "react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import "./googleAutocomplete.css";
import Valid from "../../images/valid.png";
import Invalid from "../../images/invalid.png";
export default function GoogleAutocomplete({
  onChangeValue,
  isValid,
  top,
  right,
  left,
}) {
  var options = {
    types: ["(regions)"],
    componentRestrictions: { country: "Ng" },
  };
  return (
    <div style={{ position: "relative" }} className="google-location-div">
      <div style={{ position: "absolute", left: left, top, zIndex: 1000 }}>
        <img src={isValid ? Valid : Invalid} alt="checked" />
      </div>
      <GooglePlacesAutocomplete
        options={options}
        apiKey="AIzaSyCGPY_hsHcarYRmtuyvZCTOyoRWGN7-JGA"
        selectProps={{
          onChange: (evt) => onChangeValue(evt),
          placeholder: "Enter your address",
        }}
      />
    </div>
  );
}
