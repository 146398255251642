import React from "react";

export default function RequestWaitButton({
  customWith,
  customHeight,
  customBackground,
  customColor,
  text,
  customPaddingTop,
  customPaddingBottom,
  customBorderRadius,
}) {
  return (
    <button
      style={{
        width: customWith,
        height: customHeight,
        background: customBackground,
        color: customColor,
        paddingTop: customPaddingTop,
        paddingBottom: customPaddingBottom,
        borderRadius: customBorderRadius,
        border: "none",
      }}
    >
      {text}
    </button>
  );
}
