import React, { useState } from "react";
import LandingPageHeader from "../../components/landingHeader/landingPageHeader";
import AuthForm from "../authForm/authForm";
import EnjoyCoverComponent from "../landingPage/enjoyCoverComponent";
import MobileBannerAuth from "../mobileBannerAuth";
import "./recoverPassword.css";
import Arrow from "../../images/arrow-button.png";
import { useNavigate } from "react-router-dom";
import ForgotPassword from "../../images/Forget-Password-banner.png";
import { emailValidation } from "../../utilities/utilities";
import MessageComponent from "../../components/messageComponent/messageComponent";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import RequestWaitButton from "../../components/requestWaitButton/requestWaitButton";
import services from "../../services/services";
export default function RecoverPassword() {
    const navigator = useNavigate();
    const dispatch = useDispatch();
    const [userDetails, setuserDetails] = useState({
        email: "",
    });

    const [emailChecker, setemailChecker] = useState(false);
    const loadState = useSelector((state) => state.loadState);
    const [MessageDetails, setMessageDetails] = useState({
        message: "",
        status: false,
    });
    const handleInputChange = (value, attribute) => {
        console.log(value);
        setuserDetails({
            ...userDetails,
            [attribute]: value,
        });
        if (attribute === "email") {
            setemailChecker(emailValidation(value));
        }
    };

    const handleContinue = () => {
        dispatch({ type: "LOAD_STATE", data: true });
        if (!emailValidation || userDetails.email === "") {
            dispatch({ type: "LOAD_STATE", data: false });
            setMessageDetails({
                message: "Invalid email address",
                status: false,
            });
        } else {
            const getresponse = new services().postService(
                "auth/forget_password",
                userDetails
            );
            getresponse
                .then((data) => {
                    dispatch({ type: "LOAD_STATE", data: false });
                    if (data.errorMessage) {
                        setMessageDetails({
                            message: data.errorMessage,
                            status: false,
                        });
                    } else {
                        console.log(data);
                        navigator("/login");
                    }
                })
                .catch((error) => {
                    dispatch({ type: "LOAD_STATE", data: false });
                    // console.log("Network request failed");
                });
        }
    };

    return (
        <div>
            <div className="desktop-view-forget-password">
                <div>
                    <LandingPageHeader color={"white"} />
                </div>
                <div className="signup-container">
                    <div className="container col-xl-8 col-lg-11 ">
                        <div className="sign-up-desktop">
                            <div className="row m-0 landing-row-cover">
                                <EnjoyCoverComponent recoverPasswordImage />

                                <div className="col-md col-12 recover-form-cover">
                                    <div className="recover-title">
                                        Request Password
                                    </div>

                                    <div>
                                        <div style={{ marginBottom: "23px" }}>
                                            <AuthForm
                                                placeholder="Email Address"
                                                right={"18px"}
                                                top={"14px"}
                                                isValid={emailChecker}
                                                handleChange={(evt) =>
                                                    handleInputChange(
                                                        evt.target.value,
                                                        "email"
                                                    )
                                                }
                                            />
                                        </div>
                                        <div style={{ paddingTop: "23px" }}>
                                            {MessageDetails.message ? (
                                                <MessageComponent
                                                    messageDetails={
                                                        MessageDetails
                                                    }
                                                />
                                            ) : (
                                                ""
                                            )}
                                            <div
                                                style={{ position: "relative" }}
                                            >
                                                {loadState ? (
                                                    <div
                                                        style={{
                                                            marginTop: "30px",
                                                        }}
                                                    >
                                                        <RequestWaitButton
                                                            text={
                                                                "REQUEST PASSWORD"
                                                            }
                                                            customBackground={
                                                                "#A6ACBE"
                                                            }
                                                            opacity={0.15}
                                                            customHeight={
                                                                "55px"
                                                            }
                                                            customPaddingBottom={
                                                                "19px"
                                                            }
                                                            customPaddingTop={
                                                                "19px"
                                                            }
                                                            customColor={
                                                                "white"
                                                            }
                                                            customWith={"95%"}
                                                            customBorderRadius={
                                                                "28px"
                                                            }
                                                        />
                                                    </div>
                                                ) : (
                                                    <button
                                                        className="btn btn-default desktop-submit-button"
                                                        onClick={handleContinue}
                                                    >
                                                        REQUEST PASSWORD
                                                        <img
                                                            src={Arrow}
                                                            alt="arrow"
                                                            style={{
                                                                position:
                                                                    "absolute",
                                                                left: "90%",
                                                            }}
                                                        />
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                marginTop: "10px",
                                                color: "#8B8B8B",
                                                fontSize: "13px",
                                                textAlign: "center",
                                            }}
                                        >
                                            Have Password?{" "}
                                            <span
                                                className="recover-login-span"
                                                onClick={() =>
                                                    navigator("/login")
                                                }
                                            >
                                                Login
                                            </span>
                                        </div>
                                        <div
                                            style={{
                                                marginTop: "23px",
                                                color: "#8B8B8B",
                                                fontSize: "13px",
                                                textAlign: "center",
                                                marginBottom: "94px",
                                            }}
                                        >
                                            Have Password?{" "}
                                            <span
                                                className="recover-login-span"
                                                onClick={() =>
                                                    navigator("/register")
                                                }
                                            >
                                                Sign Up
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="recover-mobile-cover">
                <div className="recover-mobile-title">Forgot Password</div>
                <div
                    style={{
                        marginTop: "76px",
                        textAlign: "center",
                        marginBottom: "54px",
                    }}
                >
                    <img src={ForgotPassword} alt="logo" />
                </div>
                <div
                    style={{
                        textAlign: "center",
                        color: "#999999",
                        fontSize: "14px",
                        fontWeight: "400",
                    }}
                >
                    Enter your registered email below to receive password reset
                    instruction
                </div>
                <div
                    style={{
                        marginTop: "40px",
                        paddingLeft: "24px",
                        paddingRight: "24px",
                        marginBottom: "58px",
                    }}
                >
                    <div
                        style={{
                            fontSize: "12px",
                            fontWeight: "400",
                            color: "#020614",
                            marginBottom: "14px",
                        }}
                    >
                        Email Address
                    </div>
                    <input
                        type="email"
                        className="form-control recover-mobile-input-form"
                        placeholder="Email Address"
                        onChange={(evt) =>
                            handleInputChange(evt.target.value, "email")
                        }
                    />
                </div>

                <div
                    style={{
                        paddingLeft: "24px",
                        paddingRight: "24px",
                        marginBottom: "98px",
                    }}
                >
                    {MessageDetails.message ? (
                        <div style={{ marginBottom: "20px" }}>
                            <MessageComponent messageDetails={MessageDetails} />
                        </div>
                    ) : (
                        ""
                    )}

                    <button
                        className="btn btn-default desktop-submit-button"
                        onClick={handleContinue}
                    >
                        Resend Password
                    </button>
                </div>
            </div>
        </div>
    );
}
