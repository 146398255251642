import React, { useEffect } from "react";
import Sidebar from "../components/sidebar/sidebar";
import Header from "../components/header/header";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route } from "react-router-dom";
import SignupPage from "../auth/signup/signup";
import Dashboard from "./dashboard/dashboard";
import SelectLoanProduct from "./applyLoan/selectLoanProduct/selectLoanProduct";
import BvnIdetification from "./applyLoan/BVNIdentification/BvnIdetification";
import EmploymentDetails from "./applyLoan/employmentDetails/employmentDetails";
import PersonalDetails from "./applyLoan/personalDetails/personalDetails";
import GuarantorInfo from "./applyLoan/guarantorInfo/guarantorInfo";
import CreditCheck from "./applyLoan/creditCheck/creditCheck";
import ViewExistingLoan from "./ViewExistingLoan/ViewExistingLoan";
import LoanHistoryDetails from "./ViewExistingLoan/loanHistory-details";
import BorrowLoanSchedule from "./borrowLoanSchedule/borrowLoanSchedul";
import Repayment from "./repayment/repayment";
import { getDataFromLocalStorage } from "../utilities/utilities";
import { useNavigate } from "react-router";
import LandingPage from "../auth/landingPage/landingPage";
import RootLandingPage from "../rootLandingPage/rootLandingPage";
import BankStatement from "./bankStatement/bankStatement";
import UploadBankDetails from "./uploadBankStatement/uploadBankDetails";
import ConfirmLoanScreen from "./confirmLoanScreen/confirmLoanScreen";
import SimpleBackdrop from "../components/backdrop/backdrop";
import ToastNotification from "../components/toastNotificaion/toastNotification";
import AllRepayment from "./allRepayments/allRepayment";
import NextRepayment from "./nextRepaymet/nextRepayment";
import CreateLoan from "./createLoan/createLoan";
import MyAccount from "./myAccount/myAccount";
import ChangePassword from "./changePassword/changePassword";
import EditUserProfile from "./editUserProfile/editUserProfile";
import Guarantors from "./guarantors/guarantors";
import GuarantorEmploymentDetails from "./guarantorEmploymentDetails/guarantorEmploymentPage";
import UploadBorrowFile from "./uploadBorrowFile/uploadBorrowFile";
import RestructureForm from "./verifications/accept-reject-restructure";
export default function RootScreen() {
  const mobileMenuToggle = useSelector((state) => state.mobileMenuToggle);
  const navigator = useNavigate();
  const userDetails = useSelector((state) => state.userDetails);
  const dispatch = useDispatch();

  return Object.keys(userDetails).length !== 0 ? (
    <div style={{ display: "flex" }} className="row m-0">
      <Header />
      <div className={`col-lg-2 p-0 main-sidebar-cover `}>
        <Sidebar />
      </div>
      <div
        className={`col-lg-2 p-0 mobile-sidebar-cover `}
        style={{
          display: mobileMenuToggle ? "block" : "none",
        }}
      >
        <Sidebar />
      </div>

      <div className="col p-0">
        <div onClick={() => dispatch({ type: "TOGGLE_MENU", data: false })}>
          <Routes>
            <Route path="/dashboard" element={<Dashboard />}></Route>
            <Route
              path="/loans/select-loan"
              element={<SelectLoanProduct />}
            ></Route>
            <Route
              path="/loans/bvn-identification"
              element={<BvnIdetification />}
            ></Route>
            <Route
              path="/loans/employment-details"
              element={<EmploymentDetails />}
            ></Route>
            <Route
              path="/loans/personal-details"
              element={<PersonalDetails />}
            ></Route>
            <Route
              path="/loans/guarantor-info"
              element={<GuarantorInfo />}
            ></Route>
            <Route path="/loans/credit-check" element={<CreditCheck />}></Route>
            <Route path="/existing-loan" element={<ViewExistingLoan />}></Route>
            <Route
              path="/borrower-loan-schedule/:id"
              element={<BorrowLoanSchedule />}
            ></Route>
            <Route path="/repayment/:id" element={<Repayment />}></Route>
            <Route
              path="/loans/bank-statement"
              element={<BankStatement />}
            ></Route>
            <Route
              path="/loans/upload-bank-statement"
              element={<UploadBankDetails />}
            ></Route>
            <Route
              path="/loans/confirm"
              element={<ConfirmLoanScreen />}
            ></Route>
            <Route path="/repayment" element={<AllRepayment />}></Route>
            <Route
              path="/next-repayment/:id"
              element={<NextRepayment />}
            ></Route>
            <Route path="/create-loan" element={<CreateLoan />}></Route>
            <Route path="/my-account" element={<MyAccount />}></Route>
            <Route path="/change-password" element={<ChangePassword />}></Route>
            <Route path="/edit-profile" element={<EditUserProfile />}></Route>
            <Route
              path="/loans/guarantor-employment-details"
              element={<GuarantorEmploymentDetails />}
            ></Route>
            <Route
              path="/loans/guarantor-details"
              element={<Guarantors />}
            ></Route>

            <Route
              path="/loans/borrow-file"
              element={<UploadBorrowFile />}
            ></Route>
            <Route path="/restructure" element={<RestructureForm />}></Route>
          </Routes>
        </div>
      </div>

      <ToastNotification />
      <SimpleBackdrop />
    </div>
  ) : (
    <RootLandingPage />
  );
}
