import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import services from "../../services/services";
import { formatToCurrency } from "../../utilities/utilities";
import RepaymentButton from "../repayment/repaymentButton";
import "./allRepayment.css";
import moment from "moment";
import GoBack from "../../components/goBack/goBack";
export default function AllRepayment() {
  const userDetails = useSelector((state) => state.userDetails);
  console.log("--------------------", userDetails);
  const getAllRepayment = useSelector((state) => state.getAllRepayment);
  const dispatch = useDispatch();
  useEffect(() => {
    const getLoan = new services().getService("loan", userDetails.token);
    getLoan
      .then((res) => {
        const loans = res?.data;
        const id = [];
        loans.map((loan) => id.push(loan.id));
        return id;
      })
      .then((res) => {
        const getAllRepayment = new services().getService(
          "repayments",
          userDetails.token
        );
        if (res.errorMessage) {
          dispatch({ type: "GET_ALL_REPAYMENT_DETAILS", data: [] });
        } else {
          const result = getAllRepayment.data.filter((rep) =>
            res.includes(rep.id)
          );
          dispatch({ type: "GET_ALL_REPAYMENT_DETAILS", data: result });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const makeRepayment = (amount, id) => {
    dispatch({ type: "SHOW_SPINNER", data: true });
    // console.log(loanId);
    let data = {
      loan_id: id,
      amount: parseInt(amount),
    };
    const postRepayment = new services().postServiceWithToken(
      "repayments/make-repayment",
      data,
      userDetails.token
    );
    postRepayment
      .then((res) => {
        if (res.errorMessage) {
          let errorMessage = {
            message: res.errorMessage,
            status: false,
          };

          dispatch({ type: "ERROR_MESSAGE", data: errorMessage });
        } else {
          setTimeout(() => {
            window.location.href = res?.data?.authorization_url;
          }, 700);
          let errorMessage = {
            message: "Repayment successful",
            status: true,
          };

          dispatch({ type: "ERROR_MESSAGE", data: errorMessage });
        }
        dispatch({ type: "SHOW_SPINNER", data: false });

        dispatch({ type: "SHOW_TOAST", data: true });
      })
      .catch((error) => {
        dispatch({ type: "SHOW_SPINNER", data: false });
        console.log(error);
      });
    return dispatch({ type: "SHOW_TOAST", data: false });
  };

  return (
    <div className="repayment-main-cover">
      <div className="all-repayment-for-desktop">
        <div className="upcoming-repayment-title">
          <span style={{ display: "flex" }}>
            {" "}
            <GoBack /> Repayments
          </span>
        </div>
        <div className="upcoming-header-cover">
          <div className="row m-0 p-0 upcoming-repayment-header">
            <div className="col-6 left-upcoming-repayment-header">
              Repayment
            </div>
            <div className="col-6 right-upcoming-repayment-header">Action</div>
          </div>
        </div>
        <div className="upcoming-repayment-content">
          {getAllRepayment?.length ? (
            getAllRepayment?.map((list) => (
              <div className="row m-0 p-0">
                <div className="col-2 due-repayment-cols">
                  Due Date
                  <br />
                  <div className="due-repayment-col-span">
                    {list?.due_date
                      ? moment(list?.due_date).format("MMM Do YY")
                      : ""}
                  </div>
                </div>
                <div className="col-2 due-repayment-cols">
                  Collection Type
                  <br />
                  <div className="due-repayment-col-span">
                    {list?.collection_type ? list?.collection_type : ""}
                  </div>
                </div>

                <div className="col-2 due-repayment-cols">
                  Principal
                  <br />
                  <div className="due-repayment-col-span">
                    ₦
                    {list?.principal_amount
                      ? formatToCurrency(list?.principal_amount)
                      : 0}
                  </div>
                </div>
                <div className="col-2 due-repayment-cols">
                  Interest
                  <br />
                  <div className="due-repayment-col-span">
                    ₦
                    {list?.interest_amount
                      ? formatToCurrency(list?.interest_amount)
                      : 0}
                  </div>
                </div>
                <div className="col-2 due-repayment-cols">
                  Amount
                  <br />
                  <div className="due-repayment-col-span">
                    {" "}
                    ₦ {list?.amount ? formatToCurrency(list?.amount) : 0}
                  </div>
                </div>
                <div className="col-2 repayment-upcoming-button-cover ">
                  <RepaymentButton
                    customWith={"165px"}
                    handlePayRepayment={() =>
                      makeRepayment(list?.amount, list.loan_id)
                    }
                  />
                </div>
              </div>
            ))
          ) : (
            <div
              style={{
                color: "#858585",
                fontSize: "12px",
                marginTop: "30px",
              }}
            >
              You have no repayment this time
            </div>
          )}
        </div>
      </div>

      {/* all repayment for mobile */}
      <div className="repayment-for-mobile">
        <div
          style={{
            fontSize: "16px",
            fontWeight: "700",
            paddingTop: "45px",
            marginBottom: "30px",
          }}
        >
          Repayments
        </div>
        <div>
          <div
            className="row"
            style={{
              background: "#E7FFEE",
              paddingTop: "18px",
              paddingBottom: "22px",
              marginBottom: "16px",
              borderRadius: "11px",
            }}
          >
            <div
              className="col-6"
              style={{
                paddingLeft: "22px",
                fontSize: "12px",
                color: "#858585",
              }}
            >
              Repayment
            </div>
            <div
              className="col-6"
              style={{
                paddingRight: "61px",
                fontSize: "12px",
                color: "#858585",
              }}
            >
              Action
            </div>
          </div>
          {getAllRepayment?.length ? (
            getAllRepayment?.map((list) => (
              <div
                className="row"
                style={{
                  fontSize: "12px",
                  paddingTop: "14px",
                  marginBottom: "5px",
                }}
              >
                <div className="col-3"> {list?.date ? list?.date : ""}</div>
                <div
                  className="col-4"
                  style={{ color: "#858585", paddingBottom: "14px" }}
                >
                  Amount
                  <br />
                  <span style={{ fontWeight: "bold" }}>
                    ₦{list?.amount ? formatToCurrency(list?.amount) : 0}
                  </span>
                </div>
                <div className="col-5">
                  <RepaymentButton
                    title={"Make Payment"}
                    fontSize={"12px"}
                    customWith={"144px"}
                    handlePayRepayment={() =>
                      makeRepayment(list?.amount, list?.loan_id)
                    }
                  />
                </div>
              </div>
            ))
          ) : (
            <div
              style={{
                color: "#858585",
                fontSize: "12px",
                marginTop: "30px",
              }}
            >
              You have no Upcoming repayment this time
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
