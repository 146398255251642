import React from "react";
import "./mobileDashboardCover.css";
import SmallWallet from "../../images/small-wallet-icon.png";
import { formatToCurrency } from "../../utilities/utilities";
import CustomizedProgressBars from "../progressbar/progressBar";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
export default function MobileDashboardCover({ hasPayment, wallet }) {
    const navigate = useNavigate();

    const userDetails = useSelector((state) => state.userDetails);
    const getCurrentLoggedInUser = useSelector(
        (state) => state.getCurrentLoggedInUser
    );
    const handleApplyAndPayment = () => {
        if (!hasPayment) {
            navigate("/loans/select-loan");
        }
    };
    return (
        <div className="mobile-card-cover-div">
            {/* <div style={{ color: "#2A2B50", fontSize: "14px" }}>
        Welcome,{" "}
        <strong>
          {userDetails?.first_name + " " + userDetails.last_name}!
        </strong>
      </div> */}
            <div className="row m-0 p-0">
                <div className="col-2" style={{ paddingTop: "45px" }}>
                    {/* <img src={SmallWallet} alt="logo" /> */}
                    <svg
                        width="35"
                        height="68"
                        viewBox="0 0 68 68"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M59.5002 31.6878V19.8332C59.5002 19.0817 59.2017 18.3611 58.6703 17.8297C58.1389 17.2983 57.4183 16.9998 56.6668 16.9998H11.3335C10.582 16.9998 9.86138 16.7013 9.33003 16.17C8.79867 15.6386 8.50016 14.918 8.50016 14.1665C8.50016 13.4151 8.79867 12.6944 9.33003 12.163C9.86138 11.6317 10.582 11.3332 11.3335 11.3332H56.6668C57.4183 11.3332 58.1389 11.0347 58.6703 10.5033C59.2017 9.97195 59.5002 9.25128 59.5002 8.49984C59.5002 7.74839 59.2017 7.02772 58.6703 6.49637C58.1389 5.96501 57.4183 5.6665 56.6668 5.6665H11.3335C9.07916 5.6665 6.91715 6.56204 5.32309 8.1561C3.72903 9.75016 2.8335 11.9122 2.8335 14.1665V53.8332C2.8335 56.0875 3.72903 58.2495 5.32309 59.8436C6.91715 61.4376 9.07916 62.3332 11.3335 62.3332H56.6668C57.4183 62.3332 58.1389 62.0347 58.6703 61.5033C59.2017 60.972 59.5002 60.2513 59.5002 59.4998V47.6452C61.1564 47.0682 62.592 45.9901 63.6078 44.5604C64.6237 43.1307 65.1695 41.4203 65.1695 39.6665C65.1695 37.9127 64.6237 36.2023 63.6078 34.7726C62.592 33.3429 61.1564 32.2648 59.5002 31.6878ZM53.8335 56.6665H11.3335C10.582 56.6665 9.86138 56.368 9.33003 55.8366C8.79867 55.3053 8.50016 54.5846 8.50016 53.8332V22.1452C9.40756 22.4807 10.3661 22.6571 11.3335 22.6665H53.8335V31.1665H45.3335C43.0792 31.1665 40.9171 32.062 39.3231 33.6561C37.729 35.2502 36.8335 37.4122 36.8335 39.6665C36.8335 41.9208 37.729 44.0829 39.3231 45.6769C40.9171 47.271 43.0792 48.1665 45.3335 48.1665H53.8335V56.6665ZM56.6668 42.4998H45.3335C44.582 42.4998 43.8614 42.2013 43.33 41.67C42.7987 41.1386 42.5002 40.418 42.5002 39.6665C42.5002 38.9151 42.7987 38.1944 43.33 37.663C43.8614 37.1317 44.582 36.8332 45.3335 36.8332H56.6668C57.4183 36.8332 58.1389 37.1317 58.6703 37.663C59.2017 38.1944 59.5002 38.9151 59.5002 39.6665C59.5002 40.418 59.2017 41.1386 58.6703 41.67C58.1389 42.2013 57.4183 42.4998 56.6668 42.4998Z"
                            fill="#2BC155"
                        />
                    </svg>
                </div>
                <div
                    className="col-8"
                    style={{
                        paddingTop: "43px",
                        marginLeft: "10px",
                        fontSize: "14px",
                        color: "black",
                        fontWeight: "500",
                        // width: "100%",
                    }}
                >
                    Main Balance
                    <br />
                    <span style={{ fontSize: "10px", color: "#A6ACBE" }}>
                        Wallet ID: {wallet?.Wallet?.walletId}
                    </span>
                    <br />
                    <span style={{ fontSize: "18px", fontWeight: 600 }}>
                        &#8358;{" "}
                        {wallet?.Wallet?.amount
                            ? formatToCurrency(wallet?.Wallet?.amount)
                            : formatToCurrency(0)}
                    </span>
                </div>
                <div className="col-5" style={{ paddingTop: "38px" }}>
                    {getCurrentLoggedInUser?.user_status?.apply_for_loan ? (
                        <></>
                    ) : (
                        <button
                            className="btn btn-default"
                            style={{
                                width: "100%",
                                fontSize: "14px",
                                border: "1px solid #47B638",
                                color: "#47B638",
                                borderRadius: "30px",
                            }}
                            onClick={handleApplyAndPayment}
                        >
                            Apply Now
                        </button>
                    )}
                </div>
            </div>
            {hasPayment ? (
                <div className="row m-0 p-0">
                    <div
                        className="col-6"
                        style={{
                            textAlign: "right",
                            paddingTop: "13px",
                            paddingBottom: "17px",
                            fontSize: "12px",
                            fontWeight: 500,
                            color: "rgba(0, 0, 0, 0.32)",
                        }}
                    >
                        Apply for a card
                    </div>
                    <div
                        className="col-6"
                        style={{
                            paddingTop: "14px",
                            fontSize: "10px",
                            fontWeight: "500",
                            color: "rgba(0, 0, 0, 0.32)",
                        }}
                    >
                        Next repayment
                        <span
                            style={{
                                marginLeft: "9px",
                                color: "#000000",
                                fontSize: "12px",
                                fontWeight: "500",
                            }}
                        >
                            08/21
                        </span>
                    </div>
                </div>
            ) : (
                <div
                    style={{
                        fontSize: "12px",
                        color: "rgba(0, 0, 0, 0.32)",
                        fontWeight: "500",
                        textAlign: "center",
                        paddingTop: "22px",
                        paddingBottom: "17px",
                    }}
                >
                    {/* You currently have no credit, check if you qualify */}
                </div>
            )}

            <div style={{ paddingBottom: "25px" }}>
                <CustomizedProgressBars />
            </div>
        </div>
    );
}
