import React from "react";
import LandingPageHeader from "../../components/landingHeader/landingPageHeader";
import "./Home.css";
import MobileLogo from "../../images/dizz-logo.svg";
import image from "../../images/DizbuzzHome.png";
import { useNavigate } from "react-router";
import SigninFormComponent from "../../auth/signin/signinFormComponent";
import DibuzzIcon from "../../images/DizbuzzIcon.svg";

function Home() {
  const navigate = useNavigate();

  return (
    <div className="home">
      <div className="homeHeader">
        <div className="homeHeaderLeft">
          <a href="https://dizbuzz.netlify.app/">
            <img src={MobileLogo} alt="logo" className="homeLogo" />
          </a>
        </div>
        <div className="homeHeaderRight">
          <span className="homeHeaderLogin" onClick={() => navigate("/login")}>
            Login
          </span>
          <div
            className="homeHeaderGetStarted"
            onClick={() => navigate("/register")}
          >
            Get Started
          </div>
        </div>
      </div>
      <div className="homeContent">
        <div className="homeContentLeft">
          <img src={image} alt="" className="homeImage" />
        </div>
        <div className="homeContentRight">
          <span className="homeFormHeader">Member Sign-In</span>
          <span className="homeFormHeaderDescription">
            Sign into your Dizbuzz account
          </span>
          <SigninFormComponent />
        </div>
      </div>
      <div className="homeMobile">
        <div className="homeMobileLogoContainer">
          <a href="https://dizbuzz.netlify.app/">
            <img src={DibuzzIcon} alt="" className="homeMobileLogo" />
          </a>
        </div>
        <span className="homeMobileWelcome">Welcome</span>
        <span className="homeMobileWelcomeDescription">
          Sign into your Dizbuzz account
        </span>
        <div className="homeMobileFormContaienr">
          <SigninFormComponent />
        </div>
      </div>
    </div>
  );
}

export default Home;
