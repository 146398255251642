import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import AuthForm from "../../auth/authForm/authForm";
import PrimaryButton from "../../components/primaryButton/primaryButton";
import TitleComponentHeader from "../../components/titleComponent/TitleComponent";
import services from "../../services/services";
// import "./changePassword.css";
export default function VerifyEmail() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleSubmit = () => {
        navigate("/login");
    };
    const [searchParams, setSearchParams] = useSearchParams();
    const verifiedEmail = searchParams.get("email");
    const verificationOtp = searchParams.get("otp");
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        let errorMessage = {
            message: "",
            status: false,
        };
        if (verifiedEmail && verificationOtp) {
            setLoading(true);
            const data = {
                otp: verificationOtp,
            };
            const verifyEmailRoute = new services().patchServices(
                "auth/customer/verify",
                data
            );

            verifyEmailRoute
                .then((res) => {
                    setLoading(false);
                    if (res.errorMessage) {
                        let errorMessage = {
                            message: res.errorMessage,
                            status: false,
                        };

                        dispatch({ type: "ERROR_MESSAGE", data: errorMessage });
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    console.log(error);
                });
        }
        return dispatch({ type: "ERROR_MESSAGE", data: errorMessage });
    }, []);
    return (
        <div className="change-password-cover">
            <div style={{ marginBottom: "50px" }}>
                <TitleComponentHeader hasGoBack title={"Verify your email"} />
            </div>
            <div>
                <div
                    className="col-md-5 col-12"
                    style={{ marginBottom: "24px" }}
                ></div>
                {verifiedEmail ? (
                    <>
                        <div className="dispay-text" style={{ marginBottom: "24px" }}>
                            {loading
                                ? "Verifying your email"
                                : "You have successfully verified your Email"}

                        </div>
                        <PrimaryButton
                            text={"continue"}
                            buttonWith={"261px"}
                            handleClickButton={handleSubmit}
                            loading={loading}
                        />
                    </>
                ) : (
                    <div className="dispay-text">
                        Hi there, We have sent a verification link to your email. Kindly check your email and click on the verification link.
                    </div>
                )}
            </div>
        </div>
    );
}
