import React, { useState } from "react";
import AuthForm from "../authForm/authForm";
import Radio from "@mui/material/Radio";
import Arrow from "../../images/arrow-button.png";
import "./signup.css";
import { useNavigate } from "react-router-dom";
import {
  emailValidation,
  setDateToLocalStorage,
} from "../../utilities/utilities";
import services, { postService } from "../../services/services";
import MessageComponent from "../../components/messageComponent/messageComponent";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import RequestWaitButton from "../../components/requestWaitButton/requestWaitButton";
import TitleComponentHeader from "../../components/titleComponent/TitleComponent";
import FormTitleDiv from "../../components/FormTitleDiv";
export default function SignUpFormComponent() {
  const navigation = useNavigate();
  const loadState = useSelector((state) => state.loadState);
  const dispatch = useDispatch();
  const [userDetails, setuserDetails] = useState({
    first_name: "",
    last_name: "",
    username: "",
    email: "",
    password: "",
    phone: "",
  });
  const [emailChecker, setemailChecker] = useState(false);
  const [agreement, setagreement] = useState(false);
  const [MessageDetails, setMessageDetails] = useState({
    message: "",
    status: false,
  });
  const [passwordType, setPasswordType] = React.useState("password");

  const handleType = () => {
    setPasswordType((prevValue) =>
      prevValue == "password" ? "text" : "password"
    );
  };
  const handleInputChange = (value, attribute) => {
    setuserDetails({
      ...userDetails,
      [attribute]: value,
    });
    if (attribute === "email") {
      setemailChecker(emailValidation(value));
    }
  };
  const handleChecked = (value) => {
    setagreement(true);
  };

  const handleContinue = async () => {

    dispatch({ type: "LOAD_STATE", data: true });
    if (
      userDetails.first_name === "" ||
      userDetails.last_name === "" ||
      userDetails.password === "" ||
      userDetails.phone === "" ||
      userDetails.email === ""
    ) {
      setMessageDetails({
        message: "Please fill out all empty fields.",
        status: false,
      });
      dispatch({ type: "LOAD_STATE", data: false });
    } else if (userDetails.password.length < 6) {
      dispatch({ type: "LOAD_STATE", data: false });
      setMessageDetails({
        message: "Password must be at least 6 characters.",
        status: false,
      });
    } else if (!emailChecker) {
      dispatch({ type: "LOAD_STATE", data: false });
      setMessageDetails({
        message: "Enter a valid email address.",
        status: false,
      });
    } else {
      const getresponse = new services().postService(
        "auth/signup",
        userDetails
      );

      getresponse
        .then((data) => {
          dispatch({ type: "LOAD_STATE", data: false });

          if (data.errorMessage) {
            setMessageDetails({
              message: data.errorMessage,
              status: false,
            });
          } else {
            setTimeout(() => {
              window.location.href = "/verify-email";
              setMessageDetails({
                message: "",
                status: false,
              });
            }, 500);

            // setDateToLocalStorage(data.data);
            setMessageDetails({
              message: "Your registration was successful.",
              status: true,
            });
          }
        })
        .catch((error) => {
          dispatch({ type: "LOAD_STATE", data: false });
          // console.log("Network request failed");
        });
    }
  };

  return (
    <div>
      <div className="row">
        <div className="col-lg-6 col-12 form-component-cover">
          <FormTitleDiv
            text={"First Name"}
            fontSize={"18px"}
            color={"#565656"}
            marginBottom={"15px"}
          />
          <AuthForm
            isValid={userDetails.first_name ? true : false}
            type={"text"}
            right={"16px"}
            top={"14px"}
            handleChange={(evt) =>
              handleInputChange(evt.target.value, "first_name")
            }
          />
        </div>
        <div className="col-lg-6 col-12 form-component-cover">
          <FormTitleDiv
            text={"Last Name"}
            fontSize={"18px"}
            color={"#565656"}
            marginBottom={"15px"}
          />
          <AuthForm
            isValid={userDetails.last_name ? true : false}
            type={"text"}
            right={"16px"}
            top={"14px"}
            handleChange={(evt) =>
              handleInputChange(evt.target.value, "last_name")
            }
          />
        </div>
        <div className="col-lg-6 col-12 form-component-cover">
          <FormTitleDiv
            text={"Username"}
            fontSize={"18px"}
            color={"#565656"}
            marginBottom={"15px"}
          />
          <AuthForm
            isValid={userDetails.username ? true : false}
            placeholder={""}
            type={"text"}
            right={"16px"}
            top={"14px"}
            handleChange={(evt) =>
              handleInputChange(evt.target.value, "username")
            }
          />
        </div>
        <div className="col-lg-6 col-12 form-component-cover">
          <FormTitleDiv
            text={"Password"}
            fontSize={"18px"}
            color={"#565656"}
            marginBottom={"15px"}
          />
          <AuthForm
            isValid={userDetails.password.length >= 6 ? true : false}
            placeholder={""}
            type={passwordType}
            handleType={handleType}
            isPassword
            right={"16px"}
            top={"14px"}
            handleChange={(evt) =>
              handleInputChange(evt.target.value, "password")
            }
          />
        </div>
        <div className="col-lg-6 col-12 form-component-cover" type={"email"}>
          <FormTitleDiv
            text={"Email"}
            fontSize={"18px"}
            color={"#565656"}
            marginBottom={"15px"}
          />
          <AuthForm
            isValid={emailChecker}
            placeholder={""}
            right={"16px"}
            top={"14px"}
            handleChange={(evt) => handleInputChange(evt.target.value, "email")}
          />
        </div>
        <div className="col-lg-6 col-12 form-component-cover">
          <FormTitleDiv
            text={"Phone Number"}
            fontSize={"18px"}
            color={"#565656"}
            marginBottom={"15px"}
          />
          <AuthForm
            isValid={userDetails.phone.length === 11 ? true : false}
            placeholder={""}
            type={"text"}
            right={"16px"}
            top={"14px"}
            handleChange={(evt) => handleInputChange(evt.target.value, "phone")}
          />
        </div>
      </div>
      {/* <div className="agreement-div">
        <span>
          <Radio
            checked={agreement}
            onChange={(evt) => handleChecked(evt.target.checked)}
          />
        </span>
        By creating an account, you aggree to our{" "}
        <span style={{ color: "#73c39d" }}>Term and Conditions</span> and
        <span style={{ color: "#73c39d" }}> Privacy Policy</span>
      </div> */}
      {MessageDetails.message ? (
        <MessageComponent messageDetails={MessageDetails} />
      ) : (
        ""
      )}

      <div className="desktop-submit-button-div">
        <div style={{ position: "relative" }}>
          {loadState ? (
            <div style={{ marginTop: "30px" }}>
              <RequestWaitButton
                text={"Register"}
                customBackground={"#47B638"}
                opacity={0.15}
                customHeight={"55px"}
                customPaddingBottom={"19px"}
                customPaddingTop={"19px"}
                customColor={"white"}
                customWith={"95%"}
                customBorderRadius={"0px"}
              />
            </div>
          ) : (
            <button
              className="btn btn-default desktop-submit-button"
              onClick={handleContinue}
            >
              Register
              {/* <img
                src={Arrow}
                alt="arrow"
                style={{ position: "absolute", left: "90%" }}
              /> */}
            </button>
          )}
        </div>
      </div>
      <div className="mobile-submit-button-div">
        <div style={{ position: "relative" }}>
          <button
            className="btn btn-default mobile-submit-button"
            onClick={handleContinue}
          >
            Sign in
          </button>
        </div>
      </div>
      <div
        style={{
          fontSize: "14px",
          color: "#8B8B8B",
          letterSpacing: " 0.531818px",
          marginTop: "23px",
          textAlign: "center",
        }}
      >
        Have an account?{" "}
        <span
          className="hav-an-account-span"
          style={{ color: "#2BC155", fontWeight: 800, cursor: "pointer" }}
          onClick={() => navigation("/login")}
        >
          Login
        </span>
      </div>
    </div>
  );
}
