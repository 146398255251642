import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AuthForm from "../../auth/authForm/authForm";
import MessageComponent from "../../components/messageComponent/messageComponent";
import PrimaryButton from "../../components/primaryButton/primaryButton";
import TitleComponentHeader from "../../components/titleComponent/TitleComponent";
import services from "../../services/services";
import "./changePassword.css";
export default function ChangePassword() {
  const dispatch = useDispatch();
  const [loading, setloading] = useState(false);
  const userDetails = useSelector((state) => state.userDetails);
  const globalErrorMessage = useSelector((state) => state.globalErrorMessage);
  const [passwordDetails, setpasswordDetails] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const handleSubmit = () => {
    setloading(true);
    if (
      passwordDetails.currentPassword === "" ||
      passwordDetails.newPassword === "" ||
      passwordDetails.confirmPassword === ""
    ) {
      setloading(false);
      let errorMessage = {
        message: "Please fill out empty fields.",
        status: false,
      };

      dispatch({ type: "ERROR_MESSAGE", data: errorMessage });
    } else if (
      passwordDetails.newPassword !== passwordDetails.confirmPassword
    ) {
      setloading(false);
      let errorMessage = {
        message: "Password does nt match.",
        status: false,
      };

      dispatch({ type: "ERROR_MESSAGE", data: errorMessage });
    } else {
      let data = {
        current_password: passwordDetails.currentPassword,
        new_password: passwordDetails.newPassword,
      };
      const sendRequestForNewpassword = new services().postServiceWithToken(
        "auth/change_password",
        data,
        userDetails.token
      );

      sendRequestForNewpassword
        .then((res) => {
          setloading(false);
          if (res.errorMessage) {
            let errorMessage = {
              message: res.errorMessage,
              status: false,
            };

            dispatch({ type: "ERROR_MESSAGE", data: errorMessage });
          } else {
            let successMessage = {
              message: "Password Change successful.",
              status: true,
            };

            dispatch({ type: "ERROR_MESSAGE", data: successMessage });
            setpasswordDetails({
              currentPassword: "",
              newPassword: "",
              confirmPassword: "",
            });
          }
        })
        .catch((error) => {
          setloading(false);
          console.log(error);
        });
    }
  };
  useEffect(() => {
    let errorMessage = {
      message: "",
      status: false,
    };
    return dispatch({ type: "ERROR_MESSAGE", data: errorMessage });
  }, []);
  return (
    <div className="change-password-cover">
      <div style={{ marginBottom: "50px" }}>
        <TitleComponentHeader hasGoBack title={"Edit Password"} />
      </div>
      <div>
        <div className="col-md-5 col-12" style={{ marginBottom: "24px" }}>
          <AuthForm
            value={passwordDetails.currentPassword}
            noCheck
            hasLabel
            placeholder={"Enter your current password"}
            label={"current password"}
            type={"password"}
            handleChange={(evt) =>
              setpasswordDetails({
                ...passwordDetails,
                currentPassword: evt.target.value,
              })
            }
          />
        </div>
        <div className="col-md-5 col-12" style={{ marginBottom: "24px" }}>
          <AuthForm
            noCheck
            hasLabel
            value={passwordDetails.newPassword}
            placeholder={"Enter new password"}
            label={"New password  "}
            type={"password"}
            handleChange={(evt) =>
              setpasswordDetails({
                ...passwordDetails,
                newPassword: evt.target.value,
              })
            }
          />
        </div>
        <div className="col-md-5 col-12" style={{ marginBottom: "24px" }}>
          <AuthForm
            value={passwordDetails.confirmPassword}
            noCheck
            hasLabel
            placeholder={"Confirm new password"}
            label={"confirm New password"}
            type={"password"}
            handleChange={(evt) =>
              setpasswordDetails({
                ...passwordDetails,
                confirmPassword: evt.target.value,
              })
            }
          />
        </div>
      </div>
      {globalErrorMessage.message ? (
        <div style={{ marginTop: "20px" }}>
          <MessageComponent messageDetails={globalErrorMessage} />
        </div>
      ) : (
        ""
      )}
      <div style={{ paddingTop: "40px", paddingBottom: "100px" }}>
        <PrimaryButton
          text={"Save"}
          buttonWith={"261px"}
          handleClickButton={handleSubmit}
          loading={loading}
        />
      </div>
    </div>
  );
}
