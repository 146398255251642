import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import DashboardCard from "../../components/dashboardCard/dashboardCard";
import MobileDashboardCover from "../../components/mobileDashboardCard/mobileDashboardCover";
import TitleComponentHeader from "../../components/titleComponent/TitleComponent";
import ViewLoanMobileTable from "../../components/viewLoanTableComponent/viewLoanMobileTable";
import ViewLoanTableComponent from "../../components/viewLoanTableComponent/viewLoanTableComponent";
import services from "../../services/services";
import LoanHistoryDetails from "./loanHistory-details";
import "./ViewExistingLoan.css";

export default function ViewExistingLoan() {
    const userDetails = useSelector((state) => state.userDetails);
    const getExistingLoan = useSelector((state) => state.getExistingLoan);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    useEffect(() => {
        const getLoanFromServer = new services().getService(
            "loan",
            userDetails.token
        );

        getLoanFromServer
            .then((res) => {
                if (res.errorMessage) {
                    dispatch({ type: "GET_EXISTING_LOAN_DETAILS", data: [] });
                } else {
                    dispatch({
                        type: "GET_EXISTING_LOAN_DETAILS",
                        data: res.data,
                    });
                }
            })
            .catch((error) => {
                console.log("an error occur,please check your network");
            });
    }, []);
    const [hasPayment, sethasPayment] = useState(false);
    const [wallet, setwallet] = useState({});
    useEffect(() => {
        const getWalletBallance = new services().getService(
            "wallet/amount",
            userDetails.token
        );
        getWalletBallance
            .then((res) => {
                if (res?.errorMessage) {
                    setwallet({});
                } else {
                    setwallet(res?.data);

                    if (res?.data?.Wallet?.amount === 0) {
                        sethasPayment(false);
                    } else {
                        sethasPayment(true);
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    return (
        <div
            className="existing-loan-cover"
            onClick={() => dispatch({ type: "TOGGLE_MENU", data: false })}
        >
            <div style={{ marginTop: "50px" }}>
                <DashboardCard
                    notHomePage
                    hasPayment={hasPayment}
                    wallet={wallet}
                />
                <MobileDashboardCover hasPayment={hasPayment} wallet={wallet} />
            </div>
            <div className="desktop-view-for-existing-loan">
                {getExistingLoan.length ? (
                    <div className="existing-loan-title">
                        <TitleComponentHeader
                            title={"Loan History"}
                            subTitle={"Summary of all your loans"}
                        />
                    </div>
                ) : (
                    ""
                )}

                <div className="view-loan-list-cover">
                    <ViewLoanTableComponent getExistingLoan={getExistingLoan} />
                </div>
            </div>
            <div className="mobile-view-for-existing-loan">
                {getExistingLoan.length ? (
                    <div className="mobile-existing-loan-title">
                        <TitleComponentHeader
                            title={"Loan History"}
                            customFontSize={"16px"}
                        />
                    </div>
                ) : (
                    ""
                )}

                <div className="mobile-view-loan-list-cover">
                    {getExistingLoan.length ? (
                        <div className="mobile-view-list-existing-loan">
                            {getExistingLoan?.map((list, index) => (
                                <ViewLoanMobileTable
                                    getExistingLoan={list}
                                    index={index}
                                />
                            ))}
                        </div>
                    ) : (
                        <div
                            style={{
                                textAlign: "center",
                                color: "#858585",
                                fontSize: "14px",
                                marginTop: "135px",
                            }}
                        >
                            You have no existing loan. Apply now for a loan.
                            <div>
                                <button
                                    style={{
                                        width: "261px",
                                        paddingTop: "15px",
                                        paddingBottom: "15px",
                                        background: "#47B638 ",
                                        border: "none",
                                        borderRadius: "26px",
                                        color: "white",
                                        marginTop: "57px",
                                    }}
                                    onClick={() =>
                                        navigate("/loans/select-loan")
                                    }
                                >
                                    Apply now{" "}
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
