import React from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import TitleComponentHeader from "../../components/titleComponent/TitleComponent";
import ViewLoanMobileTable from "../../components/viewLoanTableComponent/viewLoanMobileTable";
import services from "../../services/services";
import { formatToCurrency } from "../../utilities/utilities";
import "./loanHistoryDetails.css";
export default function LoanHistoryDetails({ details }) {
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.userDetails);

  const handlePay = (amount) => {
    dispatch({ type: "SHOW_SPINNER", data: true });
    // console.log(loanId);
    let data = {
      loan_id: details?.id,
      amount: amount,
    };
    const postRepayment = new services().postServiceWithToken(
      "repayments/make-repayment",
      data,
      userDetails.token
    );
    postRepayment
      .then((res) => {
        if (res.errorMessage) {
          let errorMessage = {
            message: res.errorMessage,
            status: false,
          };

          dispatch({ type: "ERROR_MESSAGE", data: errorMessage });
        } else {
          let errorMessage = {
            message: "Repayment successful",
            status: true,
          };

          dispatch({ type: "ERROR_MESSAGE", data: errorMessage });
        }
        dispatch({ type: "SHOW_SPINNER", data: false });

        dispatch({ type: "SHOW_TOAST", data: true });
      })
      .catch((error) => {
        dispatch({ type: "SHOW_SPINNER", data: false });
        console.log(error);
      });
  };
  return (
    <div
      className="mobile-loan-details-cover"
      style={{ paddingLeft: "24px", paddingRight: "35px" }}
    >
      <div className="row p-0 m-0">
        <div className="col-8 p-0 m-0">
          <div className="loan-id-div">Loan ID</div>
          <div className="loan-name-div">{details?.type}</div>
          <div className="loan-code-div">{details?.loan_acct_id}</div>
        </div>
        <div className="col-4 amount-div p-0 m-0">
          ₦
          {details?.initial_principal
            ? formatToCurrency(details?.initial_principal)
            : formatToCurrency(0)}
        </div>
      </div>
      <div className="repayment-details-div">
        Repayment <br />
        <span className="repayment-details-span">
          {" "}
          ₦
          {details?.initial_principal
            ? formatToCurrency(details?.initial_principal)
            : formatToCurrency(0)}{" "}
          {details?.repay_cycle}
        </span>
      </div>
      <div className="repayment-details-div">
        Loan Duration <br />
        <span className="repayment-details-span">
          {details?.loan_duration + " " + details?.loan_duration_period}
        </span>
      </div>
      {details.status === "processing" ? (
        <div className="row m-0 p-0 loan-details-payment-and-title-div">
          {/* <div className="col-8 p-0 m-0 next-payment-details-div">
            Next repayment
            <br />
            <span className="next-payment-details-div-span">12/01/2022</span>
          </div> */}
          <div className="col-4 p-0 m-0 next-payment-button-div">
            <button
              style={{
                border: "none",
                background: "#47B638 ",
                color: "white",
                borderRadius: "11px",
                width: "91px",
                height: "39px",
                fontSize: "12px",
              }}
              onClick={() => handlePay(details?.initial_principal)}
            >
              Pay
            </button>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
