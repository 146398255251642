import { combineReducers } from "redux";
import BorrowLoanSchedule from "../../screen/borrowLoanSchedule/borrowLoanSchedul";
import { getAllRepaymentAction } from "../allRepayment/allRepaymentAction";
import { getBvnRecordAction } from "../bvnRecords/bvnRecordAction";
import { getEmploymentDetailsAction } from "../employmentDetails/getEmploymentDetails";
import { getBorrowerAction } from "../getBorrower/getBorrower";
import { getCustomersAction } from "../getCustomers/getCustomerAction";
import { getExistingLoanAction } from "../getExistingLoan/existingLoan";
import { getLoanIdAction } from "../getLoanId/loanId";
import { globalErrorMessageAction } from "../globalErrorMessage/globalErrorMessage";
import { guarantorEmploymentDetailsAction } from "../guarantorEmploymentDetails/guarantorEmploymentDetails";
import { getIndividualLoanAction } from "../individualLoan/individualLoan";
import { loaderAction } from "../loader/loader";
import { loanPaymentSuccessAction } from "../loanPaymentSuccessChecker/loanPaymentSuccessChecker";
import { loanProductAction } from "../loanProduct/loanProductAction";
import { loanProductSelectedAction } from "../loanProductSelectedOption/loanProductSelectObject";
import { loaScheduleAction } from "../loanScheduleAction/loanScheduleAction";
import { getCurrentLoggedInUserAction } from "../loggedinUser/getLogedInUser";
import { mobileMenuIconReducer } from "../mobileMenuAction/mobileMenuAction";
import { getNextRepaymentAction } from "../nextRepayment/nextRepayment";
import { getLoanDetailsOpenStateAction } from "../openLoanDetails/openLoanDetails";
import { getRepaymentAction } from "../repayments/getRepayment";
import { setBorrowerDetailsAction } from "../setBrrowerDetails/setBorrowerDetails";
import { setEmploymentDetailsAction } from "../setEmploymentDetails/setEmploymentDetails";
import { getShowSpinnerAction } from "../showSpinner/showSpinner";
import { showToastStateAction } from "../toastState/toastStateReducer";
import { userDetailsAction } from "../userDetailsAction/userDetailsAction";
import { getListOfWorkStatusAction } from "../workStatus/getListOfWorkStatus";

const rootReducer = combineReducers({
  mobileMenuToggle: mobileMenuIconReducer,
  loadState: loaderAction,
  userDetails: userDetailsAction,
  laonProducts: loanProductAction,
  loanProductSlected: loanProductSelectedAction,
  getBorrowerDetails: getBorrowerAction,
  globalErrorMessage: globalErrorMessageAction,
  getBvnRecordDetails: getBvnRecordAction,
  getCurrentLoggedInUser: getCurrentLoggedInUserAction,
  setBorrowerDetails: setBorrowerDetailsAction,
  getEmploymentDetails: getEmploymentDetailsAction,
  setEmploymentDetails: setEmploymentDetailsAction,
  getExistingLoan: getExistingLoanAction,
  loanPaymentSuccess: loanPaymentSuccessAction,
  getListOfWorkStatus: getListOfWorkStatusAction,
  loaSchedule: loaScheduleAction,
  getShowSpinner: getShowSpinnerAction,
  showToastState: showToastStateAction,
  getRepayment: getRepaymentAction,
  getIndividualLoan: getIndividualLoanAction,
  getLoanDetailsOpenState: getLoanDetailsOpenStateAction,
  getNextRepayment: getNextRepaymentAction,
  getAllRepayment: getAllRepaymentAction,
  getLoanId: getLoanIdAction,
  getCustomers: getCustomersAction,
  guarantorEmploymentDetails: guarantorEmploymentDetailsAction,
});
export default rootReducer;
