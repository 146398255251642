import React from "react";
import "./buttonWithChecker.css";
import Checked from "../../images/valid.png";
import NotChecked from "../../images/invalid.png";
export default function ButtonWithChecker({
  buttonWith,
  text,
  isValid,
  right,
  handleClick,
}) {
  return (
    <div style={{ position: "relative", width: buttonWith }}>
      <div style={{ position: "absolute", right, top: "14px" }}>
        <img src={isValid ? Checked : NotChecked} alt="checked" />
      </div>
      <button
        className="btn btn-default"
        style={{
          background: "#2BC155",
          width: buttonWith,
          borderRadius: "26px",
          height: "54px",
          color: "white",
          fontSize: "16px",
        }}
        onClick={handleClick}
      >
        {text}
      </button>
    </div>
  );
}
