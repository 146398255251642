import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import LoanMobileTitleComponent from "../../../components/loanMobileTitleComponent/loanMobileTitleComponent";
import MessageComponent from "../../../components/messageComponent/messageComponent";
import NormalInputForm from "../../../components/normalInputForm/normalInputForm";
import PrimaryButton from "../../../components/primaryButton/primaryButton";
import SelectLoanCardComponent from "../../../components/select-loan-card/select-loan-card-component";
import SelectMobileLoanCardComponent from "../../../components/select-loan-card/select-loan-mobile";
import SelectInput from "../../../components/selectInput/selectInput";
import services from "../../../services/services";
import {
  formatToCurrency,
  getAppDataFromLocalStorage,
  setAppDataToLocalStorage,
} from "../../../utilities/utilities";
import GoogleAutocomplete from "../../autoCompletePlace/googleAutocomplete";
import "./selectLoan.css";
export default function SelectLoanProduct() {
  const userDetails = useSelector((state) => state.userDetails);
  const laonProducts = useSelector((state) => state.laonProducts);
  const loanProductSlected = useSelector((state) => state.loanProductSlected);
  const navigation = useNavigate();
  const dispatch = useDispatch();

  const [getActiveProduct, setgetActiveProduct] = useState(0);
  const [getRecyclePayment, setgetRecyclePayment] = useState([]);
  const [getAllSelectProductValue, setgetAllSelectProductValue] = useState({
    enjoyNowCredit: "",
    loan_duration: "",
    repaymentCycle: "",
    minimum_principal: "0",
    loan_duration_period: "",
    loanCategory: "",
  });

  const [categoryType, setcategoryType] = useState([
    { id: 1, name: "INDIVIDUAL" },
    { id: 2, name: "CORPORATE" },
  ]);
  const [loanDurationCycle, setLoanDurationCycle] = useState([
    { id: 1, name: 1 },
    { id: 2, name: 2 },
    { id: 3, name: 3 },
    { id: 4, name: 4 },
    { id: 5, name: 5 },
    { id: 6, name: 6 },
    { id: 7, name: 7 },
    { id: 8, name: 8 },
    { id: 9, name: 9 },
    { id: 10, name: 10 },
    { id: 11, name: 11 },
    { id: 12, name: 12 },
  ]);
  const [loanDurationSelected, setLoanDurationSelected] = useState("");
  const [MessageDetails, setMessageDetails] = useState({
    status: false,
    message: "",
  });
  const [buttonStateChanger, setbuttonStateChanger] = useState(false);
  const [getPendingloan, setgetPendingloan] = useState(false);
  const [categorySelected, setcategorySelected] = useState("");
  useEffect(() => {
    if (categorySelected) {
      setgetAllSelectProductValue({
        ...getAllSelectProductValue,
        loanCategory: categorySelected,
      });
    }
  }, [categorySelected]);
  useEffect(() => {
    if (loanDurationSelected) {
      setgetAllSelectProductValue({
        ...getAllSelectProductValue,
        loan_duration: loanDurationSelected,
      });
    }
  }, [loanDurationSelected]);

  useEffect(() => {
    if (categorySelected) {
      const getresponse = new services().getService(
        "loan/loan-product",
        userDetails.token
      );
      getresponse
        .then((res) => {
          if (res.errorMessage) {
            let data = [];
            dispatch({ type: "LOAN_PRODUCT", data });
          } else {
            let getLoanCategory = res?.data?.filter(
              (list) => list?.category[0] === categorySelected
            );

            if (getLoanCategory.length) {
              dispatch({ type: "LOAN_PRODUCT", data: getLoanCategory });
            } else {
              setMessageDetails({
                status: false,
                message: "no available loan products.",
              });

              dispatch({ type: "LOAN_PRODUCT", data: [] });
            }
          }
        })
        .catch((error) => {
          let data = [];
          dispatch({ type: "LOAN_PRODUCT", data });
          setMessageDetails({
            status: false,
            message: "an error occur,check your network.",
          });
        });
    }
  }, [categorySelected]);
  useEffect(() => {
    let data = {
      principal: parseInt(
        getAllSelectProductValue?.minimum_principal.toString().replace(/,/g, "")
      ),
      interest: getAllSelectProductValue?.getAllClickedObjectProduct?.interest,
      loan_duration: getAllSelectProductValue?.loan_duration,
      method:
        getAllSelectProductValue?.getAllClickedObjectProduct?.interest_method,
      interest_period:
        getAllSelectProductValue?.getAllClickedObjectProduct?.interest_period,
      repay_cycle: getAllSelectProductValue?.repaymentCycle,
      loan_period: getAllSelectProductValue.loan_duration_period,
    };
    if (getAllSelectProductValue.repaymentCycle) {
      setbuttonStateChanger(true);
      const getLoanCalculatorResponse = new services().postServiceWithToken(
        "repayments/calculator",
        data,
        userDetails.token
      );
      getLoanCalculatorResponse
        .then((res) => {
          setbuttonStateChanger(false);
          if (res.errorMessage) {
            setgetActiveProduct(0);
          } else {
            setgetActiveProduct(res?.data[0].total_payment);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [
    getActiveProduct,
    getAllSelectProductValue.repaymentCycle,
    getAllSelectProductValue.loan_duration,
    getAllSelectProductValue.minimum_principal,
  ]);

  console.log(getPendingloan);
  const handleClickButton = () => {
    setbuttonStateChanger(true);
    if (getPendingloan) {
      setMessageDetails({
        status: false,
        message: "You have a pending loan request.",
      });
      setbuttonStateChanger(false);
    } else if (categorySelected === "") {
      setMessageDetails({
        status: false,
        message: "Please select a loan category",
      });
      setbuttonStateChanger(false);
    } else if (
      getRecyclePayment.length === 0 &&
      Object.entries(getActiveProduct).length === 0
    ) {
      // dispatch({ type: "SHOW_TOAST", data: true });
      setMessageDetails({
        status: false,
        message: "Please select a product loan",
      });
    } else if (getAllSelectProductValue.repaymentCycle === "") {
      setbuttonStateChanger(false);
      setMessageDetails({
        status: false,
        message: "Please select all required options ",
      });
    } else {
      setTimeout(() => {
        setbuttonStateChanger(false);
        dispatch({
          type: "LOAN_PRODUCT_SELECTED",
          data: getAllSelectProductValue,
        });
        navigation("/loans/bvn-identification");
        setMessageDetails({
          status: false,
          message: "",
        });
      }, 700);

      setMessageDetails({
        status: true,
        message: "Loan product successfully created.",
      });
      dispatch({
        type: "LOAN_PRODUCT_SELECTED",
        data: getAllSelectProductValue,
      });
    }
  };
  const onChange = (evt) => {
    let input = evt.target.value;
    input = input.replace(/[\D\s\._\-]+/g, "");
    input = input ? parseInt(input, 10) : 0;
    setgetAllSelectProductValue({
      ...getAllSelectProductValue,
      minimum_principal: input === 0 ? "" : input.toLocaleString("en-US"),
    });
  };

  const getClickId = (data) => {
    const getAllClickedObjectProduct = laonProducts.find(
      (list) => list.id === data
    );
    setgetActiveProduct(0);

    setgetAllSelectProductValue({
      ...getAllSelectProductValue,
      minimum_principal: getAllClickedObjectProduct.minimum_principal,
      loan_duration: getAllClickedObjectProduct.loan_duration,
      loan_duration_period: getAllClickedObjectProduct.loan_duration_period,
      getAllClickedObjectProduct,
    });

    setgetRecyclePayment(getAllClickedObjectProduct.repay_cycle);
  };

  useEffect(() => {
    const pendingLoanChecker = new services().getService(
      `loan`,
      userDetails.token
    );
    pendingLoanChecker
      .then((res) => {
        const getPendingLoan = res.data.find(
          (loan) => loan.status === "pending"
        );

        if (getPendingLoan) {
          setgetPendingloan(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div className="select-loan-cover">
      <div className="select-loan-title">
        <span className="select-loan-title-text">

        Select loan type:
        </span>
        <div style={{ marginBottom: "12px" }} className="input-div-cover">
          <SelectInput
            placeholder={"Select a loan type"}
            customWidth={"70%"}
            dropdownValue={categoryType}
            labelTitle={"Is this an Individual or Corporate loan"}
            isLoanCategory
            optionValue={categorySelected}
            handleChange={(evt) => setcategorySelected(evt.target.value)}
          />
        </div>
      </div>
      {laonProducts?.length ? (
        <div>
          <div className="select-loan-title">Select a loan product</div>

          {/* <LoanMobileTitleComponent
            title={"Kindly choose a loan product to continue"}
          /> */}

          <div className="select-loan-card-div">
            <div className="row m-0 p-0">
              <SelectLoanCardComponent
                data={laonProducts}
                handleGetId={getClickId}
              />
            </div>
          </div>
          <div className="select-loan-mobile-card-div">
            <div style={{ fontSize: "14px", paddingBottom: "22px" }}>
              Kindly choose a loan product to continue
            </div>
            <div className="row m-0 p-0">
              <SelectMobileLoanCardComponent
                data={laonProducts}
                handleGetId={getClickId}
              />
            </div>
          </div>

          <div className="row m-0 p-0">
            <div className="col-lg-9 col-12 select-loan-form-cover">
              <div style={{ marginBottom: "2px" }} className="input-div-cover">
                {/* <SelectInput
        selectLoanChecker={getRecyclePayment.length ? false : true}
        placeholder={"Enjoynow Credit"}
        customWidth={"95%"}
        dropdownValue={creditValue}
        labelTitle={"How much is your monthly income"}
        isAmount
        optionValue={getAllSelectProductValue.enjoyNowCredit}
        handleChange={(evt) =>
          setgetAllSelectProductValue({
            ...getAllSelectProductValue,
            enjoyNowCredit: evt.target.value,
          })
        }
      /> */}
                <NormalInputForm
                  selectLoanChecker={
                    !getAllSelectProductValue?.minimum_principal.length
                      ? false
                      : true
                  }
                  customWidth={"95%"}
                  placeholder={
                    getAllSelectProductValue?.getAllClickedObjectProduct
                      ?.product_name
                  }
                  labelTitle={"Loan Amount"}
                  value={getAllSelectProductValue?.minimum_principal}
                  handleChange={(e) => {
                    onChange(e);
                  }}
                />
              </div>
              <div style={{ marginBottom: "2px" }} className="input-div-cover">
                <SelectInput
                  selectLoanChecker={
                    getAllSelectProductValue?.loan_duration.length
                      ? false
                      : true
                  }
                  placeholder={"Select a loan duration"}
                  customWidth={"95%"}
                  dropdownValue={loanDurationCycle}
                  isLoanDuration
                  labelTitle={"Loan Duration"}
                  optionValue={loanDurationSelected}
                  handleChange={(evt) =>{

                    console.log(evt.target.value)
                    setLoanDurationSelected(evt.target.value)
                  }
                  }
                />
                {/* <NormalInputForm
                  customWidth={"95%"}
                  placeholder={"Months"}
                  labelTitle={"Loan Duration"}
                  selectLoanChecker={
                    !getAllSelectProductValue?.loan_duration.length
                      ? false
                      : true
                  }
                  value={
                    Object.entries(getAllSelectProductValue).length === 0
                      ? ""
                      : getAllSelectProductValue?.loan_duration +
                        " " +
                        getAllSelectProductValue?.loan_duration_period
                  }
                /> */}
              </div>
              <div style={{ marginBottom: "2px" }} className="input-div-cover">
                <SelectInput
                  selectLoanChecker={
                    getAllSelectProductValue?.repaymentCycle.length
                      ? false
                      : true
                  }
                  placeholder={"Select a repayment cycle"}
                  customWidth={"95%"}
                  dropdownValue={getRecyclePayment}
                  labelTitle={"Repayment Cycle"}
                  isTag
                  optionValue={getAllSelectProductValue?.repaymentCycle}
                  handleChange={(evt) =>
                    setgetAllSelectProductValue({
                      ...getAllSelectProductValue,
                      repaymentCycle: evt.target.value,
                    })
                  }
                />
              </div>
              <div style={{ marginBottom: "58px" }} className="input-div-cover">
                <NormalInputForm
                  selectLoanChecker={!getActiveProduct.length ? false : true}
                  customWidth={"95%"}
                  labelTitle={"Repayment Amount"}
                  value={
                    getActiveProduct === 0
                      ? formatToCurrency(0)
                      : formatToCurrency(getActiveProduct)
                  }
                  disabled={true}
                  hasSpecialStyle
                />
              </div>
              {MessageDetails.message ? (
                <div style={{ marginBottom: "20px" }}>
                  <MessageComponent messageDetails={MessageDetails} />
                </div>
              ) : (
                ""
              )}
              <div
                className="desktop-apply-button"
                style={{ marginBottom: "296px" }}
              >
                <PrimaryButton
                  buttonWith={"21.65%"}
                  text={buttonStateChanger ? "Processing" : "Apply Now"}
                  handleClickButton={
                    buttonStateChanger ? "" : handleClickButton
                  }
                />
              </div>
              <div className="mobile-apply-button">
                <PrimaryButton
                  buttonWith={"85%"}
                  text={"Apply Now"}
                  handleClickButton={handleClickButton}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="select-loan-form-cover">
          {MessageDetails.message ? (
            <div style={{ marginBottom: "20px" }}>
              <MessageComponent messageDetails={MessageDetails} />
            </div>
          ) : (
            ""
          )}
        </div>
      )}
    </div>
  );
}
