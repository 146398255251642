import React from "react";
import { useNavigate } from "react-router";
import TitleComponentHeader from "../../components/titleComponent/TitleComponent";

export default function ConfirmLoanScreen() {
  const navigate = useNavigate();
  const handleRouteLoan = () => {
    navigate("/existing-loan");
  };
  return (
    <div style={{ paddingTop: "70px", paddingLeft: "51px" }}>
      <TitleComponentHeader title={"Confirmation"} />
      <div
        style={{
          paddingRight: "72px",
          marginTop: "54px",
          fontSize: "12px",
          color: "#020614",
        }}
      >
        You have successfully completed your application. Our team will get back
        to you shortly.
        <div style={{ marginTop: "50px" }}>
          <button onClick={handleRouteLoan} className="continue-button">
            Continue
          </button>
        </div>
      </div>
    </div>
  );
}
