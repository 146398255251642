import React from "react";

export default function MessageComponent({ messageDetails }) {
  return (
    <div
      style={{
        fontSize: "12px",
        color: messageDetails.status ? "#00E096" : "red",
        marginTop: "9px",
      }}
    >
      {messageDetails.message}
    </div>
  );
}
