import React, { useEffect, useState } from "react";
import SelectInput from "../../../components/selectInput/selectInput";
import NormalInput from "../../../components/normalInputForm/normalInputForm";
import PrimaryButton from "../../../components/primaryButton/primaryButton";
import { useNavigate } from "react-router";
import { StateAndLocalGovernemt } from "../../../utilities/statesAndLocal";
import { ListOfSchools } from "../../../utilities/schoolsInNigerial";
import { useDispatch, useSelector } from "react-redux";
import services from "../../../services/services";
import MessageComponent from "../../../components/messageComponent/messageComponent";
import { setAppDataToLocalStorage } from "../../../utilities/utilities";
import LocationGoogleAutocomplete from "../../../components/employmentGooglePlaceHolder/employmentGooglelocation";
import AuthForm from "../../../auth/authForm/authForm";
export default function FirstFormCover({
    getSelectedEmploymentDetails,
    getEmploymentStatusFromEndpoint,
    hasEmploymentStatusFromServer,
    sendCardDetailsToFormPage,
    information,
}) {
    const navigate = useNavigate();

    const userDetails = useSelector((state) => state.userDetails);

    const globalErrorMessage = useSelector((state) => state.globalErrorMessage);
    const getEmploymentDetails = useSelector(
        (state) => state.getEmploymentDetails
    );
    const getBvnRecordDetails = useSelector(
        (state) => state.getBvnRecordDetails
    );
    const loanProductSlected = useSelector((state) => state.loanProductSlected);
    const setBorrowerDetails = useSelector((state) => state.setBorrowerDetails);
    const dispatch = useDispatch();

    const getCurrentLoggedInUser = useSelector(
        (state) => state.getCurrentLoggedInUser
    );

    console.log(sendCardDetailsToFormPage);
    const [employmentDetails, setemploymentDetails] = useState({
        employmentStatus: "",
        companyName: "",
        phone: "",
        location: "",
        role: "",
        ippsNo: "",
        school: "",
        level: "",
        retirement_date: "",
        cac_number: "",
    });
    const [checkField, setcheckField] = useState(false);
    const [getListOfSchoolSelect, setgetListOfSchoolSelect] = useState([]);

    // console.log(userDetails.token)
    useEffect(() => {
        setemploymentDetails({
            companyName: "" || information.organization_name,
            phone: "" || information?.work_phone,
            location: "",
            role: "" || information?.occupation,
            ippsNo: "",
            school: "",
            level: "" || information?.level,
            retirement_date: "" || information?.retirement_date,
            employmentStatus: getSelectedEmploymentDetails,
        });
    }, [getSelectedEmploymentDetails]);

    useEffect(() => {
        const getSchoolPerState = ListOfSchools.find(
            (list) => list.name === employmentDetails.location
        );
        setgetListOfSchoolSelect(getSchoolPerState?.school);
    }, [employmentDetails?.location]);

    useEffect(() => {
        const getUserDetails = new services().getService(
            "borrower",
            userDetails.token
        );
        getUserDetails
            .then((res) => {
                if (!res.errorMessage) {
                    dispatch({
                        type: "GET_CURRENT_LOGGEDIN_USER",
                        data: res?.data,
                    });
                }
            })
            .catch((error) => {
                let errorMessage = {
                    message: "an error occur,please check your network.",
                    status: false,
                };

                dispatch({ type: "ERROR_MESSAGE", data: errorMessage });
            });

        let errorMessage = {
            message: "",
            status: false,
        };

        dispatch({ type: "ERROR_MESSAGE", data: errorMessage });
    }, []);

    // console.log(getCurrentLoggedInUser?.WorkStatus[0]?.business_name);
    const routeToCreditCheckPage = () => {
        // console.log("TEST ERROR MESSAGE DISPLAYED TO SCREEN");
        // console.log("getSelectedEmploymentDetails", getSelectedEmploymentDetails);
        // console.log("loanProductSlected" ,loanProductSlected.loanCategory);
        // console.log("employment", employmentDetails)
        //
        if (Object.entries(loanProductSlected).length === 0) {
            let errorMessage = {
                message:
                    "an error occur,please go back to select loan product.",
                status: false,
            };

            dispatch({ type: "ERROR_MESSAGE", data: errorMessage });
        } else if (Object.entries(getBvnRecordDetails).length === 0) {
            let errorMessage = {
                message: "an error occur,please go back to fill bvn details.",
                status: false,
            };

            dispatch({ type: "ERROR_MESSAGE", data: errorMessage });
        } else {
            if (
                employmentDetails.employmentStatus === "Student" &&
                employmentDetails.location &&
                employmentDetails.school &&
                employmentDetails.level
            ) {
                // console.log("employmentDetails set by student", employmentDetails);
                let data = {
                    type: employmentDetails.employmentStatus,
                    school_name: employmentDetails.school,
                    level: employmentDetails.level,
                    country: employmentDetails.location,
                };
                let postWorkStatus = new services().postServiceWithToken(
                    "borrower/working-status",
                    data,
                    userDetails.token
                );

                postWorkStatus
                    .then((res) => {
                        if (res.errorMessage) {
                            let errorMessage = {
                                // message: "",
                                message: res.errorMessage,
                                status: false,
                            };

                            dispatch({
                                type: "ERROR_MESSAGE",
                                data: errorMessage,
                            });
                        } else {
                            let successMessage = {
                                message:
                                    "Student Details successfully created.",
                                status: true,
                            };

                            dispatch({
                                type: "ERROR_MESSAGE",
                                data: successMessage,
                            });
                            setTimeout(() => {
                                let clearMessage = {
                                    message: "",
                                    status: false,
                                };

                                dispatch({
                                    type: "ERROR_MESSAGE",
                                    data: clearMessage,
                                });
                                navigate("/create-loan");
                            }, 500);

                            dispatch({
                                type: "SET_EMPLOYMENT_DETAILS",
                                data: res?.data,
                            });
                        }
                        // setTimeout(() => {
                        //   let clearMessage = {
                        //     message: "",
                        //     status: false,
                        //   };

                        //   dispatch({ type: "ERROR_MESSAGE", data: clearMessage });
                        //   navigate("/create-loan");
                        // }, 500);
                        // dispatch({ type: "SET_EMPLOYMENT_DETAILS", data: data });
                        // console.log("object", data);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
            // else if (
            //   getSelectedEmploymentDetails === "Pensioner" &&
            //   employmentDetails.companyName &&
            //   employmentDetails.location &&
            //   employmentDetails.retirement_date &&
            //   employmentDetails.role&&
            //   loanProductSlected.loanCategory
            // ) {
            //   let data = {
            //     type: employmentDetails.employmentStatus,
            //     business_name: employmentDetails.companyName,
            //     country: employmentDetails.location,
            //     retirement_date: employmentDetails.retirement_date,
            //     occupation: employmentDetails.role,
            //   };

            //   // console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>", {getSelectedEmploymentDetails})
            //   let postWorkStatus = new services().postServiceWithToken(
            //     "borrower/working-status",
            //     data,
            //     userDetails.token
            //   );
            //   postWorkStatus
            //     .then((res) => {
            //       if (res.errorMessage) {
            //         let errorMessage = {
            //           message: "",
            //           // message: res.errorMessage,
            //           status: false,
            //         };
            //         dispatch({ type: "ERROR_MESSAGE", data: errorMessage });
            //       } else {
            //         let successMessage = {
            //           message: "Employment details successfully created.",
            //           status: true,
            //         };
            //         dispatch({ type: "ERROR_MESSAGE", data: successMessage });
            //         setTimeout(() => {
            //           let clearMessage = {
            //             message: "",
            //             status: false,
            //           };
            //           dispatch({ type: "ERROR_MESSAGE", data: clearMessage });
            //           navigate("/create-loan");
            //         }, 500);
            //         dispatch({ type: "SET_EMPLOYMENT_DETAILS", data: res?.data });
            //       }
            //       // dispatch({ type: "SET_EMPLOYMENT_DETAILS", data: data });
            //       // setTimeout(() => {
            //       //   let clearMessage = {
            //       //     message: "",
            //       //     status: false,
            //       //   };
            //       //   dispatch({ type: "ERROR_MESSAGE", data: clearMessage });
            //       //   navigate("/create-loan");
            //       // }, 500);
            //     })
            //     .catch((error) => {
            //       console.log(error);
            //     });
            // }
            else if (
                getSelectedEmploymentDetails === "Business Owner" &&
                employmentDetails.companyName &&
                employmentDetails.phone &&
                employmentDetails.location &&
                loanProductSlected.loanCategory
                // employmentDetails.cac_number
            ) {
                let data = {
                    type: employmentDetails.employmentStatus,
                    business_name: employmentDetails.companyName,
                    work_phone: employmentDetails.phone,
                    country: employmentDetails.location,
                };

                // console.log("bussiness owner is selected");
                let postWorkStatus = new services().postServiceWithToken(
                    "borrower/working-status",
                    data,
                    userDetails.token
                );
                postWorkStatus
                    .then((res) => {
                        console.log(res);
                        if (res.errorMessage) {
                            let errorMessage = {
                                // message: "",
                                message: res.errorMessage,
                                status: false,
                            };
                            dispatch({
                                type: "ERROR_MESSAGE",
                                data: errorMessage,
                            });
                        } else {
                            let successMessage = {
                                message:
                                    "Employment details successfully created.",
                                status: true,
                            };
                            dispatch({
                                type: "ERROR_MESSAGE",
                                data: successMessage,
                            });
                            setTimeout(() => {
                                let clearMessage = {
                                    message: "",
                                    status: false,
                                };
                                dispatch({
                                    type: "ERROR_MESSAGE",
                                    data: clearMessage,
                                });
                                navigate("/create-loan");
                            }, 500);
                            dispatch({
                                type: "SET_EMPLOYMENT_DETAILS",
                                data: {
                                    ...res?.data,
                                    cac_number: employmentDetails.cac_number,
                                },
                            });
                        }
                        // dispatch({
                        //   type: "SET_EMPLOYMENT_DETAILS",
                        //   data: {
                        //     data,
                        //     cac_number: employmentDetails.cac_number,
                        //   },
                        // });
                        // setTimeout(() => {
                        //   let clearMessage = {
                        //     message: "",
                        //     status: false,
                        //   };
                        //   dispatch({ type: "ERROR_MESSAGE", data: clearMessage });
                        //   navigate("/create-loan");
                        // }, 500);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } else if (
                getSelectedEmploymentDetails === "Employee" &&
                employmentDetails.role &&
                employmentDetails.companyName &&
                employmentDetails.phone &&
                employmentDetails.location
            ) {
                // console.log(employmentDetails);
                let data = {
                    type: employmentDetails.employmentStatus,
                    organization_name: employmentDetails.companyName,
                    work_phone: employmentDetails.phone,
                    country: employmentDetails.location,
                    occupation: employmentDetails.role,
                };
                let postWorkStatus = new services().postServiceWithToken(
                    "borrower/working-status",
                    data,
                    userDetails.token
                );
                postWorkStatus
                    .then((res) => {
                        if (res.errorMessage) {
                            let errorMessage = {
                                message: res.errorMessage,
                                // message: "",
                                status: false,
                            };

                            dispatch({
                                type: "ERROR_MESSAGE",
                                data: errorMessage,
                            });
                        } else {
                            console.warn(
                                "posting the employee employment details"
                            );
                            let successMessage = {
                                message:
                                    "Employment details successfully created.",
                                status: true,
                            };

                            dispatch({
                                type: "ERROR_MESSAGE",
                                data: successMessage,
                            });

                            dispatch({
                                type: "SET_EMPLOYMENT_DETAILS",
                                data: res?.data,
                            });
                            setTimeout(() => {
                                let clearMessage = {
                                    message: "",
                                    status: false,
                                };

                                dispatch({
                                    type: "ERROR_MESSAGE",
                                    data: clearMessage,
                                });
                                navigate("/create-loan");
                            }, 500);
                        }

                        // dispatch({ type: "SET_EMPLOYMENT_DETAILS", data: data });
                        // setTimeout(() => {
                        //   let clearMessage = {
                        //     message: "",
                        //     status: false,
                        //   };

                        //   dispatch({ type: "ERROR_MESSAGE", data: clearMessage });
                        //   // console.log(data)
                        //   navigate("/create-loan");
                        // }, 500);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
                // console.log("error");
            } else if (
                getSelectedEmploymentDetails === "Government Employee" &&
                // employmentDetails.role &&
                // employmentDetails.location &&
                // employmentDetails.level &&
                employmentDetails.phone &&
                // employmentDetails.companyName &&
                checkField
            ) {
                console.log(employmentDetails);
                let data = {
                    // type: "Unemployed",
                    type: employmentDetails.employmentStatus,
                    occupation: employmentDetails.role || "-----",
                    work_phone: employmentDetails.phone,
                    country: "Nigeria",
                    // country: employmentDetails.location,
                    level: employmentDetails.level || 1,
                    organization_name: employmentDetails.companyName || "Civil",
                };
                const postWorkStatus = new services().postServiceWithToken(
                    "borrower/working-status",
                    data,
                    userDetails.token
                );
                postWorkStatus
                    .then((res) => {
                        if (res.errorMessage) {
                            let errorMessage = {
                                // message: "",
                                message: res.errorMessage,
                                status: false,
                            };

                            dispatch({
                                type: "ERROR_MESSAGE",
                                data: errorMessage,
                            });
                        } else {
                            let successMessage = {
                                message:
                                    "Employment details successfully created.",
                                status: true,
                            };

                            dispatch({
                                type: "ERROR_MESSAGE",
                                data: successMessage,
                            });
                            dispatch({
                                type: "SET_EMPLOYMENT_DETAILS",
                                data: res?.data,
                            });
                            setTimeout(() => {
                                let cleerMessage = {
                                    message: "",
                                    status: false,
                                };

                                dispatch({
                                    type: "ERROR_MESSAGE",
                                    data: cleerMessage,
                                });
                                navigate("/create-loan");
                            }, 500);
                        }
                        // dispatch({ type: "SET_EMPLOYMENT_DETAILS", data: data });
                        // setTimeout(() => {
                        //   let cleerMessage = {
                        //     message: "",
                        //     status: false,
                        //   };

                        //   dispatch({ type: "ERROR_MESSAGE", data: cleerMessage });
                        //   navigate("/create-loan");
                        // }, 500);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } else if (
                getSelectedEmploymentDetails === "Pensioner" &&
                employmentDetails.companyName &&
                employmentDetails.role &&
                employmentDetails.retirement_date
            ) {
                console.log(employmentDetails);
                let data = {
                    type: employmentDetails.employmentStatus,
                    // occupation: "Pensioner",
                    occupation: employmentDetails.role,
                    organization_name: employmentDetails.companyName,
                    country: "Nigeria",
                    // country: employmentDetails.location,
                    retirement_date: employmentDetails.retirement_date,
                };
                const postWorkStatus = new services().postServiceWithToken(
                    "borrower/working-status",
                    data,
                    userDetails.token
                );
                postWorkStatus
                    .then((res) => {
                        if (res.errorMessage) {
                            let errorMessage = {
                                message: res.errorMessage,
                                status: false,
                            };
                            console.log(res.errorMessage);
                            dispatch({
                                type: "ERROR_MESSAGE",
                                data: errorMessage,
                            });
                            navigate("/existing-loan");
                        } else {
                            dispatch({
                                type: "SET_EMPLOYMENT_DETAILS",
                                data: res?.data,
                            });
                            let successMessage = {
                                message:
                                    "Employment details successfully created.",
                                status: true,
                            };

                            dispatch({
                                type: "ERROR_MESSAGE",
                                data: successMessage,
                            });

                            setTimeout(() => {
                                let clearMessage = {
                                    message: "",
                                    status: false,
                                };

                                dispatch({
                                    type: "ERROR_MESSAGE",
                                    data: clearMessage,
                                });
                                navigate("/create-loan");
                            }, 700);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } else {
                let errorMessage = {
                    message: "Select or fill out all required fields",
                    status: false,
                };
                // dispatch({ type: "ERROR_MESSAGE", data: errorMessage });
            }
        }
        console.log("employmentDetails set by user", employmentDetails);
    };

    const onChangeValue = (value) => {
        setemploymentDetails({
            ...employmentDetails,
            location: value,
        });
    };

    // useEffect(() => {
    //   setemploymentDetails({
    //     ...employmentDetails,
    //     location: "",
    //   });
    // }, [employmentDetails.employmentStatus]);

    return (
        <div>
            {employmentDetails.employmentStatus === "Business Owner" ? (
                <div>
                    <div style={{ marginBottom: "29px" }}>
                        <NormalInput
                            labelTitle={"What is your company name"}
                            placeholder={"Company name"}
                            customWidth={"64%"}
                            value={employmentDetails.companyName}
                            disabled={hasEmploymentStatusFromServer}
                            handleChange={(e) =>
                                setemploymentDetails({
                                    ...employmentDetails,
                                    companyName: e.target.value,
                                })
                            }
                        />
                    </div>
                    <div style={{ marginBottom: "29px" }}>
                        <NormalInput
                            labelTitle={"Contact Number"}
                            placeholder={"Enter your company's phone number"}
                            customWidth={"64%"}
                            disabled={hasEmploymentStatusFromServer}
                            value={employmentDetails.phone}
                            handleChange={(e) =>
                                setemploymentDetails({
                                    ...employmentDetails,
                                    phone: e.target.value,
                                })
                            }
                        />
                    </div>
                    {loanProductSlected?.loanCategory === "CORPORATE" ? (
                        <div style={{ marginBottom: "29px" }}>
                            <NormalInput
                                labelTitle={"Enter CAC Number"}
                                placeholder={"CAC Number"}
                                customWidth={"64%"}
                                disabled={hasEmploymentStatusFromServer}
                                value={employmentDetails.cac_number}
                                handleChange={(e) =>
                                    setemploymentDetails({
                                        ...employmentDetails,
                                        cac_number: e.target.value,
                                    })
                                }
                            />
                        </div>
                    ) : (
                        ""
                    )}
                    <div
                        style={{ marginBottom: "29px" }}
                        className="col-md-8 col-12 employment-location-cover"
                    >
                        {/* <NormalInput
              disabled={hasEmploymentStatusFromServer}
              labelTitle={"Location"}
              placeholder={"Location"}
              value={employmentDetails.location}
              handleChange={(e) =>
                setemploymentDetails({
                  ...employmentDetails,
                  location: e.target.value,
                })
              }
            /> */}
                        {/* <AuthForm
                            type={"text"}
                            placeholder={"Location"}
                            right={"19px"}
                            top={"14px"}
                            disable={false}
                            value={employmentDetails.location}
                            isValid={Boolean(employmentDetails.location)}
                            handleChange={(e) => {
                                onChangeValue(e.target.value);
                            }}
                        />{" "} */}
                        <NormalInput
                            labelTitle={"Location"}
                            placeholder={"Location"}
                            customWidth={"100%"}
                            value={employmentDetails.location}
                            disabled={false}
                            handleChange={(e) => onChangeValue(e.target.value)}
                        />
                        {/* <LocationGoogleAutocomplete
                            labelTitle={"Location"}
                            onChangeValue={onChangeValue}
                            // value={employmentDetails.location}
                        /> */}
                    </div>
                </div>
            ) : employmentDetails.employmentStatus === "Employee" ? (
                <div>
                    <div style={{ marginBottom: "29px" }}>
                        <NormalInput
                            disabled={hasEmploymentStatusFromServer}
                            labelTitle={"Employee Company"}
                            placeholder={"Company name"}
                            customWidth={"64%"}
                            value={
                                employmentDetails.companyName ||
                                information?.organization_name
                            }
                            handleChange={(e) =>
                                setemploymentDetails({
                                    ...employmentDetails,
                                    companyName: e.target.value,
                                })
                            }
                        />
                    </div>
                    <div style={{ marginBottom: "29px" }}>
                        <NormalInput
                            labelTitle={"What is your job role"}
                            placeholder={"Enter your current role"}
                            customWidth={"64%"}
                            value={
                                employmentDetails.role ||
                                information?.occupation
                            }
                            handleChange={(e) =>
                                setemploymentDetails({
                                    ...employmentDetails,
                                    role: e.target.value,
                                })
                            }
                            disabled={hasEmploymentStatusFromServer}
                        />
                    </div>
                    <div style={{ marginBottom: "29px" }}>
                        <NormalInput
                            labelTitle={"What is your Employer's Phone Number"}
                            placeholder={"Enter your Employer's Phone Number"}
                            customWidth={"64%"}
                            value={
                                employmentDetails.phone ||
                                information?.work_phone
                            }
                            handleChange={(e) =>
                                setemploymentDetails({
                                    ...employmentDetails,
                                    phone: e.target.value,
                                })
                            }
                            disabled={hasEmploymentStatusFromServer}
                        />
                    </div>
                    <div
                        style={{ marginBottom: "29px" }}
                        className="col-md-8 col-12 employment-location-cover"
                    >
                        {/* <NormalInput
              disabled={hasEmploymentStatusFromServer}
              labelTitle={"Location"}
              placeholder={"Location"}
              value={employmentDetails.location}
              handleChange={(e) =>
                setemploymentDetails({
                  ...employmentDetails,
                  location: e.target.value,
                })
              }
            /> */}
                        {/* <AuthForm
                            type={"text"}
                            placeholder={"Employee Location"}
                            right={"19px"}
                            top={"14px"}
                            disable={false}
                            value={employmentDetails.location}
                            isValid={Boolean(employmentDetails.location)}
                            handleChange={(e) => {
                                onChangeValue(e.target.value);
                            }}
                        />{" "} */}
                        <NormalInput
                            labelTitle={"Employer Location"}
                            placeholder={"Employer Location"}
                            customWidth={"99%"}
                            value={employmentDetails.location}
                            disabled={false}
                            handleChange={(e) => onChangeValue(e.target.value)}
                        />
                        {/* <LocationGoogleAutocomplete
                            labelTitle={"Employee Location"}
                            onChangeValue={onChangeValue}
                        /> */}
                    </div>
                </div>
            ) : employmentDetails.employmentStatus === "Government Employee" ? (
                <div>
                    {/* <div style={{ marginBottom: "29px" }}>
            <NormalInput
              labelTitle={"Level"}
              placeholder={"Level"}
              customWidth={"64%"}
              value={employmentDetails.level}
              handleChange={(e) =>
                setemploymentDetails({
                  ...employmentDetails,
                  level: e.target.value,
                })
              }
              disabled={hasEmploymentStatusFromServer}
            />
          </div> */}
                    {/* <div style={{ marginBottom: "29px" }}>
            <NormalInput
              disabled={hasEmploymentStatusFromServer}
              labelTitle={"What is your company name"}
              placeholder={"Company"}
              customWidth={"64%"}
              value={employmentDetails.companyName}
              handleChange={(e) =>
                setemploymentDetails({
                  ...employmentDetails,
                  companyName: e.target.value,
                })
              }
            />
          </div> */}
                    {/* <div style={{ marginBottom: "29px" }}>
            <NormalInput
              labelTitle={"What is your Remita Phone Number"}
              placeholder={"Enter your Remita phone number"}
              customWidth={"64%"}
              value={employmentDetails.role}
              handleChange={(e) =>
                setemploymentDetails({
                  ...employmentDetails,
                  role: e.target.value,
                })
              }
              disabled={hasEmploymentStatusFromServer}
            />
          </div> */}
                    <div style={{ marginBottom: "29px" }}>
                        <NormalInput
                            labelTitle={"What is your Remita Phone Number"}
                            placeholder={"Enter your Remita phone number"}
                            customWidth={"64%"}
                            value={employmentDetails.phone}
                            handleChange={(e) =>
                                setemploymentDetails({
                                    ...employmentDetails,
                                    phone: e.target.value,
                                })
                            }
                            disabled={hasEmploymentStatusFromServer}
                        />
                    </div>
                    <div
                        style={{ marginBottom: "29px" }}
                        className="col-md-8 col-12 employment-location-cover"
                    >
                        {/* <NormalInput
              disabled={hasEmploymentStatusFromServer}
              labelTitle={"Location"}
              placeholder={"Location"}
              value={employmentDetails.location}
              handleChange={(e) =>
                setemploymentDetails({
                  ...employmentDetails,
                  location: e.target.value,
                })
              }
            /> */}

                        {/* <LocationGoogleAutocomplete
              labelTitle={"Location"}
              onChangeValue={onChangeValue}
            /> */}
                        <div
                            style={{
                                fontSize: "14px",
                                lineHeight: "21px",
                                color: "black",
                                fontWeight: 500,
                                marginTop: "47px",
                                display: "flex",
                            }}
                        >
                            <input
                                type="checkbox"
                                style={{
                                    width: "80px",
                                    height: "80px",
                                    marginRight: "10px",
                                }}
                                value={checkField}
                                onChange={(evt) =>
                                    setcheckField(evt.target.checked)
                                }
                            />
                            By clicking on the Checkbox, I consent to Dizbuzz
                            Limited obtaining information from relevant third
                            parties as may be necessary, on my employment
                            details, salary payment history, loans and other
                            related data, to make a decision on my loan
                            application. I also consent to the loan amounts
                            being deducted from my salary at source before
                            credit to my account; and any outstanding loans
                            being recovered automatically from any BVN accounts
                            linked to me in the case of default.
                        </div>
                    </div>
                </div>
            ) : employmentDetails.employmentStatus === "Student" ? (
                <div>
                    <div style={{ marginBottom: "29px" }}>
                        <NormalInput
                            labelTitle={"Level"}
                            placeholder={"Level"}
                            customWidth={"64%"}
                            value={employmentDetails.level}
                            handleChange={(e) =>
                                setemploymentDetails({
                                    ...employmentDetails,
                                    level: e.target.value,
                                })
                            }
                            disabled={hasEmploymentStatusFromServer}
                        />
                    </div>
                    <div style={{ marginBottom: "29px" }}>
                        <SelectInput
                            labelTitle={"Location"}
                            placeholder={"Location"}
                            dropdownValue={StateAndLocalGovernemt}
                            customWidth={"64%"}
                            optionValue={employmentDetails.location}
                            hasLocation
                            handleChange={(e) =>
                                setemploymentDetails({
                                    ...employmentDetails,
                                    location: e.target.value,
                                })
                            }
                            disabled={hasEmploymentStatusFromServer}
                        />
                    </div>
                    {employmentDetails.location ? (
                        <div style={{ marginBottom: "29px" }}>
                            <SelectInput
                                labelTitle={"School Name"}
                                placeholder={"School Name"}
                                dropdownValue={getListOfSchoolSelect}
                                customWidth={"64%"}
                                hasSchools
                                optionValue={employmentDetails.school}
                                handleChange={(e) =>
                                    setemploymentDetails({
                                        ...employmentDetails,
                                        school: e.target.value,
                                    })
                                }
                                disabled={hasEmploymentStatusFromServer}
                            />
                        </div>
                    ) : (
                        ""
                    )}
                </div>
            ) : employmentDetails.employmentStatus === "Pensioner" ? (
                <div>
                    <NormalInput
                        labelTitle={"What was your organization name"}
                        placeholder={"Organization Name"}
                        customWidth={"64%"}
                        value={employmentDetails.companyName}
                        handleChange={(e) =>
                            setemploymentDetails({
                                ...employmentDetails,
                                companyName: e.target.value,
                            })
                        }
                        disabled={hasEmploymentStatusFromServer}
                    />
                    <div style={{ marginBottom: "29px" }}>
                        <NormalInput
                            labelTitle={"What is your previous occupation?"}
                            placeholder={"Enter your current role"}
                            customWidth={"64%"}
                            value={employmentDetails.role}
                            handleChange={(e) =>
                                setemploymentDetails({
                                    ...employmentDetails,
                                    role: e.target.value,
                                })
                            }
                            disabled={hasEmploymentStatusFromServer}
                        />
                    </div>
                    {/* <div
            style={{ marginBottom: "29px" }}
            className="col-md-8 col-12 employment-location-cover"
          >
            <NormalInput
              disabled={hasEmploymentStatusFromServer}
              labelTitle={"Location"}
              placeholder={"Location"}
              value={employmentDetails.location}
              handleChange={(e) =>
                setemploymentDetails({
                  ...employmentDetails,
                  location: e.target.value,
                })
              }
            />

            <LocationGoogleAutocomplete
              labelTitle={"Location"}
              onChangeValue={onChangeValue}
            />
          </div> */}
                    <div style={{ marginBottom: "29px" }}>
                        <NormalInput
                            type={"date"}
                            labelTitle={"Retirement Date"}
                            customWidth={"64%"}
                            value={employmentDetails.retirement_date}
                            handleChange={(e) =>
                                setemploymentDetails({
                                    ...employmentDetails,
                                    retirement_date: e.target.value,
                                })
                            }
                            disabled={hasEmploymentStatusFromServer}
                        />
                    </div>
                </div>
            ) : (
                ""
            )}
            {globalErrorMessage.message ? (
                <div style={{ marginTop: "20px" }}>
                    <MessageComponent messageDetails={globalErrorMessage} />
                </div>
            ) : (
                ""
            )}
            <div
                style={{ marginTop: "58px", paddingBottom: "59px" }}
                className="mobile-first-cover-button"
            >
                {getSelectedEmploymentDetails ===
                "Government Employee/Federal Civil Servant" ? (
                    employmentDetails.phone && checkField ? (
                        <PrimaryButton
                            text={"Continue"}
                            buttonWith={"21.65%"}
                            handleClickButton={routeToCreditCheckPage}
                        />
                    ) : (
                        ""
                    )
                ) : (
                    <PrimaryButton
                        text={"Continue"}
                        buttonWith={"21.65%"}
                        handleClickButton={routeToCreditCheckPage}
                    />
                )}
                <div style={{ marginTop: "29px", fontSize: "10px" }}>
                    {" "}
                    Note: Dizbuzz may use any information relating to the
                    Borrower for evaluating the credit application. Dizbuzz may
                    at its sole discretion as it shall consider appropriate give
                    to and receive from credit bureaus and reference agencies
                    whether based locally or abroad, other financial
                    institutions, regulatory and law enforcement agencies and
                    relevant third parties information about the Borrower,
                    including information on the conduct on the Borrower’s
                    account together with details of any non-payment or delayed
                    payments for the purpose of assisting them and/or Dizbuzz in
                    making lending or rating decisions about the Borrower.
                </div>
            </div>
        </div>
    );
}
