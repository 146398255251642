import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import { useSelector } from "react-redux";
import EditIcon from "../../images/Edit icon.png";
import axios from "axios";
import services from "../../services/services";
import { useDispatch } from "react-redux";
export default function ImageAccountAvatars({ hasEditIcon }) {
  const userDetails = useSelector((state) => state.userDetails);
  const getCurrentLoggedInUser = useSelector(
    (state) => state.getCurrentLoggedInUser
  );

  const dispatch = useDispatch();
  const [imageDetails, setimageDetails] = React.useState({
    image: null,
  });
  const [refresh, setrefresh] = React.useState(false);
  const handleChangeFile = (file) => {
    setimageDetails({
      image: file[0],
    });
  };

  React.useEffect(() => {
    let form_data = new FormData();

    form_data.append("borrower_avatar", imageDetails.image);
    const requestOptions = {
      headers: {
        Authorization: `${userDetails.token}`,
      },
    };
    if (imageDetails.image) {
      axios
        .patch(
          `${process.env.REACT_APP_BASEURL}borrower/image-upload/${userDetails.customer_id}`,
          form_data,
          requestOptions
        )
        .then((res) => {
          if (res.errorMessage) {
            dispatch({
              type: "GET_CURRENT_LOGGEDIN_USER",
              data: { ...getCurrentLoggedInUser },
            });
          } else {
            setrefresh(true);
            dispatch({
              type: "GET_CURRENT_LOGGEDIN_USER",
              data: {
                ...getCurrentLoggedInUser,
                borrower_avatar: res?.data?.borrower_avatar,
              },
            });
          }
          //   console.log(data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [imageDetails.image]);

  React.useEffect(() => {
    const getCustomer = new services().getService(
      "borrower",

      userDetails?.token
    );
    getCustomer
      .then((res) => {
        if (res.errorMessage) {
          dispatch({ type: "GET_CURRENT_LOGGEDIN_USER", data: {} });
        } else {
          dispatch({
            type: "GET_CURRENT_LOGGEDIN_USER",
            data: res?.data,
          });
        }
      })
      .catch((error) => {
        dispatch({ type: "GET_CURRENT_LOGGEDIN_USER", data: {} });
      });
  }, [refresh]);
  console.log(getCurrentLoggedInUser?.borrower_avatar);
  return (
    <div style={{ position: "relative" }}>
      {hasEditIcon ? (
        <div
          style={{
            position: "absolute",
            zIndex: "1000",
            left: "20%",
            top: "59%",
          }}
        >
          <input
            type="file"
            onChange={(evt) => handleChangeFile(evt.target.files)}
            id="image-upload"
            style={{ display: "none" }}
          />
          <label htmlFor="image-upload">
            {" "}
            <img src={EditIcon} alt="logo" style={{ cursor: "pointer" }} />
          </label>
        </div>
      ) : (
        ""
      )}

      <Stack direction="row" spacing={2}>
        <Avatar
          alt={userDetails?.first_name}
          src={getCurrentLoggedInUser?.borrower_avatar}
          sx={{ width: 100, height: 100 }}
        />
      </Stack>
    </div>
  );
}
