import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LandingPageHeader from "../../components/landingHeader/landingPageHeader";
import { emailValidation } from "../../utilities/utilities";
import EnjoyCoverComponent from "../landingPage/enjoyCoverComponent";
import MobileBannerAuth from "../mobileBannerAuth";
// import "./signin.css";
import "./signin.css";

import SigninFormComponent from "./signinFormComponent";
export default function Signin() {
  return (
    <div>
      <div>
        <LandingPageHeader color={"white"} />
      </div>
      <div className="signup-container">
        <div className="container col-xl-7 col-lg-11 ">
          <div className="sign-up-desktop">
            <div className="row m-0 landing-row-cover">
              {/* <EnjoyCoverComponent /> */}

              <div className="col-md-12 col-12 register-form-cover">
                <div className="signin-title">

                Member Sign-In



                </div>
                <div className="register-sub-title">
                Sign into your Dizbuzz account
                </div>

                <div>
                  <SigninFormComponent />
                </div>
              </div>
            </div>
          </div>
          <div className="sign-up-mobile">
            <div className="row m-0">
              {/* <MobileBannerAuth customHeight={"137px"} customWidth={"151px"} /> */}

              <div className="col-md col-12 register-form-cover">
                <div className="signin-mobile-title">  Member Sign-In</div>
                <div className="signin-mobile-sub-title">
                Sign into your Dizbuzz account
                </div>

                <div>
                  {" "}
                  <SigninFormComponent />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
