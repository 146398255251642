import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import AuthForm from "../../../auth/authForm/authForm";
import GoogleAutoComplete from "../../../components/googlePlaceholder/googlePlaceholder";
import MessageComponent from "../../../components/messageComponent/messageComponent";
import PrimaryButton from "../../../components/primaryButton/primaryButton";
import RoundSelectInput from "../../../components/roundSelectInput/roundSelectInput";
import services from "../../../services/services";
import { StateAndLocalGovernemt } from "../../../utilities/statesAndLocal";
import { getStatesInNigeria } from "../../../utilities/utilities";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import GoogleAutocomplete from "../../autoCompletePlace/googleAutocomplete";
export default function PersonalRowCover() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const globalErrorMessage = useSelector((state) => state.globalErrorMessage);
    const getCurrentLoggedInUser = useSelector(
        (state) => state.getCurrentLoggedInUser
    );

    const [sexValue, setsexValue] = useState("Male");
    const [cityValue, setcityValue] = useState("");
    const [stateValue, setstateValue] = useState("");
    const [lastName, setlastName] = useState("");
    const getBvnRecordDetails = useSelector(
        (state) => state.getBvnRecordDetails
    );
    const loanProductSlected = useSelector((state) => state.loanProductSlected);
    const userDetails = useSelector((state) => state.userDetails);
    const [TitleValue, setTitleValue] = useState("Mr");
    const [titleState, settitleState] = useState([
        { id: 1, name: "Mr" },
        { id: 2, name: "Mrs" },
    ]);
    const [cityDropDownValue, setcityDropDownValue] = useState([]);
    const [sexDropDownValue, setsexDropDownValue] = useState([
        { id: 1, name: "Male", value: "Male" },
        { id: 2, name: "Female", value: "Female" },
    ]);

    const [stateResidence, setstateResidence] = useState("");
    const [addressResidence, setaddressResidence] = useState("");
    const [address, setaddress] = useState("");

    console.log(cityValue);
    console.log(getCurrentLoggedInUser?.city);
    useEffect(() => {
        const getCity = StateAndLocalGovernemt.find(
            (list) => list.state.name === stateValue
        );
        setcityDropDownValue(getCity?.state?.locals);
    }, [stateValue]);

    useEffect(() => {
        const getUserDetails = new services().getService(
            "borrower",
            userDetails.token
        );
        getUserDetails
            .then((res) => {
                if (!res.errorMessage) {
                    dispatch({
                        type: "GET_CURRENT_LOGGEDIN_USER",
                        data: res.data,
                    });

                    setsexValue(res?.data?.gender ? res?.data?.gender : "Male");
                    setcityValue(res?.data?.city ? res?.data?.city : "");
                    setstateValue(
                        res?.data?.state_origin ? res?.data?.state_origin : ""
                    );
                    setlastName(
                        res?.data?.last_name ? res?.data?.last_name : ""
                    );
                    setTitleValue(res?.data?.title ? res?.data?.title : "Mr");
                    setstateResidence(
                        res?.data?.state_residence
                            ? res?.data?.state_residence
                            : ""
                    );
                    setaddressResidence(
                        res?.data?.residential_address
                            ? res?.data?.residential_address
                            : ""
                    );
                }
            })
            .catch((error) => {
                let errorMessage = {
                    message: "an error occur,please check your network.",
                    status: false,
                };

                dispatch({ type: "ERROR_MESSAGE", data: errorMessage });
            });
        let errorMessage = {
            message: "",
            status: false,
        };

        dispatch({ type: "ERROR_MESSAGE", data: errorMessage });
    }, []);
    let bvnNames = getBvnRecordDetails.accountName.split(" ");
    const bvnLastName = bvnNames.pop();
    const bvnFirstName = bvnNames.join(" ");
    const routeToEmploymentDetails = () => {
        console.log(
            stateValue,
            cityValue,
            sexValue,
            addressResidence,
            stateResidence,
            lastName,
            TitleValue
        );
        if (Object.entries(loanProductSlected).length === 0) {
            let errorMessage = {
                message:
                    "an error occur,please  go back to select loan product.",
                status: false,
            };

            dispatch({ type: "ERROR_MESSAGE", data: errorMessage });
        } else if (getBvnRecordDetails.bvn_status === false) {
            let errorMessage = {
                message: "an error occur,please  go back to fill bvn details.",
                status: false,
            };

            dispatch({ type: "ERROR_MESSAGE", data: errorMessage });
        } else if (
            stateValue === "" ||
            cityValue === "" ||
            sexValue === "" ||
            addressResidence === "" ||
            stateResidence === "" ||
            TitleValue === ""
        ) {
            let errorMessage = {
                message: "please fill out all empty fields.",
                status: false,
            };

            dispatch({ type: "ERROR_MESSAGE", data: errorMessage });
        } else {
            // bvn_status: getBvnRecordDetails?.bvn_status,
            console.log(
                stateValue,
                cityValue,
                sexValue,
                addressResidence,
                stateResidence,
                lastName,
                TitleValue
            );
            if (getCurrentLoggedInUser?.user_status?.create_borrower) {
                dispatch({
                    type: "SET_BORROWER_DETAILS",
                    data: getCurrentLoggedInUser,
                });
                navigate("/loans/employment-details");
            } else {
                let data = {
                    customer_id: userDetails.customer_id,
                    title: TitleValue,
                    first_name: bvnFirstName,
                    middle_name: ".....",
                    last_name: bvnLastName,
                    email: userDetails.email,
                    dob: getBvnRecordDetails?.dateOfBirth,
                    gender: sexValue,
                    residential_address: addressResidence,
                    city: cityValue,
                    state_origin: stateValue,
                    state_residence: stateResidence,
                    phone: userDetails.phone,
                    bvn: getBvnRecordDetails.bvnNumber,
                    description: getBvnRecordDetails.bvnNumber,
                };
                const getPostBorrowResponse =
                    new services().postServiceWithToken(
                        "borrower",
                        data,
                        userDetails.token
                    );
                getPostBorrowResponse
                    .then((res) => {
                        if (res.errorMessage) {
                            let errorMessage = {
                                message: res.errorMessage,
                                status: false,
                            };

                            dispatch({
                                type: "ERROR_MESSAGE",
                                data: errorMessage,
                            });
                        } else {
                            let successMessage = {
                                message:
                                    "Personal details successfully created.",
                                status: true,
                            };

                            dispatch({
                                type: "ERROR_MESSAGE",
                                data: successMessage,
                            });
                            setTimeout(() => {
                                navigate("/loans/employment-details");
                            }, 500);
                            dispatch({
                                type: "SET_BORROWER_DETAILS",
                                data: data,
                            });
                        }
                    })
                    .catch((error) => {
                        let errorMessage = {
                            message:
                                "an error occur, please check your network",
                            status: false,
                        };

                        dispatch({ type: "ERROR_MESSAGE", data: errorMessage });
                    });
            }
        }
    };
    const onChangeValue = (value) => {
        setaddressResidence(value.label);

        setstateResidence(value?.value?.structured_formatting?.secondary_text);
    };
    return (
        <div className="row m-0 p-0">
            <div className="col-12 p-0 m-0">
                <div className="row m-0 p-0">
                    <div
                        className="col-lg-6 col-12 first-col"
                        style={{ marginBottom: "29px" }}
                    >
                        <div className="row m-0">
                            <div className="col-4 inner-first-col">
                                <RoundSelectInput
                                    top={"14px"}
                                    right={"21px"}
                                    arrowRight={"50px"}
                                    arrowTop={"16px"}
                                    dropDownValue={titleState}
                                    isValid
                                    hasTitle
                                    disabled={
                                        getCurrentLoggedInUser?.user_status
                                            ?.create_borrower
                                            ? true
                                            : false
                                    }
                                    value={TitleValue}
                                    handleSelectChange={(evt) =>
                                        setTitleValue(evt.target.value)
                                    }
                                />
                            </div>
                            <div className="col inner-second-col">
                                <AuthForm
                                    value={
                                        getCurrentLoggedInUser?.user_status
                                            ?.create_borrower
                                            ? getCurrentLoggedInUser?.first_name
                                            : bvnFirstName
                                    }
                                    isValid
                                    type={"text"}
                                    placeholder={"First Name"}
                                    right={"19px"}
                                    top={"14px"}
                                    disable={true}
                                />
                            </div>
                        </div>
                    </div>
                    <div
                        className="col-lg-6 col-12 second-col"
                        style={{ marginBottom: "29px" }}
                    >
                        <AuthForm
                            type={"text"}
                            placeholder={"Last Name"}
                            right={"19px"}
                            top={"14px"}
                            disable={true}
                            value={
                                getCurrentLoggedInUser?.user_status
                                    ?.create_borrower
                                    ? getCurrentLoggedInUser?.last_name
                                    : bvnLastName
                            }
                            isValid={true}
                        />{" "}
                    </div>
                    <div
                        className="col-lg-6 col-6 mobile-first-col"
                        style={{ marginBottom: "29px" }}
                    >
                        <RoundSelectInput
                            top={"14px"}
                            right={"21px"}
                            arrowRight={"50px"}
                            arrowTop={"16px"}
                            dropDownValue={sexDropDownValue}
                            isValid
                            disabled={
                                getCurrentLoggedInUser?.user_status
                                    ?.create_borrower
                                    ? true
                                    : false
                            }
                            value={sexValue}
                            handleSelectChange={(evt) =>
                                setsexValue(evt.target.value)
                            }
                            hasSex
                        />
                    </div>
                    <div
                        className="col-lg-6 col-6 mobile-second-col"
                        style={{ marginBottom: "29px" }}
                    >
                        <AuthForm
                            type={"text"}
                            placeholder={"Date of Birth"}
                            right={"19px"}
                            top={"14px"}
                            value={
                                getCurrentLoggedInUser?.user_status
                                    ?.create_borrower
                                    ? getCurrentLoggedInUser?.dob
                                    : getBvnRecordDetails?.dateOfBirth
                            }
                            isValid
                            disable={true}
                        />{" "}
                    </div>
                    <div
                        className="col-lg-6 col-12 mobile-first-col"
                        style={{ marginBottom: "29px" }}
                    >
                        {/* <input
                            type="text"
                            placeholder="Enter Your Address"
                            className="w-full h-full"
                        /> */}
                        <AuthForm
                            type={"text"}
                            placeholder={"Enter Your Address"}
                            right={"19px"}
                            top={"14px"}
                            disable={false}
                            value={addressResidence}
                            isValid={Boolean(addressResidence)}
                            handleChange={(e) => {
                                setaddressResidence(e.target.value);
                            }}
                        />{" "}
                        {/* <GoogleAutocomplete
              onChangeValue={onChangeValue}
              isValid={
                getCurrentLoggedInUser?.user_status?.create_borrower ||
                addressResidence.length
                  ? true
                  : false
              }
              left={"91%"}
              top={"30%"}
            /> */}
                    </div>

                    <div
                        className="col-lg-6 col-12 mobile-second-col"
                        style={{ marginBottom: "29px" }}
                    >
                        <AuthForm
                            type={"text"}
                            placeholder={"State of residence"}
                            right={"19px"}
                            top={"14px"}
                            handleChange={(evt) =>
                                setstateResidence(evt.target.value)
                            }
                            value={stateResidence}
                            isValid={Boolean(stateResidence)}
                            // disable={
                            //   getCurrentLoggedInUser?.user_status?.create_borrower
                            //     ? true
                            //     : false
                            // }
                            disable={false}
                        />{" "}
                    </div>

                    <div
                        className="col-lg-6 col-12 mobile-first-col"
                        style={{ marginBottom: "29px" }}
                    >
                        <RoundSelectInput
                            top={"14px"}
                            right={"21px"}
                            arrowRight={"50px"}
                            arrowTop={"16px"}
                            dropDownValue={StateAndLocalGovernemt}
                            isValid={stateValue ? true : false}
                            disabled={
                                getCurrentLoggedInUser?.user_status
                                    ?.create_borrower
                                    ? true
                                    : false
                            }
                            value={stateValue}
                            handleSelectChange={(evt) =>
                                setstateValue(evt.target.value)
                            }
                            hasStateValue
                        />
                    </div>
                    <div
                        className="col-lg-6 col-12 mobile-second-col"
                        style={{ marginBottom: "29px" }}
                    >
                        <RoundSelectInput
                            top={"14px"}
                            right={"21px"}
                            arrowRight={"50px"}
                            arrowTop={"16px"}
                            dropDownValue={cityDropDownValue}
                            isValid={cityValue ? true : false}
                            disabled={
                                getCurrentLoggedInUser?.user_status
                                    ?.create_borrower
                                    ? true
                                    : false
                            }
                            value={cityValue}
                            handleSelectChange={(evt) =>
                                setcityValue(evt.target.value)
                            }
                            hasCity
                        />
                    </div>
                    {userDetails?.email ? (
                        ""
                    ) : (
                        <div
                            className="col-lg-6 col-6  mobile-first-col"
                            style={{ marginBottom: "29px" }}
                        >
                            <AuthForm
                                type={"email"}
                                placeholder={"Email Address"}
                                right={"19px"}
                                top={"14px"}
                                disable={true}
                                isValid
                                value={
                                    getCurrentLoggedInUser?.user_status
                                        ?.create_borrower
                                        ? getCurrentLoggedInUser?.email
                                        : userDetails?.email
                                }
                            />{" "}
                        </div>
                    )}
                    {userDetails?.phone ? (
                        ""
                    ) : (
                        <div className="col-lg-6 col-6  mobile-second-col">
                            <AuthForm
                                type={"text"}
                                placeholder={"Phone number"}
                                right={"19px"}
                                top={"14px"}
                                isValid
                                value={
                                    getCurrentLoggedInUser?.user_status
                                        ?.create_borrower
                                        ? getCurrentLoggedInUser?.phone
                                        : userDetails?.phone
                                }
                                disable={true}
                            />{" "}
                        </div>
                    )}

                    {globalErrorMessage.message ? (
                        <div style={{ marginTop: "20px" }}>
                            <MessageComponent
                                messageDetails={globalErrorMessage}
                            />
                        </div>
                    ) : (
                        ""
                    )}

                    <div
                        className="col-lg-12 col-12 desktop-button-div"
                        style={{
                            marginTop: "78px",
                            textAlign: "center",
                        }}
                    >
                        <span style={{ paddingRight: "50px" }}>
                            {" "}
                            <PrimaryButton
                                text={"Continue"}
                                buttonWith={"21.65%"}
                                handleClickButton={routeToEmploymentDetails}
                            />
                        </span>
                    </div>
                    <div
                        className="col-lg-12 col-12 mobile-button-div"
                        style={{
                            marginTop: "78px",
                            textAlign: "center",
                        }}
                    >
                        <span>
                            {" "}
                            <PrimaryButton
                                text={"Continue"}
                                buttonWith={"80%"}
                                handleClickButton={routeToEmploymentDetails}
                            />
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}
