import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./rootReducer/rootReducer";
import { persistStore, persistReducer } from "redux-persist"; // imports from redux-persist
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
const { logger } = require(`redux-logger`);
const middlewares = [thunk];
middlewares.push(logger);
const persistConfig = {
  // configuration object for redux-persist
  key: "selectedUserDetails",
  storage, // define which storage to use
  whitelist: [
    "loanProductSlected",
    "getBvnRecordDetails",
    "setEmploymentDetails",
    "loanPaymentSuccess",
    "getLoanId",
  ],
};
const persistedReducer = persistReducer(persistConfig, rootReducer); // create a persisted reducer
const store = createStore(persistedReducer, applyMiddleware(...middlewares));
const persistor = persistStore(store);
export default store;
