import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import GoBack from "../../components/goBack/goBack";
import LoanMobileTitleComponent from "../../components/loanMobileTitleComponent/loanMobileTitleComponent";
import services from "../../services/services";
import { formatToCurrency } from "../../utilities/utilities";
import RepaymentButton from "../repayment/repaymentButton";
import UpcomingRepaymentMobile from "../repayment/upcomingRepaymentMobile";
import "./nextRepayment.css";
export default function NextRepayment() {
  const getId = useParams();
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.userDetails);
  const getNextRepayment = useSelector((state) => state.getNextRepayment);
  const getIndividualLoan = useSelector((state) => state.getIndividualLoan);
  useEffect(() => {
    const getNextLoanRepayment = new services().getServiceWithId(
      "repayments/due",
      getId.id,
      userDetails.token
    );
    getNextLoanRepayment
      .then((res) => {
        if (res.errorMessage) {
          dispatch({ type: "GET_NEXT_REPAYMENT", data: {} });
        } else {
          if (res?.data?.remainingPeriods.length) {
            dispatch({
              type: "GET_NEXT_REPAYMENT",
              data: res?.data?.remainingPeriods[0],
            });
          } else {
            dispatch({ type: "GET_NEXT_REPAYMENT", data: {} });
          }
        }
        console.log(res);
      })
      .catch((error) => {
        console.log(error);
      });

    const getIndividualLoan = new services().getServiceWithId(
      "loan",
      getId.id,
      userDetails.token
    );
    getIndividualLoan
      .then((res) => {
        if (res.errorMessage) {
          dispatch({ type: "GET_INDIVIDUAL_LOAN_DETAILS", data: {} });
        } else {
          dispatch({ type: "GET_INDIVIDUAL_LOAN_DETAILS", data: res?.data });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const makeRepayment = (amount) => {
    dispatch({ type: "SHOW_SPINNER", data: true });
    // console.log(loanId);
    let data = {
      loan_id: getId.id,
      amount: amount,
    };
    const postRepayment = new services().postServiceWithToken(
      "repayments/make-repayment",
      data,
      userDetails.token
    );
    postRepayment
      .then((res) => {
        if (res.errorMessage) {
          let errorMessage = {
            message: res.errorMessage,
            status: false,
          };

          dispatch({ type: "ERROR_MESSAGE", data: errorMessage });
        } else {
          window.location.href = res?.data?.authorization_url;
          let errorMessage = {
            message: "Repayment successful",
            status: true,
          };

          dispatch({ type: "ERROR_MESSAGE", data: errorMessage });
        }
        dispatch({ type: "SHOW_SPINNER", data: false });

        dispatch({ type: "SHOW_TOAST", data: true });
      })
      .catch((error) => {
        dispatch({ type: "SHOW_SPINNER", data: false });
        console.log(error);
      });
  };
  return (
    <div className="next-repayment-main-cover">
      <div className="next-repayment-cover-for-mobile">
        <div className="upcoming-repayment-title">
          <span style={{ display: "flex" }}>
            {" "}
            <GoBack /> Next Repayments
          </span>
        </div>
        <div>
          <div className="upcoming-header-cover">
            <div className="row m-0 p-0 upcoming-repayment-header">
              <div className="col-6 left-upcoming-repayment-header">
                Repayment
              </div>
              <div className="col-6 right-upcoming-repayment-header">
                Action
              </div>
            </div>
          </div>
          <div className="upcoming-repayment-content">
            {Object.entries(getNextRepayment).length ? (
              <div className="row m-0 p-0">
                <div className="col-2 due-repayment-cols">
                  Due Date
                  <br />
                  <div className="due-repayment-col-span">
                    {getNextRepayment?.date ? getNextRepayment?.date : ""}
                  </div>
                </div>
                <div className="col-2 due-repayment-cols">
                  Loan ID
                  <br />
                  <div className="due-repayment-col-span">
                    {getIndividualLoan?.loan_acct_id
                      ? getIndividualLoan?.loan_acct_id
                      : ""}
                  </div>
                </div>
                <div className="col-2 due-repayment-cols">
                  Principal
                  <br />
                  <div className="due-repayment-col-span">
                    ₦
                    {getNextRepayment?.principal_outstanding
                      ? formatToCurrency(
                          getNextRepayment?.principal_outstanding
                        )
                      : 0}
                  </div>
                </div>
                <div className="col-2 due-repayment-cols">
                  Interest
                  <br />
                  <div className="due-repayment-col-span">
                    ₦
                    {getNextRepayment?.interest
                      ? formatToCurrency(getNextRepayment?.interest)
                      : 0}
                  </div>
                </div>
                <div className="col-2 due-repayment-cols">
                  Due Amount
                  <br />
                  <div className="due-repayment-col-span">
                    {" "}
                    ₦{" "}
                    {getNextRepayment?.due_principal
                      ? formatToCurrency(getNextRepayment?.due_principal)
                      : 0}
                  </div>
                </div>
                <div className="col-2 repayment-upcoming-button-cover ">
                  <RepaymentButton
                    customWith={"165px"}
                    handlePayRepayment={() =>
                      makeRepayment(getNextRepayment?.due_principal)
                    }
                  />
                </div>
              </div>
            ) : (
              <div
                style={{
                  color: "#858585",
                  fontSize: "12px",
                  marginTop: "30px",
                }}
              >
                You have no Upcoming repayment this time
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="next-repayment-for-mobile">
        <div
          style={{ fontWeight: "700", fontSize: "16px", marginBottom: "30px" }}
        >
          Next Repayment
        </div>
        <UpcomingRepaymentMobile />
      </div>
    </div>
  );
}
