import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import LocationGoogleAutocomplete from "../../../components/employmentGooglePlaceHolder/employmentGooglelocation";
import GooglePlaceholder from "../../../components/googlePlaceholder/googlePlaceholder";
import MessageComponent from "../../../components/messageComponent/messageComponent";
import PrimaryButton from "../../../components/primaryButton/primaryButton";
import SelectInput from "../../../components/selectInput/selectInput";
import TitleComponentHeader from "../../../components/titleComponent/TitleComponent";
import services from "../../../services/services";
import "./employmentDetails.css";
import FirstFormCover from "./firstFormCover";
import FourthFormCover from "./fourthFormCover";
import ListOfEmploymentStatusCard from "./listOfEmploymentStatusCard/listOfEmploymentStatusCard";
import SecondFormCover from "./secondFormCover";
import ThirdFormCover from "./ThirdFormCover";
export default function EmploymentDetails() {
    const navigate = useNavigate();
    const globalErrorMessage = useSelector((state) => state.globalErrorMessage);
    const getEmploymentDetails = useSelector(
        (state) => state.getEmploymentDetails
    );
    const getBvnRecordDetails = useSelector(
        (state) => state.getBvnRecordDetails
    );
    const loanProductSlected = useSelector((state) => state.loanProductSlected);
    const setBorrowerDetails = useSelector((state) => state.setBorrowerDetails);
    const [employmentStatus, setemploymentStatus] = useState([
        { id: 1, name: "Government Employee" },
        { id: 2, name: "Employee" },
        { id: 3, name: "Business Owner" },
        { id: 4, name: "Pensioner" },
        { id: 5, name: "Student" },
    ]);

    console.log(loanProductSlected, ">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>");

    const setEmploymentDetails = useSelector(
        (state) => state.setEmploymentDetails
    );
    console.log(employmentStatus);
    const getListOfWorkStatus = useSelector(
        (state) => state.getListOfWorkStatus
    );
    const userDetails = useSelector((state) => state.userDetails);
    const [EmploymentStatusName, setEmploymentStatusName] = useState(
        "Government Employee"
    );
    const [getStatusFromServer, setgetStatusFromServer] = useState(false);
    const dispatch = useDispatch();
    const handleEmploymentStatusFromServer = (employmentStatus) => {
        setEmploymentStatusName(
            employmentStatus ? employmentStatus : "Business Owner"
        );
        setgetStatusFromServer(employmentStatus ? true : false);
    };
    const [addNewEmploymentDetails, setaddNewEmploymentDetails] =
        useState(false);

    const [userData, setUserData] = useState(false);

    const userDataCallback = (data) => {
        setUserData(data);
        setEmploymentStatusName(data.type);
        console.log("))))))))))))))))", data);
    };

    useEffect(() => {
        const getUserDetails = new services().getService(
            "borrower",
            userDetails.token
        );
        getUserDetails
            .then((res) => {
                if (!res.errorMessage) {
                    dispatch({
                        type: "LIST_OF_WORK_STATUS",
                        data: res.data.WorkStatus,
                    });
                }
            })
            .catch((error) => {
                let errorMessage = {
                    message: "an error occur,please check your network.",
                    status: false,
                };

                dispatch({ type: "ERROR_MESSAGE", data: errorMessage });
            });
        let errorMessage = {
            message: "",
            status: false,
        };
        dispatch({ type: "ERROR_MESSAGE", data: errorMessage });
    }, []);

    const [sendSelectedCardDataToFunction, setsendSelectedCardDataToFunction] =
        useState({});

    const getCardClickData = (data) => {
        if (Object.entries(loanProductSlected).length === 0) {
            let errorMessage = {
                message:
                    "an error occur,please go back to select loan product.",
                status: false,
            };

            dispatch({ type: "ERROR_MESSAGE", data: errorMessage });
        } else if (Object.entries(getBvnRecordDetails).length === 0) {
            let errorMessage = {
                message: "an error occur,please go back to fill bvn details.",
                status: false,
            };

            dispatch({ type: "ERROR_MESSAGE", data: errorMessage });
        } else {
            if (data.type === "Student") {
                // console.log(employmentDetails);
                let dataS = {
                    type: data.type,
                    school_name: data.school_name,
                    level: data.level,
                    country: data.country,
                };
                let postWorkStatus = new services().postServiceWithToken(
                    "borrower/working-status",
                    dataS,
                    userDetails.token
                );
                postWorkStatus
                    .then((res) => {
                        if (res.errorMessage) {
                            let errorMessage = {
                                message: res.errorMessage,
                                status: false,
                            };

                            dispatch({
                                type: "ERROR_MESSAGE",
                                data: errorMessage,
                            });
                        } else {
                            let successMessage = {
                                message:
                                    "Student Details successfully created.",
                                status: true,
                            };

                            dispatch({
                                type: "ERROR_MESSAGE",
                                data: successMessage,
                            });
                            setTimeout(() => {
                                let clearMessage = {
                                    message: "",
                                    status: false,
                                };

                                dispatch({
                                    type: "ERROR_MESSAGE",
                                    data: clearMessage,
                                });
                            }, 500);

                            dispatch({
                                type: "SET_EMPLOYMENT_DETAILS",
                                data: res?.data,
                            });
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } else if (data.type === "Business Owner") {
                let dataB = {
                    type: data.type,
                    business_name: data.business_name,
                    work_phone: data.work_phone,
                    country: data.country,
                };
                let postWorkStatus = new services().postServiceWithToken(
                    "borrower/working-status",
                    dataB,
                    userDetails.token
                );
                postWorkStatus
                    .then((res) => {
                        if (res.errorMessage) {
                            let errorMessage = {
                                message: res.errorMessage,
                                status: false,
                            };
                            dispatch({
                                type: "ERROR_MESSAGE",
                                data: errorMessage,
                            });
                        } else {
                            let successMessage = {
                                message:
                                    "Employment details successfully created.",
                                status: true,
                            };
                            dispatch({
                                type: "ERROR_MESSAGE",
                                data: successMessage,
                            });
                            setTimeout(() => {
                                let clearMessage = {
                                    message: "",
                                    status: false,
                                };
                                dispatch({
                                    type: "ERROR_MESSAGE",
                                    data: clearMessage,
                                });
                                navigate("/create-loan");
                            }, 500);
                            dispatch({
                                type: "SET_EMPLOYMENT_DETAILS",
                                data: res?.data,
                            });
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } else if (data.type === "Employee") {
                let dataE = {
                    type: data.type,
                    organization_name: data.organization_name,
                    work_phone: data.work_phone,
                    country: data.country,
                    occupation: data.occupation,
                };
                let postWorkStatus = new services().postServiceWithToken(
                    "borrower/working-status",
                    dataE,
                    userDetails.token
                );
                postWorkStatus
                    .then((res) => {
                        if (res.errorMessage) {
                            let errorMessage = {
                                message: res.errorMessage,
                                status: false,
                            };

                            dispatch({
                                type: "ERROR_MESSAGE",
                                data: errorMessage,
                            });
                            navigate("/existing-loan");
                        } else {
                            let successMessage = {
                                message:
                                    "Employment details successfully created.",
                                status: true,
                            };

                            dispatch({
                                type: "ERROR_MESSAGE",
                                data: successMessage,
                            });

                            dispatch({
                                type: "SET_EMPLOYMENT_DETAILS",
                                data: res?.data,
                            });

                            setTimeout(() => {
                                let clearMessage = {
                                    message: "",
                                    status: false,
                                };

                                dispatch({
                                    type: "ERROR_MESSAGE",
                                    data: clearMessage,
                                });
                                navigate("/create-loan");
                            }, 500);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } else if (data.type === "Government Employee") {
                let dataG = {
                    type: data.type,
                    occupation: data.occupation,
                    work_phone: data.work_phone,
                    country: data.country,
                    level: data.level,
                    organization_name: data.organization_name,
                };
                const postWorkStatus = new services().postServiceWithToken(
                    "borrower/working-status",
                    dataG,
                    userDetails.token
                );
                postWorkStatus
                    .then((res) => {
                        if (res.errorMessage) {
                            let errorMessage = {
                                message: res.errorMessage,
                                status: false,
                            };

                            dispatch({
                                type: "ERROR_MESSAGE",
                                data: errorMessage,
                            });
                        } else {
                            let successMessage = {
                                message:
                                    "Employment details successfully created.",
                                status: true,
                            };

                            dispatch({
                                type: "ERROR_MESSAGE",
                                data: successMessage,
                            });
                            dispatch({
                                type: "SET_EMPLOYMENT_DETAILS",
                                data: res?.data,
                            });
                            setTimeout(() => {
                                let cleerMessage = {
                                    message: "",
                                    status: false,
                                };

                                dispatch({
                                    type: "ERROR_MESSAGE",
                                    data: cleerMessage,
                                });
                                navigate("/create-loan");
                            }, 500);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } else if (data.type === "Pensioner") {
                let dataP = {
                    type: data.type,
                    occupation: data.occupation,
                    organization_name: data.organization_name,
                    country: data.country,
                    retirement_date: data.retirement_date,
                };
                const postWorkStatus = new services().postServiceWithToken(
                    "borrower/working-status",
                    dataP,
                    userDetails.token
                );
                postWorkStatus
                    .then((res) => {
                        if (res.errorMessage) {
                            let errorMessage = {
                                message: res.errorMessage,
                                status: false,
                            };

                            dispatch({
                                type: "ERROR_MESSAGE",
                                data: errorMessage,
                            });
                        } else {
                            dispatch({
                                type: "SET_EMPLOYMENT_DETAILS",
                                data: res?.data,
                            });
                            let successMessage = {
                                message:
                                    "Employment details successfully created.",
                                status: true,
                            };

                            dispatch({
                                type: "ERROR_MESSAGE",
                                data: successMessage,
                            });

                            setTimeout(() => {
                                let clearMessage = {
                                    message: "",
                                    status: false,
                                };

                                dispatch({
                                    type: "ERROR_MESSAGE",
                                    data: clearMessage,
                                });
                                navigate("/create-loan");
                            }, 700);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } else {
                let errorMessage = {
                    message: "Select or fill out all required fields",
                    status: false,
                };

                dispatch({ type: "ERROR_MESSAGE", data: errorMessage });
            }
        }
    };

    const handleSwitchEmployment = () => {
        if (addNewEmploymentDetails) {
            setaddNewEmploymentDetails(false);
        } else {
            setaddNewEmploymentDetails(true);
        }
    };
    return (
        <div className="employment-cover">
            <div className="employment-title">
                <TitleComponentHeader
                    title={"Employment Details"}
                    subTitle={"Kindly provide your employments"}
                />
            </div>
            <div className="employment-form-cover">
                {addNewEmploymentDetails ? (
                    ""
                ) : globalErrorMessage.message ? (
                    <div style={{ marginTop: "20px" }}>
                        <MessageComponent messageDetails={globalErrorMessage} />
                    </div>
                ) : (
                    ""
                )}

                {!userData &&
                getListOfWorkStatus.length &&
                !addNewEmploymentDetails ? (
                    getListOfWorkStatus.map((list) => (
                        <ListOfEmploymentStatusCard
                            userDataCallback={userDataCallback}
                            customWidth={"64%"}
                            customHeight={"125px"}
                            employmentDetails={list}
                            getCardClickEmploymentDetails={getCardClickData}
                        />
                    ))
                ) : (
                    <div>
                        <div style={{ marginBottom: "29px" }}>
                            {loanProductSlected?.loanCategory ===
                            "CORPORATE" ? (
                                ""
                            ) : (
                                <SelectInput
                                    labelTitle={"Select employment status"}
                                    placeholder={"Employment status"}
                                    dropdownValue={employmentStatus}
                                    customWidth={"64%"}
                                    hasEmploymentStatus
                                    optionValue={EmploymentStatusName}
                                    handleChange={(e) => {
                                        console.log(e);
                                        console.log(e.target);
                                        console.log(e.target.value);
                                        setEmploymentStatusName(e.target.value);
                                    }}
                                />
                            )}
                        </div>

                        <div>
                            <FirstFormCover
                                information={userData}
                                hasEmploymentStatusFromServer={
                                    getStatusFromServer
                                }
                                getSelectedEmploymentDetails={
                                    EmploymentStatusName
                                }
                                employmentStatusFirstForm={employmentStatus}
                                getEmploymentStatusFromEndpoint={
                                    handleEmploymentStatusFromServer
                                }
                            />
                        </div>
                    </div>
                )}
            </div>
            {getListOfWorkStatus.length ? (
                <div style={{ paddingLeft: "50px", paddingTop: "50px" }}>
                    <PrimaryButton
                        text={
                            addNewEmploymentDetails
                                ? "Click to add existing work status"
                                : "Add New Employment Details"
                        }
                        handleClickButton={handleSwitchEmployment}
                    />
                </div>
            ) : (
                ""
            )}
        </div>
    );
}
