import React, { useState } from "react";
import AuthForm from "../authForm/authForm";
import Arrow from "../../images/arrow-button.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
    emailValidation,
    getDataFromLocalStorage,
    setDateToLocalStorage,
} from "../../utilities/utilities";
import RequestWaitButton from "../../components/requestWaitButton/requestWaitButton";
import MessageComponent from "../../components/messageComponent/messageComponent";
import services from "../../services/services";
import FormTitleDiv from "../../components/FormTitleDiv";
export default function SigninFormComponent() {
    const navigation = useNavigate();
    const handleRouteToRecoverPasswordPage = () => {
        navigation("/recover-password");
    };
    const loadState = useSelector((state) => state.loadState);
    const dispatch = useDispatch();
    const [emailChecker, setemailChecker] = useState(false);
    const [userDetails, setuserDetails] = useState({
        email: "",
        password: "",
    });
    const [MessageDetails, setMessageDetails] = useState({
        message: "",
        status: false,
    });

    const [passwordType, setPasswordType] = React.useState("password");

    const handleInputChange = (value, attribute) => {
        setuserDetails({
            ...userDetails,
            [attribute]: value,
        });
        if (attribute === "email") {
            setemailChecker(emailValidation(value));
        }
    };

    const handleType = () => {
        setPasswordType((prevValue) =>
            prevValue == "password" ? "text" : "password"
        );
    };

    const handleContinue = () => {
        dispatch({ type: "LOAD_STATE", data: true });
        if (!emailValidation || userDetails.password === "") {
            dispatch({ type: "LOAD_STATE", data: false });
            setMessageDetails({
                message: "Invalid email or password",
                status: false,
            });
        } else {
            const getresponse = new services().postService(
                "auth/login",
                userDetails
            );

            getresponse
                .then((data) => {
                    dispatch({ type: "LOAD_STATE", data: false });
                    if (data.errorMessage) {
                        setMessageDetails({
                            message: data.errorMessage,
                            status: false,
                        });
                    } else {
                        setTimeout(() => {
                            setMessageDetails({
                                message: "",
                                status: false,
                            });
                            if (data.data.is_verified) {
                                window.location.href = "/dashboard";
                            } else {
                                window.location.href = "/verify-email";
                            }
                        }, 500);
                        setMessageDetails({
                            message: "Login successful.",
                            status: true,
                        });
                        if (data.data.is_verified) {
                            setDateToLocalStorage(data.data);
                        } else {
                        }
                    }
                })
                .catch((error) => {
                    dispatch({ type: "LOAD_STATE", data: false });
                    // console.log("Network request failed");
                });
        }
    };
    return (
        <div>
            <div className="signin-form-cover">
                <FormTitleDiv
                    text={"Email Address"}
                    fontSize={"18px"}
                    color={"#565656"}
                    marginBottom={"15px"}
                />
                <AuthForm
                    isValid={emailChecker}
                    placeholder={""}
                    type={"email"}
                    right={"18px"}
                    top={"14px"}
                    handleChange={(evt) =>
                        handleInputChange(evt.target.value, "email")
                    }
                />
            </div>
            <div className="signin-form-cover">
                <FormTitleDiv
                    text={"Password"}
                    fontSize={"18px"}
                    color={"#565656"}
                    marginBottom={"15px"}
                />
                <AuthForm
                    isValid={true}
                    placeholder={""}
                    type={passwordType}
                    handleType={handleType}
                    isPassword
                    right={"18px"}
                    top={"14px"}
                    handleChange={(evt) =>
                        handleInputChange(evt.target.value, "password")
                    }
                />
            </div>

            {MessageDetails.message ? (
                <MessageComponent messageDetails={MessageDetails} />
            ) : (
                ""
            )}
            <div className="desktop-submit-button-div">
                <div style={{ position: "relative" }}>
                    {loadState ? (
                        <div style={{ marginTop: "30px" }}>
                            <RequestWaitButton
                                text={"CONTINUE"}
                                customBackground={"#A6ACBE"}
                                opacity={0.15}
                                customHeight={"55px"}
                                customPaddingBottom={"19px"}
                                customPaddingTop={"19px"}
                                customColor={"white"}
                                customWith={"95%"}
                                customBorderRadius={"28px"}
                            />
                        </div>
                    ) : (
                        <button
                            className="btn btn-default desktop-submit-button"
                            onClick={handleContinue}
                        >
                            CONTINUE
                            {/* <img
                src={Arrow}
                alt="arrow"
                style={{ position: "absolute", left: "90%" }}
              /> */}
                        </button>
                    )}
                </div>
                <div
                    style={{
                        marginTop: "20px",
                        textAlign: "center",
                        fontSize: "13px",
                        fontWeight: 700,
                    }}
                >
                    Forgot Password?{" "}
                    <span
                        className="signin-link-span"
                        style={{ marginLeft: "5px", color: "#2BC155" }}
                        onClick={handleRouteToRecoverPasswordPage}
                    >
                        Recover Password
                    </span>
                </div>
                <div
                    style={{
                        marginTop: "22px",
                        textAlign: "center",
                        fontSize: "13px",
                        fontWeight: 700,
                        paddingBottom: "50px",
                    }}
                >
                    Don’t have an account?
                    <span
                        className="signin-link-span"
                        style={{ marginLeft: "5px", color: "#2BC155" }}
                        onClick={() => navigation("/register")}
                    >
                        Sign Up
                    </span>
                </div>
            </div>
            <div className="mobile-submit-button-div">
                <div
                    className="sign-forgot-password-div"
                    onClick={handleRouteToRecoverPasswordPage}
                >
                    Forgot Password?
                </div>
                <div style={{ position: "relative" }}>
                    <button
                        className="btn btn-default mobile-signin-submit-button"
                        onClick={handleContinue}
                    >
                        Sign in
                    </button>
                </div>
                <div className="sign-dont-have-an-account-div">
                    Don’t have an account?{" "}
                    <span
                        className="dont-have-account-span"
                        onClick={() => navigation("/register")}
                    >
                        Sign up
                    </span>
                </div>
            </div>
        </div>
    );
}
