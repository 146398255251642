import React from "react";

export default function LightButton({
  customBorderRadius,
  customWidth,
  customHeight,
  title,
  handleClick,
}) {
  return (
    <button
      style={{
        borderRadius: customBorderRadius,
        border: "1px solid #9597A6",
        width: customWidth,
        height: customHeight,
        background: "white",
      }}
      onClick={handleClick}
    >
      {title}
    </button>
  );
}
