import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import "./selectInput.css";
import { formatToCurrency } from "../../utilities/utilities";
export default function SelectInput({
  placeholder,
  customWidth,
  dropdownValue,
  labelTitle,
  isAmount,
  isTag,
  hasBank,
  hasEmploymentStatus,
  hasLoanCredit,
  selectLoanChecker,
  handleChange,
  optionValue,
  hasLocation,
  hasSchools,
  disabled,
  isLoanCategory,
  isLoanDuration,
}) {
  return (
    <div>
      <div
        className="form-title-div"
        style={{ color: selectLoanChecker ? "red" : "#020614" }}
      >
        {labelTitle}
      </div>
      <FormControl
        variant="filled"
        sx={{ width: customWidth }}
        className="selectInput-for-mobile"
      >
        <InputLabel
          id="demo-simple-select-filled-label"
          style={{ paddingLeft: "52px", paddingTop: "17px" }}
        >
          {placeholder}
        </InputLabel>
        <Select
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          value={optionValue}
          onChange={handleChange}
          disableUnderline
          disabled={disabled}
        >
          <MenuItem value="" >
            <em>Select Option</em>
          </MenuItem>
          {isAmount &&
            dropdownValue?.map((amount) => (
              <MenuItem value={amount?.amount} key={amount.id}>
                &#8358;{formatToCurrency(amount?.amount)}
              </MenuItem>
            ))}
          {isTag &&
            dropdownValue?.map((list) => (
              <MenuItem value={list} key={list}>
                {list?.charAt(0)+ list?.slice(1).toLowerCase()}
              </MenuItem>
            ))}
          {isLoanCategory &&
            dropdownValue?.map((list) => (
              <MenuItem value={list?.name} key={list.id}>
                {list?.name?.charAt(0)+ list?.name?.slice(1).toLowerCase()}
              </MenuItem>
            ))}
          {isLoanDuration &&
            dropdownValue?.map((list) => (
              <MenuItem value={list.name} key={list.id}>
                {list.name}
              </MenuItem>
            ))}
          {hasBank &&
            dropdownValue?.map((list) => (
              <MenuItem value={list?.code} key={list.id}>
                {list?.name}
              </MenuItem>
            ))}
          {hasEmploymentStatus &&
            dropdownValue?.map((list) => (
              <MenuItem value={list?.name} key={list.id}>
                {list?.name}
              </MenuItem>
            ))}
          {hasLoanCredit &&
            dropdownValue?.map((list) => (
              <MenuItem value={list?.title} key={list.id}>
                {list?.title}
              </MenuItem>
            ))}
          {hasLocation &&
            dropdownValue?.map((list) => (
              <MenuItem value={list?.state?.name} key={list?.state?.id}>
                {list?.state?.name}
              </MenuItem>
            ))}
          {hasSchools &&
            dropdownValue?.map((list) => (
              <MenuItem value={list?.name} key={list?.name}>
                {list?.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </div>
  );
}
