import React, { useState } from "react";
import "./select-loan-card-component.css";
export default function SelectMobileLoanCardComponent({ data, handleGetId }) {
  const [activeId, setactiveId] = useState(1);

  const handleId = (id) => {
    setactiveId(id);
    handleGetId(id);
  };
  return data?.map((list) => (
    <div key={list.id} className="col-6">
      <div
        className={`${
          list.id === activeId
            ? "loan-card-mobile-container-active"
            : "loan-card-mobile-container-inactive"
        } ${list.lastSmallCard}`}
        onClick={() => handleId(list.id)}
      >
        <div className="loan-card-mobile-title">{list.product_name}</div>
        <div className="loan-card-mobile-subtitle"> {list.description}</div>
      </div>
    </div>
  ));
}
