import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import TitleComponentHeader from "../../components/titleComponent/TitleComponent";
import services from "../../services/services";
import MobileViewForRepayment from "./mobileViewForRepayment";
import "./repayment.css";
import RepaymentButton from "./repaymentButton";
import moment from "moment";
import { formatToCurrency } from "../../utilities/utilities";
import { listeners } from "process";
import GoBack from "../../components/goBack/goBack";
export default function Repayment() {
  const getId = useParams();
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.userDetails);
  const getRepayment = useSelector((state) => state.getRepayment);
  const getIndividualLoan = useSelector((state) => state.getIndividualLoan);
  useEffect(() => {
    const getRepayment = new services().getServiceWithId(
      "repayments/due",
      getId.id,
      userDetails.token
    );
    getRepayment
      .then((res) => {
        if (res.errorMessage) {
          dispatch({ type: "GET_REPAYMENT_DETAILS", data: {} });
        } else {
          dispatch({ type: "GET_REPAYMENT_DETAILS", data: res?.data });
        }
      })
      .catch((error) => {
        console.log(error);
      });
    const getIndividualLoan = new services().getServiceWithId(
      "loan",
      getId.id,
      userDetails.token
    );
    getIndividualLoan
      .then((res) => {
        if (res.errorMessage) {
          dispatch({ type: "GET_INDIVIDUAL_LOAN_DETAILS", data: {} });
        } else {
          dispatch({ type: "GET_INDIVIDUAL_LOAN_DETAILS", data: res?.data });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  const payRepaymentDue = (amount) => {
    dispatch({ type: "SHOW_SPINNER", data: true });
    let data = {
      loan_id: getId.id,
      amount: amount,
    };

    const postRepayment = new services().postServiceWithToken(
      "repayments/make-repayment",
      data,
      userDetails.token
    );
    postRepayment
      .then((res) => {
        if (res.errorMessage) {
          let errorMessage = {
            message: res.errorMessage,
            status: false,
          };

          dispatch({ type: "ERROR_MESSAGE", data: errorMessage });
        } else {
          let errorMessage = {
            message: "Repayment successful",
            status: true,
          };

          dispatch({ type: "ERROR_MESSAGE", data: errorMessage });
        }
        dispatch({ type: "SHOW_SPINNER", data: false });

        dispatch({ type: "SHOW_TOAST", data: true });
      })
      .catch((error) => {
        dispatch({ type: "SHOW_SPINNER", data: false });
        console.log(error);
      });
    return dispatch({ type: "SHOW_TOAST", data: false });
  };
  const makeRepayment = (amount) => {
    dispatch({ type: "SHOW_SPINNER", data: true });
    // console.log(loanId);
    let data = {
      loan_id: getId.id,
      amount: amount,
    };
    const postRepayment = new services().postServiceWithToken(
      "repayments/make-repayment",
      data,
      userDetails.token
    );
    postRepayment
      .then((res) => {
        if (res.errorMessage) {
          let errorMessage = {
            message: res.errorMessage,
            status: false,
          };

          dispatch({ type: "ERROR_MESSAGE", data: errorMessage });
        } else {
          let errorMessage = {
            message: "Repayment successful",
            status: true,
          };
          window.location.href = res?.data?.authorization_url;

          dispatch({ type: "ERROR_MESSAGE", data: errorMessage });
        }
        dispatch({ type: "SHOW_SPINNER", data: false });

        dispatch({ type: "SHOW_TOAST", data: true });
      })
      .catch((error) => {
        dispatch({ type: "SHOW_SPINNER", data: false });
        console.log(error);
      });
  };
  return (
    <div className="repayment-cover">
      <div className="desktop-view-screen">
        <div className="repayment-title-cover">
          {" "}
          <span style={{ display: "flex" }}>
            {" "}
            <GoBack /> Due Repayment
          </span>
        </div>
        <div className="due-payment-cover">
          {Object.entries(getRepayment)?.length !== 0 ? (
            Object.entries(getRepayment?.currentPeriodStatus)?.length === 0 ? (
              <div style={{ color: "#858585", fontSize: "12px" }}>
                You have no due repayment this time
              </div>
            ) : (
              <div className="row m-0 p-0">
                <div className="col-2 due-repayment-cols">
                  Date
                  <br />
                  <div className="due-repayment-col-span">
                    {getIndividualLoan?.createdAt
                      ? moment(getIndividualLoan?.createdAt).format("MMM Do YY")
                      : ""}
                  </div>
                </div>
                <div className="col-2 due-repayment-cols">
                  Loan ID
                  <br />
                  <div className="due-repayment-col-span">
                    {getIndividualLoan?.loan_acct_id
                      ? getIndividualLoan?.loan_acct_id
                      : ""}
                  </div>
                </div>
                <div className="col-2 due-repayment-cols">
                  Loan Product
                  <br />
                  <div className="due-repayment-col-span">
                    {getIndividualLoan?.type ? getIndividualLoan?.type : ""}
                  </div>
                </div>
                <div className="col-2 due-repayment-cols">
                  Amount
                  <br />
                  <div className="due-repayment-col-span">
                    ₦
                    {getRepayment?.currentPeriodStatus?.current_liability
                      ? formatToCurrency(
                          getRepayment?.currentPeriodStatus?.current_liability
                        )
                      : 0}
                  </div>
                </div>
                <div className="col-1 due-repayment-cols">
                  Status
                  <br />
                  <span className="due-payment-status">Unpaid</span>
                </div>
                <div className="col-3 repayment-due-button-cover">
                  <RepaymentButton
                    customWith={"165px"}
                    handlePayRepayment={() =>
                      payRepaymentDue(
                        getRepayment?.currentPeriodStatus?.current_liability
                      )
                    }
                  />
                </div>
              </div>
            )
          ) : (
            ""
          )}
        </div>
        <div className="upcoming-repayment-title">Upcoming Repayments</div>
        <div>
          <div className="upcoming-header-cover">
            <div className="row m-0 p-0 upcoming-repayment-header">
              <div className="col-6 left-upcoming-repayment-header">
                Repayment
              </div>
              <div className="col-6 right-upcoming-repayment-header">
                Action
              </div>
            </div>
          </div>
          <div className="upcoming-repayment-content">
            {getRepayment?.remainingPeriods?.length ? (
              getRepayment?.remainingPeriods?.map((list) => (
                <div className="row m-0 p-0">
                  <div className="col-2 due-repayment-cols">
                    Due Date
                    <br />
                    <div className="due-repayment-col-span">
                      {list?.date ? list?.date : ""}
                    </div>
                  </div>
                  <div className="col-2 due-repayment-cols">
                    Loan ID
                    <br />
                    <div className="due-repayment-col-span">
                      {getIndividualLoan?.loan_acct_id
                        ? getIndividualLoan?.loan_acct_id
                        : ""}
                    </div>
                  </div>
                  <div className="col-2 due-repayment-cols">
                    Principal
                    <br />
                    <div className="due-repayment-col-span">
                      ₦
                      {list?.principal_outstanding
                        ? formatToCurrency(list?.principal_outstanding)
                        : 0}
                    </div>
                  </div>
                  <div className="col-2 due-repayment-cols">
                    Interest
                    <br />
                    <div className="due-repayment-col-span">
                      ₦{list?.interest ? formatToCurrency(list?.interest) : 0}
                    </div>
                  </div>
                  <div className="col-2 due-repayment-cols">
                    Due Amount
                    <br />
                    <div className="due-repayment-col-span">
                      {" "}
                      ₦{" "}
                      {list?.due_principal
                        ? formatToCurrency(list?.due_principal)
                        : 0}
                    </div>
                  </div>
                  <div className="col-2 repayment-upcoming-button-cover ">
                    <RepaymentButton
                      customWith={"165px"}
                      handlePayRepayment={() =>
                        makeRepayment(list?.due_principal)
                      }
                    />
                  </div>
                </div>
              ))
            ) : (
              <div
                style={{
                  color: "#858585",
                  fontSize: "12px",
                  marginTop: "30px",
                }}
              >
                You have no Upcoming repayment this time
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="mobile-repayment-view">
        <MobileViewForRepayment
          dueRepayment={getRepayment}
          getIndividualLoan={getIndividualLoan}
        />
      </div>
    </div>
  );
}
