import React from "react";
import TitleComponentHeader from "../../../components/titleComponent/TitleComponent";
import FirstFormCover from "../employmentDetails/firstFormCover";
import PersonalRowCover from "../personalDetails/personalRowCover";
import "./guarantorInfo.css";
export default function GuarantorInfo() {
  return (
    <div className="guarantor-cover">
      <div className="guarantor-title-cover">
        <TitleComponentHeader title={"Guarantor Information"} />
      </div>
      <div className="guarantor-form-cover">
        <PersonalRowCover />
        <div>
          <FirstFormCover />
        </div>
      </div>
    </div>
  );
}
